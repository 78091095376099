import { AppConfig } from '../initializer/AppConfig';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EtablissmentService = /** @class */ (function () {
    function EtablissmentService(http) {
        this.http = http;
    }
    EtablissmentService.prototype.getAssignableEtablissments = function () {
        return this.http.get(AppConfig.settings.api.adminUrl + "/assignableEtablissements/");
    };
    EtablissmentService.ngInjectableDef = i0.defineInjectable({ factory: function EtablissmentService_Factory() { return new EtablissmentService(i0.inject(i1.HttpClient)); }, token: EtablissmentService, providedIn: "root" });
    return EtablissmentService;
}());
export { EtablissmentService };
