import {IDebitDTO} from '../iDebitDTO';
import {IMandat} from '../previsions/mandat.model';

export interface ITransfertPonctuelCreation {
  transfertId?: number;
  sourceMandatCseId: number;
  targetMandatCseId: number;
  transferredAmountInMinutes: number;
  typeHeureId: number;
}


export class TransfertPonctuelCreation implements ITransfertPonctuelCreation {
  transfertId?: number;
  sourceMandatCseId: number;
  targetMandatCseId: number;
  transferredAmountInMinutes: number;
  typeHeureId: number;

  constructor(sourceMandatCseId: number,
              targetMandatCseId: number,
              transferredAmountInMinutes: number,
              typeHeureId: number) {
    this.sourceMandatCseId = sourceMandatCseId;
    this.targetMandatCseId = targetMandatCseId;
    this.transferredAmountInMinutes = transferredAmountInMinutes;
    this.typeHeureId = typeHeureId;
  }
}


export interface ITransfertRecurrentCreation extends ITransfertPonctuelCreation {
  creditSourceId: number;
}


export class TransfertRecurrentCreation extends TransfertPonctuelCreation implements ITransfertRecurrentCreation {

  creditSourceId: number;

  constructor(creditSourceId: number, sourceMandatCseId: number,
              targetMandatCseId: number,
              transferredAmountInMinutes: number,
              typeHeureId: number) {
    super(sourceMandatCseId, targetMandatCseId, transferredAmountInMinutes, typeHeureId);
    this.creditSourceId = creditSourceId;
  }
}


export interface ITransfert extends IDebitDTO {
  targetMandat?: IMandat;
  sourceMandat?: IMandat;
  alreadyUsed?: number;
  maximumModificationDuration?: number;
  effectDate?: Date;
}
