import { isDevMode } from '@angular/core';
import { Router } from '@angular/router';
// import { LoginModalService } from 'app/core/login/login-modal.service';
// import { StateStorageService } from './state-storage.service';
import { AuthenticationService } from './authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./authentication.service";
var UserRouteAccessService = /** @class */ (function () {
    function UserRouteAccessService(router, 
    // private loginModalService: LoginModalService,
    authenticationService) {
        this.router = router;
        this.authenticationService = authenticationService;
    }
    UserRouteAccessService.prototype.canActivate = function (route, state) {
        var authorities = route.data['authorities'];
        // We need to call the checkLogin / and so the accountService.identity() function, to ensure,
        // that the client has a principal too, if they already logged in by the server.
        // This could happen on a page refresh.
        return this.checkLogin(authorities, state.url);
    };
    UserRouteAccessService.prototype.checkLogin = function (authorities, url) {
        var _this = this;
        return Promise.resolve(AuthenticationService.getToken()).then(function (account) {
            if (!authorities || authorities.length === 0) {
                return true;
            }
            if (account) {
                var hasAnyAuthority = _this.authenticationService.hasAnyAuthority(authorities);
                if (hasAnyAuthority) {
                    return true;
                }
                if (isDevMode()) {
                    console.error('User has not any of required authorities: ', authorities);
                }
                _this.router.navigate(['accessdenied']);
                return false;
            }
            //  this.stateStorageService.storeUrl(url);
            // this.loginModalService.open();
            _this.router.navigate(['login']);
            return false;
        });
    };
    UserRouteAccessService.ngInjectableDef = i0.defineInjectable({ factory: function UserRouteAccessService_Factory() { return new UserRouteAccessService(i0.inject(i1.Router), i0.inject(i2.AuthenticationService)); }, token: UserRouteAccessService, providedIn: "root" });
    return UserRouteAccessService;
}());
export { UserRouteAccessService };
