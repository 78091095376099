<div class="container"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0">
  <div fxFlex="auto"></div>
  <div class="titrePage" fxFlex>{{pageTitle}}</div>
  <div fxFlex="auto"></div>
</div>

<div class="container corps"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0">
  <div class="item item-5" fxFlex="15em" fxFlex.lt-md="0em"></div>
  <div class="item item-4" fxFlex fxFlexOffset="50px"  fxFlexOffset.xs="0">

    <form>
      <mat-form-field style="min-width: 250px;" *ngIf="accessUserList()">
        <mat-label>Mandaté</mat-label>
        <input matInput
               [formControl]='userInput'
               required
               [matAutocomplete]="auto"
               [value]="debitModif?.mandate"
        >
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="mandateChanged()">
          <mat-option *ngFor="let user of filteredUsers" [value]="user">
            <span>{{user.prenom}} {{user.nom}} </span>
            <small> | ID: {{user.michelinId}}</small>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>

    <form autocomplete="off" #saisieForm="ngForm" (ngSubmit)="action()">

      <mat-form-field>
        <mat-label>{{msg('lib.choixDate')}}</mat-label>
        <input matInput [matDatepicker]="picker"

               name="date"
               [min]=horizonSaisie
               [max]=horizonSaisieFutur
               [(ngModel)]="date"
               required
               (ngModelChange)="saisieDate($event)"
               [disabled]="!selectedMichelinId"
               (click)="picker.open()"
        >
        <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <br/>

      <mat-form-field *ngIf="creation">
        <mat-label>{{msg('lib.choixMandat')}}</mat-label>

        <mat-select name="mandatSelect"
                    [(ngModel)]='selectedActivity'
                    [disabled]='toggleContingent'
                    required
                    name="contingent">
          <div *ngIf="contingents">
            <mat-option *ngFor="let contingent of contingents"
                        [value]="contingent"
                        matTooltip="{{msg('lib.choixMandat_tip')}}"
                        matTooltipClass="tooltip">
              {{contingent.libelleSaisie}}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>

      <div *ngIf="!creation && selectedActivity" class="activite-modif">
        <p>
          <span class="activite-modif"> {{selectedActivity.libelleSaisie}} </span>
          <span *ngIf="modifMaxSaisieAffiche" class="activite-modif-saisie-max">(disponible {{modifMaxSaisieAffiche}}
            )</span>
        </p>
      </div>

      <em style="color: darkslategray"
          *ngIf="!toggleContingent && selectedActivity?.typeHeureId==0">{{msg('saisieHeures.saisieMax.description')}}</em>
      <br/>

      <mat-form-field>
        <mat-label>{{msg('lib.commentaire')}} {{saisieCommentaire ? tailleSaisieCommentaireMax - saisieCommentaire?.length + ' restant' : ''}}</mat-label>
        <textarea matInput class="commentaire"
                  [(ngModel)]='saisieCommentaire'
                  [disabled]='toggleContingent'
                  [maxLength]=tailleSaisieCommentaireMax
                  cdkTextareaAutosize
                  [required]="selectedActivity?.commentMandatory"
                  name="commentaire">
        </textarea>
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="{{msg('lib.heureDebut')}}" [ngxTimepicker]="fullTimeBegin"
               [format]="24" readonly name="timeBeginInput" [(ngModel)]="timeBeginInput" [disabled]='toggleContingent' required
               (ngModelChange)="changeHeure()">
        <ngx-material-timepicker #fullTimeBegin></ngx-material-timepicker>
      </mat-form-field>
      <br/>
      <mat-form-field>
        <input matInput placeholder="{{msg('lib.heureFin')}}" [ngxTimepicker]="fullTimeEnd"
               [format]="24" readonly name="timeEndInput" [(ngModel)]="timeEndInput" [disabled]='toggleContingent'
               required
               (ngModelChange)="changeHeure()">
        <ngx-material-timepicker #fullTimeEnd
                                 [preventOverlayClick]="true">
        </ngx-material-timepicker>
      </mat-form-field>

      <button type="submit" mat-raised-button
              class="app-item" [disabled]="submitInProgress">
        Valider
      </button>
    </form>




  </div>
  <div class="item item-5" fxFlex="auto"></div>
</div>






