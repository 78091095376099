<div fxFlexFill fxLayout="column">
  <div fxFlex="none" fxFlexOrder="1">
    <app-header></app-header>
  </div>

  <div fxFlex="100%" style="overflow-y: auto" fxFlexOrder="2">
    <block-ui>
      <router-outlet></router-outlet>
    </block-ui>
  </div>

  <div fxFlex="none" fxFlexOrder="3" fxHide.lt-sm="true">
    <app-footer></app-footer>
  </div>
</div>

