<div>
  <mat-dialog-content>
    <form fxLayout="column">

      <h3>Modification d'un transfert</h3>
      <div>
        <mat-label>Activité :</mat-label>
        {{transfert.natureActiviteSaisie}}
      </div>

      <div>
        <mat-label>Destinataire :</mat-label>
        {{transfert.targetMandat.mandate}}
      </div>


      <div fxLayout="row"
           fxLayoutAlign="center"
           fxLayoutGap="5px">
        <mat-form-field style="width:100px">
          <mat-label>{{msg('transfert.transfertHeures')}}</mat-label>
          <input matInput name="hoursToTranfert"
                 [(ngModel)]='hours'
                 class="number-right"
                 inputmode="numeric"
                 type="number"
                 min="0"
                 required/>
        </mat-form-field>
        <mat-form-field style="width:100px">
          <mat-label>{{msg('transfert.transfertMinutes')}}</mat-label>
          <input matInput name="minutesToTranfert"
                 [(ngModel)]='minutes'
                 class="number-right"
                 type="number"
                 inputmode="numeric"
                 min="0"
                 max="60"
                 required/>
        </mat-form-field>

      </div>
      <div> Durée consommée : {{(transfert.alreadyUsed ? transfert.alreadyUsed : 0)|millisToHoursMinutes}}
      </div>
      <div> Durée d'origine : {{transfert.duree|millisToHoursMinutes}}</div>

    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Annuler</button>
    <button class="mat-raised-button mat-primary" (click)="save()" [disabled]="!isValid()">Sauvegarder</button>
  </mat-dialog-actions>

</div>
