<div
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutAlign="start center"
  fxLayoutGap="10px"
  fxLayoutGap.xs="0"
  style="height: 100%">

  <div fxFlex fxFlexOffset="10px" fxFlexOffset.xs="0">
    <h3 style="text-align: center">Gestion des utilisateurs, mandats et contingents individuels</h3>
    <form [formGroup]='usersForm'>
      <mat-form-field style="min-width: 250px;">
        <input matInput
               formControlName='userInput'
               required
               [matAutocomplete]="auto"
               placeholder="Utilisateur ou Mandaté">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="select()">
          <mat-option *ngFor="let user of filteredUsers" [value]="user">
            <span>{{user.prenom}} {{user.nom}} </span>
            <small> | ID: {{user.michelinId}}</small>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <button mat-button (click)="create()" *ngIf="authenticationService.hasAnyAuthority(['ROLE_ADM_USER_RW'])">Créer un
        utilisateur
      </button>
      <button mat-button (click)="modify()" *ngIf="authenticationService.hasAnyAuthority(['ROLE_ADM_USER_RW'])"
              [disabled]="!this.selectedUser">Modifier un utilisateur
      </button>
      <button mat-button (click)="manageMandats()" [disabled]="!this.selectedUser">Gérer les mandats et contingents
      </button>
    </form>
  </div>
  <div fxFlex="100%" fxFlexOffset="10px" fxFlexOffset.xs="0" fxLayoutAlign="start center" fxLayout="column"
       id="innerRouter">
    <router-outlet></router-outlet>
  </div>

</div>

