import {Injectable} from '@angular/core';
import {IAppConfig} from './appConfig.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class AppConfig {
  // Stockage des settings
  public static settings: IAppConfig;
  // Stockage des messages
  protected static messages;

  /**
   * Accesseur des messages
   */
  public static msg(key) {
    if (AppConfig.messages[key] > '') {
      return AppConfig.messages[key];
    } else {
      return '[[' + key + ']]';
    }
  }

  /**
   * Ajouter les messages 'locaux' à Angular, mais ne devrait pas être utilisé
   * (car une update nécessite de redéployer Angular / relivrer => outage)
   */
  protected static addLocalMsg() {
    // AppConfig.messages['messageKey'] = 'Test du message';
  }

  constructor(private httpClient: HttpClient) {}

  /**
   * Récupérer le paramétrage local dont on a besoin.
   * En deux étapes:
   *    1 - Récupérer le paramétrage local (l'équivalent du 'environment', que l'on n'utilise du coup plus)
   *    2 - Récupérer les messages, en utilisant le paramétrage local fraîchement chargé
   **
   * Il faut donc que :
   *    1 - on soit sûr que ça ne démarre pas avant le reste de l'appli => APP_INITIALIZER, cf. app.module.ts
   *    2 - les deux s'enchainent bien dans l'ordre => Promise imbriquées et avec resolve() explicite
   */
  public getSettings(): Promise<any> {
    return this.httpClient.get(environment.configJsonName.toString())
      .toPromise()
      .then(settings => {
        AppConfig.settings = <IAppConfig>settings;
        return this.httpClient.get(AppConfig.settings.api.messageResourceUrl + '/get?language=fr')
          .toPromise()
          .then(messages => {
            AppConfig.messages = messages;
            AppConfig.addLocalMsg();
          })
          .then(() => {
            Promise.resolve(this);
          })
          .catch(() => {
            Promise.reject(this);
          });
      })
      .then(() => {
        Promise.resolve(this);
      })
      .catch(() => {
        Promise.reject(this);
      });
  }
}
