import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {SaisieDesHeuresComponent} from './saisie-des-heures/saisie-des-heures.component';
import {UserRouteAccessService} from './services/user-route-access-service';
import {errorRoute} from './layouts';
import {ListeDesHeuresComponent} from './liste-des-heures/liste-des-heures.component';
import {ExtractionContingentComponent} from './extraction-contingent/extraction-contingent.component';
import {TransfertPonctuelCreationComponent} from './transfert-ponctuel/transfert-ponctuel-creation/transfert-ponctuel-creation.component';
import {RelationsSocialesComponent} from './organisations/relations-sociales/relations-sociales.component';
import {CseComponent} from './organisations/cse/cse.component';
import {OrganisationSyndicaleComponent} from './organisations/organisation-syndicale/organisation-syndicale.component';
import {PrevisionsComponent} from './organisations/cse/previsions/previsions.component';
import {AdministrationComponent} from './administration/administration.component';
import {TransfertRecurrentCreationComponent} from './organisations/relations-sociales/transferts-recurrents/transfert-recurrent-creation/transfert-recurrent-creation.component';
import {UserManagementComponent} from './user-management/user-management.component';
import {userManagementRoute} from './user-management/user-management.route';
import {TestTimezoneComponent} from './test-timezone/test-timezone.component';
import {TransfertPonctuelManagementComponent} from './transfert-ponctuel/transfert-ponctuel-management/transfert-ponctuel-management.component';
import {TransfertRecurrentManagementComponent} from './organisations/relations-sociales/transferts-recurrents/transfert-recurrent-management/transfert-recurrent-management.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: []
    }
  },
  {
    path: 'saisieDesHeures/:debitId',
    component: SaisieDesHeuresComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_SAISIE_TEMPS_RW']
    }
  },
  {
    path: 'saisieDesHeures',
    component: SaisieDesHeuresComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_SAISIE_TEMPS_RW']
    }
  },
  {
    path: 'listeDesHeures',
    component: ListeDesHeuresComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_SAISIE_TEMPS_RO']
    }
  },
  {
    path: 'extractionContingent',
    component: ExtractionContingentComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_SAISIE_TEMPS_RO']
    }
  },
  {
    path: 'relationsSociales',
    component: RelationsSocialesComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_EXTRACT_ETAB', 'ROLE_EXTRACT_FORM', 'ROLE_EXTRACT_TAUX', 'ROLE_TRANSFERTS_ALL']
    }
  },
  {
    path: 'cse',
    component: CseComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_Secrétaire', 'ROLE_SecrétaireCfe', 'ROLE_SecrétaireCsec', 'ROLE_ADMINCSE']
    }
  },
  {
    path: 'organisationSyndicale',
    component: OrganisationSyndicaleComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_Dsc']
    }
  },
  {
    path: 'transfertHeures',
    component: TransfertPonctuelCreationComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_SAISIE_TEMPS_RW', 'ROLE_TRANSFERTS_ALL']
    }
  },
  {
    path: 'transfertManagement',
    component: TransfertPonctuelManagementComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_SAISIE_TEMPS_RW', 'ROLE_TRANSFERTS_ALL']
    }
  },

  {
    path: 'gestion-previsions',
    component: PrevisionsComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_Secrétaire', 'ROLE_SecrétaireCfe', 'ROLE_SecrétaireCsec', 'ROLE_ADMINCSE']
    }
  },
  {
    path: 'administration',
    component: AdministrationComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_ADMIN']
    }
  },
  {
    path: 'relations-sociales/transfertRecurrent',
    component: TransfertRecurrentManagementComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_TRANSFERTS_ALL']
    }
  },
  {
    path: 'relations-sociales/transfertRecurrent/create',
    component: TransfertRecurrentCreationComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_TRANSFERTS_ALL']
    }
  },

  {
    path: 'users',
    component: UserManagementComponent,
    children: userManagementRoute,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_ADM_USER_LST', 'ROLE_ADM_USER_RO', 'ROLE_ADM_USER_RW']
    }
  },
  {
    path: 'test-timezone',
    component: TestTimezoneComponent,
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_ADMIN']
    }
  },
  ...errorRoute
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', enableTracing: false, useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
