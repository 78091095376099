import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfig } from '../initializer/AppConfig';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MandatService = /** @class */ (function () {
    function MandatService(http) {
        this.http = http;
    }
    MandatService.prototype.getMandatsByUser = function (matricule) {
        return this.http.get(AppConfig.settings.api.adminUrl + "/mandats/" + matricule);
    };
    MandatService.prototype.find = function (mandatId) {
        return this.http.get(AppConfig.settings.api.adminUrl + "/mandat/" + mandatId, { observe: 'response' });
    };
    MandatService.prototype.create = function (mandat) {
        return this.http.post(AppConfig.settings.api.adminUrl + "/mandat", mandat, { observe: 'response' });
    };
    MandatService.prototype.update = function (mandat) {
        return this.http.put(AppConfig.settings.api.adminUrl + "/mandat", mandat, { observe: 'response' });
    };
    MandatService.prototype.getCredits = function (mandat, typeHeure) {
        var params = new HttpParams().set('mandatId', String(mandat.id)).set('typeHeureId', String(typeHeure.typeHeureId));
        return this.http.get(AppConfig.settings.api.adminUrl + "/credits", { params: params });
    };
    MandatService.prototype.updateCredit = function (credit) {
        return this.http.patch(AppConfig.settings.api.adminUrl + "/credit", credit, { observe: 'response' });
    };
    MandatService.prototype.initializeCredits = function (mandat, typeHeure, year) {
        return this.http.post(AppConfig.settings.api.adminUrl + "/credits", {
            mandatId: mandat.id,
            typeHeureId: typeHeure.typeHeureId,
            annee: year
        }, { observe: 'response' });
    };
    MandatService.prototype.getNatureActivites = function (mandat) {
        return this.http.get(AppConfig.settings.api.adminUrl + "/natureActivites/" + mandat.fonction.id);
    };
    MandatService.prototype.getContingentMax = function (mandat) {
        return this.http.get(AppConfig.settings.api.adminUrl + "/credit/contingentMax/" + mandat.id);
    };
    MandatService.ngInjectableDef = i0.defineInjectable({ factory: function MandatService_Factory() { return new MandatService(i0.inject(i1.HttpClient)); }, token: MandatService, providedIn: "root" });
    return MandatService;
}());
export { MandatService };
