import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IOs} from '../entities/user/os.model';
import {AppConfig} from '../initializer/AppConfig';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OsService {

  constructor(private readonly http: HttpClient) {
  }

  getOss(): Observable<IOs[]> {
    return this.http.get<IOs[]>(`${AppConfig.settings.api.adminUrl}/oss/`);
  }
}
