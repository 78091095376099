import {ActivatedRouteSnapshot, Resolve, Router, Routes} from '@angular/router';
import {UserRouteAccessService} from '../services/user-route-access-service';
import {UserDetailsComponent} from './user-details/user-details.component';
import {EMPTY, Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {IUser, User} from '../entities/user/user.model';
import {UserService} from '../services/user.service';
import {flatMap} from 'rxjs/internal/operators';
import {HttpResponse} from '@angular/common/http';
import {MandatComponent} from './mandat/mandat.component';
import {IMandat} from '../entities/previsions/mandat.model';
import {MandatService} from '../services/mandat.service';
import {ContingentManagementComponent} from './contingent-management/contingent-management.component';

@Injectable({providedIn: 'root'})
export class UserResolve implements Resolve<IUser> {
  constructor(private readonly userService: UserService, private readonly router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<IUser> | Observable<never> {
    const id = route.params['matricule'];
    if (id) {
      return this.userService.find(id).pipe(
        flatMap((user: HttpResponse<User>) => {
          if (user.body) {
            return of(user.body);
          } else {
            this.router.navigate(['404']);
            return EMPTY;
          }
        })
      );
    }
    return of(new User());
  }
}


@Injectable({providedIn: 'root'})
export class MandatResolve implements Resolve<IMandat> {
  constructor(private readonly mandatService: MandatService, private readonly router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<IMandat> | Observable<never> {
    const id = route.params['mandatId'];
      return this.mandatService.find(id).pipe(
        flatMap((mandat: HttpResponse<IMandat>) => {
          if (mandat.body) {
            return of(mandat.body);
          } else {
            this.router.navigate(['404']);
            return EMPTY;
          }
        })
      );
  }
}


export const userManagementRoute: Routes = [
  {
    path: 'create',
    component: UserDetailsComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      user: UserResolve
    },
    data: {
      authorities: ['ROLE_ADM_USER_RW']
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: ':matricule/edit',
    component: UserDetailsComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      user: UserResolve
    },
    data: {
      authorities: ['ROLE_ADM_USER_RW', 'ROLE_ADM_USER_RO']
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: ':matricule/mandats',
    component: MandatComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      user: UserResolve
    },
    data: {
      authorities: ['ROLE_ADM_USER_RW', 'ROLE_ADM_USER_RO']
    },
    canActivate: [UserRouteAccessService]
  },

  {
    path: ':matricule/mandat/:mandatId/contingents',
    component: ContingentManagementComponent,
    resolve: {
      mandat: MandatResolve
    },
    canActivate: [UserRouteAccessService],
    data: {
      authorities: ['ROLE_ADM_USER_LST', 'ROLE_ADM_USER_RO', 'ROLE_ADM_USER_RW']
    }
  },
  /*,
  {
    path: '',
    component: UserManagementComponent,
    data: {
      authorities: ['ROLE_ADMIN', 'ROLE_RS_CENTRAL', 'ROLE_RS_SITE']
    },
    canActivate: [UserRouteAccessService]
  }*/
];
