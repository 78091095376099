import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Pipe({
  name: 'secondsToHoursMinutes'
})
export class SecondsToHoursMinutesPipe implements PipeTransform {
  decimalPipe = new DecimalPipe('FR');

  constructor() {
  }

  transform(value: number, args?: any): string {

    return this.decimalPipe.transform(Math.trunc(value / 3600), '2.0-0') + ':' +
      this.decimalPipe.transform(Math.trunc(value % 3600 / 60), '2.0-0');
  }

}

@Pipe({
  name: 'millisToHoursMinutes'
})
export class MillisToHoursMinutesPipe implements PipeTransform {
  decimalPipe = new DecimalPipe('FR');

  constructor() {
  }

  transform(value: number, args?: any): string {
    if (value != null) {
      value = Math.trunc(value / 1000);
      return this.decimalPipe.transform(Math.trunc(value / 3600), '2.0-0') + ':' +
        this.decimalPipe.transform(Math.trunc(value % 3600 / 60), '2.0-0');
    }
    return undefined;
  }
}
