import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
import { AppConfig } from '../../initializer/AppConfig';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ActuatorService = /** @class */ (function () {
    function ActuatorService(http) {
        this.http = http;
    }
    // ***** SPECIFIC *******
    // API: GET /management/health
    ActuatorService.prototype.getActuatorHealth = function () {
        return this.http
            .get(AppConfig.settings.api.actuatorUrl + '/health');
    };
    // API: GET /management/info
    ActuatorService.prototype.getActuatorInfo = function () {
        return this.http
            .get(AppConfig.settings.api.actuatorUrl + '/info');
    };
    ActuatorService.prototype.handleError = function (error) {
        console.error('ApiService::handleError', error);
        return observableThrowError(error);
    };
    ActuatorService.ngInjectableDef = i0.defineInjectable({ factory: function ActuatorService_Factory() { return new ActuatorService(i0.inject(i1.HttpClient)); }, token: ActuatorService, providedIn: "root" });
    return ActuatorService;
}());
export { ActuatorService };
