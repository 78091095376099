import {Component, OnInit} from '@angular/core';
import {ExtractionContingentService} from '../services/extraction-contingent.service';
import * as fileSaver from 'file-saver';
import {AppConfig} from '../initializer/AppConfig';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../services/authentication.service'; // npm i --update file-saver

@Component({
  selector: 'app-extraction-contingent',
  templateUrl: './extraction-contingent.component.html',
  styleUrls: ['./extraction-contingent.component.scss']
})
export class ExtractionContingentComponent implements OnInit {

  fileSystemName = 'data';

  /**
   * Titre injecté dans la page
   */
  pageTitle = this.msg('extractionContingent.title');
  selectedYear: number;
  minYear: number;
  maxYear: number;

  private static saveFile(data: any, filename?: string) {
    const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    fileSaver.saveAs(blob, filename);
  }

  /**
   * Accès aux textes/messages paramétrés
   * @param key
   */
  msg(key) {
    return AppConfig.msg(key);
  }

  constructor(private extractionContingent: ExtractionContingentService,
              private toastr: ToastrService,
              public authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.extractionContingent.availableYears().subscribe(availableYears => {
        this.selectedYear = (new Date()).getFullYear();
        if (availableYears) {
          this.maxYear = availableYears[0];
          this.minYear = availableYears[availableYears.length - 1];
        } else {
          this.minYear = this.selectedYear;
          this.maxYear = this.selectedYear;
        }
      }
    );
  }

  extraction() {
    this.extractionContingent.downloadExtraction()
      .subscribe(response => {
        const contentDisposition = response.headers.get('content-disposition');
        console.debug(contentDisposition);
        const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        console.debug(filename);
        ExtractionContingentComponent.saveFile(response.body, filename);
      });
  }

  extractionOS() {
    this.extractionContingent.downloadExtractionOS(this.selectedYear)
      .subscribe(response => {
          const contentDisposition = response.headers.get('content-disposition');
          console.debug(contentDisposition);
          const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          console.debug(filename);
          ExtractionContingentComponent.saveFile(response.body, filename);
        }, (err) => {
          console.error(`Backend returned code ${err.status}, body was: ${err.error.message}`);
          const reader = new FileReader();
          reader.addEventListener('loadend', (e) => {
            const out = JSON.parse(e.target['result']);
            console.error(out);
            this.toastr.error(this.msg(out.hint));
          });
          reader.readAsText(err.error);
        }
      );
  }



  extractionCSE() {
    this.extractionContingent.downloadExtractionCSE(this.selectedYear)
      .subscribe(response => {
          const contentDisposition = response.headers.get('content-disposition');
          console.debug(contentDisposition);
          const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          console.debug(filename);
          ExtractionContingentComponent.saveFile(response.body, filename);
        }, (err) => {
          console.error(`Backend returned code ${err.status}, body was: ${err.error.message}`);
          const reader = new FileReader();
          reader.addEventListener('loadend', (e) => {
            const out = JSON.parse(e.target['result']);
            console.error(out);
            this.toastr.error(this.msg(out.hint));
          });
          reader.readAsText(err.error);
        }
      );
  }
}
