import {Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import {Idecodedtoken} from '../entities/user/Idecodedtoken';
import {AuthenticationService} from '../services/authentication.service';
import {GestionDesHeuresService} from '../services/gestion-des-heures.service';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {IActiviteDTO} from '../entities/iActiviteDTO';
import {ActivatedRoute, Router} from '@angular/router';

import {AppConfig} from '../initializer/AppConfig';
import {IDebitDTO} from '../entities/iDebitDTO';

import * as moment from 'moment';
import {Duration} from 'moment';
import 'moment-duration-format';
import {String} from 'typescript-string-operations';
import {UserService} from '../services/user.service';
import {IUser} from '../entities/user/user.model';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {FormControl} from '@angular/forms';
import {debounceTime, filter, switchMap} from 'rxjs/operators';


export const APP_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

/**
 * Déclaration du component
 */
@Component({
  selector: 'app-saisie-des-heures',
  templateUrl: './saisie-des-heures.component.html',
  styleUrls: ['./saisie-des-heures.component.scss'],
  providers: [{
    provide: MAT_DATE_LOCALE,
    useValue: 'fr'
  },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }]
})
export class SaisieDesHeuresComponent implements OnInit {


  @Input('cdkTextareaAutosize')
  get enabled(): boolean {
    return true;
  }


  /**
   * le debit recue par ecran liste des heures à modifier
   */
  debitModif: IDebitDTO;

  /**
   * Date de l'évènement saisi
   */
  public date?: Date;

  /**
   * Composant de saisie 'heure de début'
   */
  public timeBeginInput?: string;

  /**
   * Composant de saisie 'heure de début'
   */
  public timeEndInput?: string;

  /**
   * Composant de saisie 'activités'
   */
  public selectedActivity?: IActiviteDTO;

  /**
   * Durée calculée de la saisie
   */
  duree: Duration;

  /**
   * Heure de début, convertie depuis le composant de saisie
   */
  public timeBegin: Duration;

  /**
   * Heure de fin, convertie depuis le composant de saisie
   */
  public timeEnd: Duration;

  /**
   * Flag de validation de la saisie des heures
   */
  saisieCorrecteHeures = false;


  public horizonSaisie: Date;

  public horizonSaisieFutur: Date;
  /**
   * Liste des activités = contingents retournés par le backend
   */
  contingents: Array<IActiviteDTO> = [];
  /**
   * Titre injecté dans la page
   */
  pageTitle = this.msg('saisieHeures.title');

  // booléen pour affichage en saisie uniquement si il y a des contingents à la date choisie
  toggleContingent: boolean;

  // commentaire dans saisie
  saisieCommentaire = '';

  /**
   * taille maxi des commentaires
   */
  tailleSaisieCommentaireMax = 140;

  /**
   * taille du commentaire pendant la saisie
   */
  compteurSaisieCommentaire: string;

  /**
   * defini si mode saisie creation ou modification
   */
  creation = true;

  /**
   * util en mode modification de saisie, pour supprimer debit avant de creer un autre debit
   */
  debitASupprimer = false;

  /**
   * saisie maximum lors de modification d'une saisie au format hh:mm
   */
  modifMaxSaisieAffiche: string;

  /**
   * date du jour
   */
  today: number = Date.now();

  /**
   * durée de la saisie à modifier, utile pour calculer saisie max les de modification
   */
  rabDebitModif: number;


  selectedMichelinId: string;

  filteredUsers: IUser[] = [];
  selectedUser: IUser;
  userInput = new FormControl();

  // types heure collectifs (pour masquer la saisie max
  private typesHeuresCollectifs = [3, 4];

  submitInProgress = false;


  /**
   * Constructeur, avec injection du service de gestion des heures, le toaster
   * + router & location pour la navigation
   * @param gestionDesHeuresService
   * @param toastr
   * @param router
   * @param location
   */
  constructor(private gestionDesHeuresService: GestionDesHeuresService,
              public toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private dateAdapter: DateAdapter<Date>,
              private authenticationService: AuthenticationService,
              private userService: UserService) {
  }

  ngOnInit() {
    this.dateAdapter.setLocale('fr');
    this.dateAdapter.getFirstDayOfWeek = () => 1;
    this.toggleContingent = true;

    this.route.params.subscribe(params => {
      if (params.debitId) {
        this.creation = false;
        this.modif(params.debitId);
      } else {
        this.creation = true;
        this.debitModif = null;
      }
    });

    if (this.accessUserList()) {
      if (this.creation) {
        this.userInput
          .valueChanges
          .pipe(
            filter(value => value.length >= 2),
            debounceTime(300),
            switchMap(value => this.userService.getFilteredAdministrableUsers(value)
            )
          ).subscribe(users => this.filteredUsers = users);
      }
      this.horizonSaisieFutur = new Date();
    } else {
      this.selectedMichelinId = AuthenticationService.getTokenObject().sub;
      this.horizonSaisieFutur = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    }


    this.gestionDesHeuresService.getHorizonSaisie().subscribe(LatestDatePaye => {
      this.horizonSaisie = LatestDatePaye;
    });


  }

  modif(debitId: number) {
    this.gestionDesHeuresService.getDebit(debitId).subscribe
    (debit => {
        console.debug(debitId);
        this.successModifSubscribe(debit);
      },
      (err: HttpErrorResponse) => {
        console.error(err + ' : pb reseau');
      }
    );
  }


  successModifSubscribe(debit: IDebitDTO) {
    const dateActivite: Date = moment(debit.dateDebit, 'YYYY-MM-DD').toDate();

    this.debitModif = debit;
    this.selectedMichelinId = debit.michelinId;
    this.pageTitle = this.msg('saisieHeures.title.modif');
    this.date = debit.dateDebit;
    this.timeBeginInput = debit.heureDebut;
    this.timeEndInput = moment(debit.dateDebit + ' ' + debit.heureDebut).add(debit.duree, 'milliseconds').format('HH:mm:ss');
    this.saisieCommentaire = debit.commentaire;
    let contingentTrouve = false;

    this.userInput.disable();

    this.gestionDesHeuresService.getActivitesByDate(this.selectedMichelinId, dateActivite).subscribe(contingentList => {

        // recupere le contingent a modifier
        for (const cl of contingentList) {
          if (cl.mandatId === debit.mandatCseId && cl.typeHeureId === debit.typeHeureId) {
            this.contingents = [];
            this.contingents.push(cl);
            this.selectedActivity = cl;
            contingentTrouve = true;
            break;
          }
        }
        if (contingentTrouve === false) {
          console.error('--------------pb------contingent dans successModifSubscribe');
          this.toastr.error(this.msg('lib.Error'));
        }

        // Gestion du cas des heures hors contingent avec un magic number :-/
        if (this.selectedActivity.solde != null && !this.typesHeuresCollectifs.includes(this.selectedActivity.typeHeureId)) {
          this.rabDebitModif = this.debitModif.duree;
          console.debug('Rab de modif ' + this.rabDebitModif);
          console.debug('this.selectedActivity.saisieMax ' + this.selectedActivity.saisieMax);

          this.selectedActivity.saisieMax = +this.selectedActivity.saisieMax + +this.rabDebitModif;

          console.debug('this.selectedActivity.saisieMax ' + this.selectedActivity.saisieMax);

          this.modifMaxSaisieAffiche = moment.duration(this.selectedActivity.saisieMax, 'millisecond')
            .format('h[h]mm[m]', {userLocale: 'fr'}) + 'in';
          console.debug(moment.duration(this.selectedActivity.saisieMax, 'millisecond').format('hh:mm'));
        }
        this.changeHeure();

        this.debitASupprimer = true;
        this.toggleContingent = false;
      },
      (err: HttpErrorResponse) => {
        this.errorContingent(err);
      });
  }


  /**
   * Accès aux textes/messages paramétrés
   * @param key
   */
  msg(key) {
    return AppConfig.msg(key);
  }


  /**
   * Récupérer le token d'authent courant
   */
  get tokenObject(): Idecodedtoken {
    //console.debug('token: ' + AuthenticationService.tokenObject());
    return AuthenticationService.tokenObject();
  }


  /**
   * Noter l'heure de fin saisie et de début saisie,, sous forme de duration
   * + Valider que la saisie est correct
   */
  changeHeure() {
    this.timeBegin = this.timeBeginInput ? moment.duration(moment.duration(this.timeBeginInput)) : undefined;
    this.timeEnd = this.timeEndInput ? moment.duration(moment.duration(this.timeEndInput)) : undefined;

    if (!this.timeEnd && this.selectedActivity.saisieDefaut) {
      this.timeEnd = this.timeBegin.clone();
      this.timeEnd.add(moment.duration(this.selectedActivity.saisieDefaut, 'millisecond'));
      this.timeEndInput = this.timeEnd.format('HH:mm', {userLocale: 'fr'});
    }


    if (this.timeEnd !== undefined && this.timeBegin !== undefined) {
      this.calculDuree();
    }
  }

  calculDuree() {
    if (this.timeEnd !== undefined && this.timeBegin !== undefined) {
      console.debug('debut: ' + this.timeBegin + 'fin: ' + this.timeEnd);
      const difference = this.timeEnd.subtract(this.timeBegin);
      this.duree = difference;
      this.saisieCorrecteHeures = this.verifHeureSaisieCorrecte(difference);
    }
  }


  /**
   * Vérifier que la durée indiquée est valide
   * @param duree
   */
  verifHeureSaisieCorrecte(duree: Duration): boolean {
    if (!this.selectedActivity || duree == null) {
      return false;
    }
    const solde = moment.duration(this.selectedActivity.solde);
    const saisieMax = this.selectedActivity.saisieMax != null ? moment.duration(this.selectedActivity.saisieMax) : null;

    if (duree.asMilliseconds() > 0) {
      if (saisieMax != null && duree > saisieMax) {
        this.toastr.error(
          String.Format(this.msg('saisieHeures.err.saisieMax'),
            moment.duration(this.selectedActivity.saisieMax, 'millisecond').format('h[h]mm[m]', {userLocale: 'fr'}) + 'in'),
          this.msg('lib.Error'));
        return false;
      } else {
        return true;
      }
    } else {
      this.toastr.error(this.msg('saisieHeures.err.autre'), this.msg('lib.Error'));
      return false;
    }
  }

  /**
   * au clic 'Valider' supprime debit si mode modification, et appel methode enregistre le debit
   */
  action() {
    if (this.verifHeureSaisieCorrecte(this.duree)) {
      this.submitInProgress = true;
      if (!this.creation && this.debitASupprimer) {
        this.updateDebit();
      } else if (this.creation) {
        this.enregistrementDebit();
      }
    } else {
      this.toastr.error(this.msg('saisieHeures.err.saisieIncorrecte'), this.msg('lib.Error'));
    }
  }


  /**
   * Enregistrer le débit d'heure
   */
  enregistrementDebit() {
    this.gestionDesHeuresService.addDebit({
      dateDebit: this.date,
      duree: this.duree.asMilliseconds(),
      heureDebut: this.timeBeginInput,
      mandatCseId: this.selectedActivity.mandatId,
      typeHeureId: this.selectedActivity.typeHeureId,
      commentaire: this.saisieCommentaire,
      michelinId: this.selectedMichelinId
    }).subscribe(result => {
        this.successSaisie(result);
      }, (err: HttpErrorResponse) => {
        this.errorSaisie(err);
      }
    );
  }

  updateDebit() {
    this.gestionDesHeuresService.updateDebit({
      debitId: this.debitModif.debitId,
      dateDebit: this.date,
      duree: this.duree.asMilliseconds(),
      heureDebut: this.timeBeginInput,
      mandatCseId: this.selectedActivity.mandatId,
      typeHeureId: this.selectedActivity.typeHeureId,
      commentaire: this.saisieCommentaire,
      michelinId: this.selectedMichelinId
    }).subscribe(result => {
        this.successSaisie(result);
      }, (err: HttpErrorResponse) => {
        this.errorSaisie(err);
      }
    );
  }

  /**
   * Si le backend a bien enregistré la saisie...
   * @param result
   */
  private successSaisie(result) {
    this.toastr.success(this.msg('saisieHeures.succ.saisie'), this.msg('lib.Success'));
    this.submitInProgress = false;
    this.router.navigate(['home']);
  }

  /**
   * Si le backend a rejeté la saisie...
   * @param err
   */
  private errorSaisie(err) {
    this.toastr.error(this.msg('saisieHeures.err.prefix') + err.error.message, this.msg('lib.Error'));
    this.submitInProgress = false;
  }

  /**
   * Raffraichir les activités dispo selon la date en mode creation et
   * verifie que la date saisie est plus recente que la date de la derniere paye en mode modification
   * @param date
   */
  saisieDate(date: Date) {
    const dateSaisie: Date = date;
    if (this.creation) {
      this.date = date;
      this.contingents = [];
      this.toggleContingent = true;
      this.gestionDesHeuresService.getActivitesByDate(this.selectedMichelinId, date).subscribe(contingentList => {
          this.successContingent(contingentList);
        },
        (err: HttpErrorResponse) => {
          this.errorContingent(err);
        });
    } else {

      if (moment(moment(dateSaisie).format('YYYY-MM-DD')).diff(moment(moment(this.today).format('YYYY-MM-DD'))) < 0) {
        //console.debug('avant');

        if (moment(moment(dateSaisie).format('YYYY-MM-DD')).diff(this.horizonSaisie) < 0) {
          // console.debug('avant PB');
          this.toastr.error(
            this.msg('saisieHeures.err.LatestDatePayeAfterSelectedDate')
            + moment(this.horizonSaisie).format('DD-MM-YYYY'),
            this.msg('lib.Error'));
        }
      }
      this.gestionDesHeuresService.getActivitesByDate(this.selectedMichelinId, date).subscribe(contingentList => {
          for (const contingent of contingentList) {
            if (contingent.mandatId === this.selectedActivity.mandatId
              && contingent.typeHeureId === this.selectedActivity.typeHeureId) {
              this.selectedActivity = contingent;
              if (contingent.solde != null) {
                this.selectedActivity.saisieMax = +contingent.saisieMax + +this.rabDebitModif;
                console.debug('this.selectedActivity.saisieMax : ' + this.selectedActivity.saisieMax);
                this.modifMaxSaisieAffiche = moment.duration(this.selectedActivity.saisieMax, 'millisecond')
                  .format('h[h]mm[m]', {userLocale: 'fr'}) + 'in';
              }
              break;
            }
          }
          this.changeHeure();
        },
        (err: HttpErrorResponse) => {
          this.errorContingent(err);
        });
    }
  }

  mandateChanged() {
    if (this.userInput.value && this.userInput.value.michelinId) {
      this.selectedUser = this.userInput.value;
      this.selectedMichelinId = this.selectedUser.michelinId;
    } else {
      this.selectedUser = null;
      this.selectedMichelinId = null;
    }
    this.date = null;
    this.selectedActivity = null;
    this.contingents = [];
  }

  /**
   * Le backend a bien retourné une liste de contingent (éventuellement vide)
   * @param contingentList
   */
  private successContingent(contingentList: Array<IActiviteDTO>) {
    console.debug('contingentList: ' + contingentList);
    if (contingentList.length === 0) {
      this.toastr.error(this.msg('saisieHeures.err.noCredit'), this.msg('lib.Error'));
    } else {
      for (const contingent of contingentList) {
        console.debug('returned contingent.name: ' + contingent.libelleSaisie);
      }
      this.contingents = contingentList;
      for (const contingent of this.contingents) {
        if (contingent.saisieMax != null && !this.typesHeuresCollectifs.includes(contingent.typeHeureId)) {
          contingent.libelleSaisie = contingent.libelleSaisie
            + ' (disponible '
            + moment.duration(contingent.saisieMax, 'millisecond').format('h[h]mm[m]', {userLocale: 'fr'})
            + 'in)';

          console.debug('contingent: ' + contingent.libelleSaisie);
        }
      }
      this.toggleContingent = false;
      if (contingentList.length === 1) {
        this.selectedActivity = contingentList[0];
      }
    }
  }

  /**
   * Le backend a retourné une erreur au lieu de la liste des contingents
   * @param err
   */
  private errorContingent(err: HttpErrorResponse) {
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error.message}`);
      console.error('err: ' + err);
      this.toastr.error(err.error.message, this.msg(err.error.message));
    }
  }

  public accessUserList(): boolean {
    return this.authenticationService.hasAnyAuthority(['ROLE_ADM_USER_LST']);
  }


  displayFn(user: IUser): string {
    return user ? `${user.prenom} ${user.nom}` : '';
  }

}
