import {Component, OnInit} from '@angular/core';
// Import BlockUI decorator & optional NgBlockUI type
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {VersionCheckService} from './auto-reload/version-check.service';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'kze_front';

  @BlockUI() blockUI: NgBlockUI;

  constructor(private versionCheckService: VersionCheckService) {
  }

  ngOnInit() {
    this.versionCheckService.initVersionCheck(environment.versionCheckURL, environment.refreshRate);
    this.versionCheckService.loadBackVersionKze().then();
  }
}



