import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {ConfirmDialogComponent} from '../layouts/confirm-dialog/confirm-dialog.component';
import {Observable} from 'rxjs';
import {IMandat} from '../entities/previsions/mandat.model';
import {AppConfig} from '../initializer/AppConfig';

export interface VersionKze {
  "name": string,
  "version": string,
  "hash": string,
  "branch" : string,
  "log" : string
}

@Injectable()
export class VersionCheckService {
  // On est déjà en train de poser la question (évite les milles-feuilles)
  private alreadyAskingUser: boolean = false;

  public frontVersionKze : VersionKze|null = VersionCheckService.getFrontVersionKze();
  public backVersionKze : VersionKze|null = null;

  constructor(
    private http: HttpClient,
    private readonly reloadDialog: MatDialog,
  ) {
  }

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in seconds, defaults to 30 minutes
   */
  public initVersionCheck(url, frequency = 60 * 30) {
    console.info('Version checking polling starting with refresh rate of', frequency, 'seconds.');
    this.checkVersion(url);
    setInterval(() => {
      this.checkVersion(url);
    }, frequency * 1000);
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url) {
    if (!this.alreadyAskingUser) {
      // timestamp these requests to invalidate caches
      this.http.get(url + '?t=' + new Date().getTime())
        .subscribe(
          (response: VersionKze) => {
            const newHash = response.hash;
            const lastVersion = VersionCheckService.getFrontVersionKze();
            // If no version returned (dev build?)
            if (!newHash) {
              console.warn('Front version not returned correctly (dev build?), doing nothing');
            }
            else {
              // If new version, do something
              if (!lastVersion || newHash !== lastVersion.hash) {
                console.info('Front version change detected, asking user to reload');
                this.confirmReload(response);
              } else {
                console.info('Front version not changed, doing nothing');
              }
            }
          },
          (err) => {
            console.error(err, 'Could not get version');
          }
        );
    }
  }

  /**
   * Confirmation de l'utilisateur pour recharger l'appli après avoir noté la nouvelle version
   * @param newVersionKze
   * @private
   */
  private confirmReload(newVersionKze: VersionKze) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: `Mise à jour TOTEM ${newVersionKze.version}`,
      message: `Une nouvelle version de TOTEM est disponible !<br />Il est fortement conseillé d'appliquer la mise à jour.<br />Si vous êtes au milieu d'une saisie,<br />choisissez 'Plus tard', terminez puis faites Ctrl+F5`,
      cancelButton: 'Plus tard',
      okButtonText: 'Mettre à jour maintenant'
    };
    this.alreadyAskingUser = true;
    const dialogRef = this.reloadDialog.open(ConfirmDialogComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(
      reload => {
        this.alreadyAskingUser = false;
        if (reload) {
          console.info('Front version change detected, user agreed to reload');
          localStorage.setItem('versionKzeFront', JSON.stringify(newVersionKze));
          this.frontVersionKze = newVersionKze;
          location.reload();
        }
      }
    );
  }

  /**
   * Récupération du numéro de version depuis le localStorage.
   * Retourne null si c'est pas du Json comifo
   * @private
   */
  private static getFrontVersionKze() : VersionKze|null {
    try {
      return JSON.parse(localStorage.getItem('versionKzeFront'));
    }
    catch {
      return null;
    }
  }

  /**
   * Récupération de la version du backend, à retenter tant qu'on prend une erreur
   * (ce qui est normal quand on n'est pas connecté)
   */
  public async loadBackVersionKze() {
    console.info('Trying to get versionKzeBack')
    localStorage.setItem('versionKzeBack', null);
    const retry = (fn, ms=10000) => new Promise<VersionKze>(resolve => {
      fn()
        .then(resolve)
        .catch(() => {
          setTimeout(() => {
            console.warn('Retrying to get versionKzeBack...');
            retry(fn, ms).then(resolve);
          }, ms);
        })
    });
    this.backVersionKze = await retry(<VersionKze>() => this.http.get<VersionKze>(`${AppConfig.settings.api.versionUrl}/get`).toPromise<VersionKze>());
    localStorage.setItem('versionKzeBack', JSON.stringify(this.backVersionKze));
    console.info(`versionKzeBack retrieved : ${this.backVersionKze.version}`);
  }
}
