import { AppConfig } from '../initializer/AppConfig';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CseService = /** @class */ (function () {
    function CseService(http) {
        this.http = http;
    }
    CseService.prototype.getAssignableCse = function (matricule) {
        return this.http.get(AppConfig.settings.api.adminUrl + "/assignableCses/" + matricule);
    };
    CseService.ngInjectableDef = i0.defineInjectable({ factory: function CseService_Factory() { return new CseService(i0.inject(i1.HttpClient)); }, token: CseService, providedIn: "root" });
    return CseService;
}());
export { CseService };
