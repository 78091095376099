import {Component, OnInit} from '@angular/core';
import {TranfertService} from '../../services/tranfert.service';
import {ITransfert} from '../../entities/tranfert-de-temps/transfert.model';
import {MatDialog, MatDialogConfig, MatTableDataSource} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {TransfertPonctuelEditDialogComponent} from '../transfert-ponctuel-edit-dialog/transfert-ponctuel-edit-dialog.component';
import {ConfirmDialogComponent} from '../../layouts/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-transfert-ponctuel-management',
  templateUrl: './transfert-ponctuel-management.component.html',
  styleUrls: ['./transfert-ponctuel-management.component.scss']
})
export class TransfertPonctuelManagementComponent implements OnInit {

  transferts: ITransfert[];

  transfertsDataSource: MatTableDataSource<ITransfert>;

  constructor(private readonly tranfertService: TranfertService,
              private readonly editDialog: MatDialog,
              private readonly toastrService: ToastrService) {
  }

  ngOnInit() {
    this.loadTransferts();
  }

  private loadTransferts() {
    this.tranfertService.getTransfertsPonctuels().subscribe(transferts => {
      this.transferts = transferts;
      this.transfertsDataSource = new MatTableDataSource<ITransfert>(this.transferts);
    });
  }


  modify(transfert: ITransfert) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {transfert};
    const dialogRef = this.editDialog.open(TransfertPonctuelEditDialogComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(
      () => this.loadTransferts());
  }

  effectiveDeleteAfterConfirm(transfert: ITransfert) {
    this.tranfertService.deleteTransfert(transfert).subscribe(next => {
      this.toastrService.success('Transfert supprimé');
      this.loadTransferts();
    }, error => this.toastrService.error(error.error.message));
  }


  recoverUnused(transfert: ITransfert) {
    if (+transfert.alreadyUsed === 0) {
      this.delete(transfert);
    } else {
      this.tranfertService.updateTransfert(transfert, +transfert.alreadyUsed).subscribe(
        () => this.loadTransferts(),
        error => this.toastrService.error(error.error.message)
      );
    }
  }


  delete(transfert: ITransfert) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Suppression de transfert',
      message: `Supprimer le transfert ?`
    };
    const dialogRef = this.editDialog.open(ConfirmDialogComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(
      val => {
        if (val) {
          this.effectiveDeleteAfterConfirm(transfert);
        }
      }
    );
  }
}
