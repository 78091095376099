<h1 mat-dialog-title>suppression</h1>
<div mat-dialog-content>
  <p>Voulez vous supprimer cet element ?</p>
<!--  <mat-form-field>-->
    <input hidden matInput [(ngModel)]="data">
<!--  </mat-form-field>-->
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Annuler</button>
  <button mat-button [mat-dialog-close]="data" >Supprimer</button>
</div>
