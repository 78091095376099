import { Router } from '@angular/router';
import { UserRouteAccessService } from '../services/user-route-access-service';
import { UserDetailsComponent } from './user-details/user-details.component';
import { EMPTY, of } from 'rxjs';
import { User } from '../entities/user/user.model';
import { UserService } from '../services/user.service';
import { flatMap } from 'rxjs/internal/operators';
import { MandatComponent } from './mandat/mandat.component';
import { MandatService } from '../services/mandat.service';
import { ContingentManagementComponent } from './contingent-management/contingent-management.component';
import * as i0 from "@angular/core";
import * as i1 from "../services/user.service";
import * as i2 from "@angular/router";
import * as i3 from "../services/mandat.service";
var UserResolve = /** @class */ (function () {
    function UserResolve(userService, router) {
        this.userService = userService;
        this.router = router;
    }
    UserResolve.prototype.resolve = function (route) {
        var _this = this;
        var id = route.params['matricule'];
        if (id) {
            return this.userService.find(id).pipe(flatMap(function (user) {
                if (user.body) {
                    return of(user.body);
                }
                else {
                    _this.router.navigate(['404']);
                    return EMPTY;
                }
            }));
        }
        return of(new User());
    };
    UserResolve.ngInjectableDef = i0.defineInjectable({ factory: function UserResolve_Factory() { return new UserResolve(i0.inject(i1.UserService), i0.inject(i2.Router)); }, token: UserResolve, providedIn: "root" });
    return UserResolve;
}());
export { UserResolve };
var MandatResolve = /** @class */ (function () {
    function MandatResolve(mandatService, router) {
        this.mandatService = mandatService;
        this.router = router;
    }
    MandatResolve.prototype.resolve = function (route) {
        var _this = this;
        var id = route.params['mandatId'];
        return this.mandatService.find(id).pipe(flatMap(function (mandat) {
            if (mandat.body) {
                return of(mandat.body);
            }
            else {
                _this.router.navigate(['404']);
                return EMPTY;
            }
        }));
    };
    MandatResolve.ngInjectableDef = i0.defineInjectable({ factory: function MandatResolve_Factory() { return new MandatResolve(i0.inject(i3.MandatService), i0.inject(i2.Router)); }, token: MandatResolve, providedIn: "root" });
    return MandatResolve;
}());
export { MandatResolve };
export var userManagementRoute = [
    {
        path: 'create',
        component: UserDetailsComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
            user: UserResolve
        },
        data: {
            authorities: ['ROLE_ADM_USER_RW']
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: ':matricule/edit',
        component: UserDetailsComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
            user: UserResolve
        },
        data: {
            authorities: ['ROLE_ADM_USER_RW', 'ROLE_ADM_USER_RO']
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: ':matricule/mandats',
        component: MandatComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
            user: UserResolve
        },
        data: {
            authorities: ['ROLE_ADM_USER_RW', 'ROLE_ADM_USER_RO']
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: ':matricule/mandat/:mandatId/contingents',
        component: ContingentManagementComponent,
        resolve: {
            mandat: MandatResolve
        },
        canActivate: [UserRouteAccessService],
        data: {
            authorities: ['ROLE_ADM_USER_LST', 'ROLE_ADM_USER_RO', 'ROLE_ADM_USER_RW']
        }
    },
];
