/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./error.component";
import * as i2 from "@angular/router";
var styles_ErrorComponent = [];
var RenderType_ErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ErrorComponent, data: {} });
export { RenderType_ErrorComponent as RenderType_ErrorComponent };
export function View_ErrorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "hipster img-fluid rounded"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 9, "div", [["class", "col-md-8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Page d'erreur !"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", " "])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "alert alert-danger"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Vous n'avez pas l'autorisation de voir cette page. "])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "alert alert-danger"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Cette page n'existe pas. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.errorMessage; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.errorMessage; _ck(_v, 9, 0, currVal_1); var currVal_2 = !_co.error403; _ck(_v, 10, 0, currVal_2); var currVal_3 = !_co.error404; _ck(_v, 12, 0, currVal_3); }); }
export function View_ErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i0.ɵdid(1, 114688, null, 0, i1.ErrorComponent, [i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorComponentNgFactory = i0.ɵccf("ng-component", i1.ErrorComponent, View_ErrorComponent_Host_0, {}, {}, []);
export { ErrorComponentNgFactory as ErrorComponentNgFactory };
