<div class="container"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0">
  <div fxFlex="auto"></div>
  <div class="titrePage" fxFlex>{{pageTitle}}</div>
  <div fxFlex="auto"></div>
</div>

<br><br>

<div class="container"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0">
  <div class="item item-5" fxFlex="15em" fxFlex.lt-md="0em"></div>


  <div class="item item-4" fxFlex fxFlexOffset="50px"  fxFlexOffset.xs="0">


    <mat-card *ngIf="authenticationService.isSecretaireCSE()">
      <mat-card-header>{{msg('extractionCse.header')}}</mat-card-header>
      <mat-card-content>
        <mat-form-field style="width:50px;">
          <mat-label>{{msg('extractionCse.year')}}</mat-label>
          <input matInput name="year"
                 [(ngModel)]='selectedYear'
                 class="number-right"
                 inputmode="numeric"
                 type="number"
                 [min]="minYear"
                 [max]="maxYear"
                 required/>
        </mat-form-field>
        <button mat-raised-button style="display: inline;"
                class="app-item"
                (click)="(extractionCSE())">
          {{msg('extractionCse.btn')}}
        </button>
      </mat-card-content>
    </mat-card>
  </div>


  <button mat-raised-button style="display: inline;"
          class="app-item"
          (click)="this.router.navigate(['gestion-previsions'])">
    Gestion des prévisions
  </button>

  <div class="item item-5" fxFlex="auto"></div>
</div>




