<div
     fxLayout="column"
     fxLayoutAlign="center center"
     fxFlexAlign="center"
     fxLayoutGap.xs="0"
>

  <div class="titrePage" fxFlex>{{pageTitle}}</div>

  <div flex fxLayout="row" fxLayoutGap="1em" >


    <mat-form-field fxFlex="0 0 20em">
      <mat-label>Contingent</mat-label>
      <mat-select  [(ngModel)]="selectedCreditId" (selectionChange)="creditSelectedChanged()" >
        <mat-option *ngFor="let credit of selectableCredits" [value]="credit.id">
          {{credit.effectDate|date:'yyyy'}} - {{credit.groupeFonctionLibelle}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="selectedCredit"  fxLayoutGap="1em" fxFlexAlign="center">

        <mat-label>
          Total initial {{selectedCredit.allocated|millisToHoursMinutes}}
        </mat-label>
      <mat-label>
        Total consommé {{(selectedCredit.allocated - selectedCredit.remaining)|millisToHoursMinutes}}
      </mat-label>
      <mat-label>
        Total alloué {{selectedCredit.forecast|millisToHoursMinutes}}
      </mat-label>
      </div>

    <div *ngIf="!selectedCredit"  fxLayoutGap="1em" fxFlexAlign="center">Pas de données pour cette période</div>
  </div>

  <div  flex fxLayout="row" fxLayoutGap="1em"  >
  <mat-form-field  *ngIf="selectedCredit"  fxFlex="0 0 20em" fxFlexAlign="">
    <mat-label>Sous-contingent</mat-label>
    <mat-select  [(ngModel)]="commission" (selectionChange)="commissionChanged()" [compareWith]="compareFonctions">
      <mat-option></mat-option>
      <mat-option *ngFor="let commission of selectedCredit.eligibleFonctions" [value]="commission">
        {{commission.libelle}}
      </mat-option>
    </mat-select>
  </mat-form-field>

    <div *ngIf="selectedCredit&&commission"  fxLayoutGap="1em" fxFlexAlign="center">
      <mat-label>
        Consommé {{commission.totalDurationUsed|millisToHoursMinutes}}
      </mat-label>
      <mat-label>
        Alloué {{commission.totalDurationAllocated|millisToHoursMinutes}}
      </mat-label>
    </div>
  </div>

      <div class="table-container" [hidden]="!(selectedCredit&&commission)" >
        <mat-table #datatable [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="fonctionLibelle">
            <mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>Fonction</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let prevision"> {{prevision.fonctionLibelle}} </mat-cell>
            <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="mandate">
            <mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>Mandaté</span>
              <mat-form-field appearance="outline">
                <input matInput class="form-field" [formControl]="mandateFilter" placeholder="Filtrer par mandaté">
              </mat-form-field>
            </mat-header-cell>
            <mat-cell *matCellDef="let prevision"> {{prevision.mandate}} </mat-cell>
            <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
          </ng-container>


          <ng-container matColumnDef="effectDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Date</mat-header-cell>
            <mat-cell *matCellDef="let prevision"> {{prevision.effectDate |date:'MMM yyyy'}} </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="duration">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Prévu</mat-header-cell>
            <mat-cell
              *matCellDef="let prevision"> {{prevision.duration|millisToHoursMinutes}} </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{filteredTotal|millisToHoursMinutes}}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="durationUsed">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Consommé</mat-header-cell>
            <mat-cell [ngStyle]="prevision.durationUsed>prevision.duration? {'color': 'red'}:{'color':'black'}"
                      *matCellDef="let prevision"> {{prevision.durationUsed|millisToHoursMinutes}} </mat-cell>
            <mat-footer-cell *matFooterCellDef
                             [ngStyle]="filteredUsedTotal>filteredTotal? {'color': 'red'}:{'color':'black'}">
              {{filteredUsedTotal|millisToHoursMinutes}}</mat-footer-cell>
          </ng-container>


          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>
            <button mat-button (click)="createMultiple()">
                <mat-icon matTooltipPosition="right">playlist_add_check</mat-icon>
              </button>
            </mat-header-cell>
            <mat-cell *matCellDef="let prevision">
              <button mat-button (click)="modify(prevision)">
                <mat-icon matTooltip="modifier" matTooltipPosition="right" disabled="buttonDisabled">edit</mat-icon>
              </button>
              <!--<button mat-button (click)="delete(prevision)">
                <mat-icon matTooltip="supprimer" matTooltipPosition="right" disabled="buttonDisabled">delete</mat-icon>
              </button>-->
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="['mandate','effectDate','duration','durationUsed','action']; sticky: true"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: ['mandate','effectDate','duration','durationUsed','action'];"></mat-row>
          <mat-footer-row
            *matFooterRowDef="['mandate','effectDate','duration','durationUsed','action']; sticky: true"></mat-footer-row>

        </mat-table>
        <!-- <mat-paginator #paginator
                        [length]="dataSource.data.length"
                        [pageIndex]="0"
                        [pageSize]="50"
                        [pageSizeOptions]="[25, 50, 100, 250]">
         </mat-paginator>-->

      </div>

</div>


