/**
 * Import de la locale 'french'
 */
import { AppConfig } from './initializer/AppConfig';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeFr, 'fr');
var ɵ0 = { useUtc: true }, ɵ1 = function (appConfig) { return function () { return appConfig.getSettings(); }; };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
