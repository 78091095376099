<div fxLayout="column"
     fxLayoutAlign="center center"
     fxLayoutGap="10px">

  <div class="titrePage" fxFlex>Transferts récurrents</div>

  <div>
    <mat-form-field style="min-width: 20em;">
      <mat-label>Elu donneur</mat-label>
      <mat-select name="mandatSourceSelect"
                  [(ngModel)]='transfertSource'
                  (selectionChange)="transfertSourceChanged()"
                  required>
        <mat-option *ngFor="let mandat of possibleTransfertSources"
                    [value]="mandat">
          {{mandat.name}} {{mandat.firstName}} {{mandat.functionLabel}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-button (click)="create()" [disabled]="!transfertSource">Nouveau transfert récurrent</button>
  </div>

  <mat-table [dataSource]="transfertsDataSource" matSort style="min-width: 70em;">

    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef mat-sort-header="dateDebit">
        Date
      </mat-header-cell>
      <mat-cell
        *matCellDef="let transfert"> {{transfert.dateDebit|date}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="effectDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Date d'effet
      </mat-header-cell>
      <mat-cell
        *matCellDef="let transfert"> {{transfert.effectDate|date}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="activite">
      <mat-header-cell *matHeaderCellDef>
        Activité
      </mat-header-cell>
      <mat-cell *matCellDef="let transfert"> {{transfert.natureActiviteComplet}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="recipient">
      <mat-header-cell *matHeaderCellDef mat-sort-header="targetMandat.mandate">
        Destinataire
      </mat-header-cell>
      <mat-cell *matCellDef="let transfert"> {{transfert.targetMandat.mandate}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef>
        Transféré
      </mat-header-cell>
      <mat-cell *matCellDef="let transfert"> {{transfert.duree|millisToHoursMinutes}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="used">
      <mat-header-cell *matHeaderCellDef>
        Consommé
      </mat-header-cell>
      <mat-cell *matCellDef="let transfert"> {{transfert.alreadyUsed|millisToHoursMinutes}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="remaining">
      <mat-header-cell *matHeaderCellDef>
        Restant
      </mat-header-cell>
      <mat-cell
        *matCellDef="let transfert"> {{transfert.duree - transfert.alreadyUsed|millisToHoursMinutes}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef>Action
      </mat-header-cell>
      <mat-cell *matCellDef="let transfert">
        <button mat-button (click)="modify(transfert)" class="actionButton"
                [disabled]="+transfert.alreadyUsed === +transfert.maximumModificationDuration">
          <mat-icon matTooltip="Modifier" matTooltipPosition="right">edit</mat-icon>
        </button>

        <button mat-button (click)="recoverUnused(transfert)" class="actionButton"
                [disabled]="+transfert.alreadyUsed === +transfert.duree">
          <mat-icon matTooltip="Restituer le non consommé" matTooltipPosition="right">delete</mat-icon>
        </button>

      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      matTooltip="Sélectionner tous">
        </mat-checkbox>
        <button mat-button (click)="confirmMassRecoverTime()" class="actionButton"
                [disabled]="!isMassrecovertTimeEnabled()">
          <mat-icon matTooltip="Restituer le non consommé pour la sélection" matTooltipPosition="right">delete
          </mat-icon>
        </button>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [disabled]="+row.alreadyUsed === +row.duree">
        </mat-checkbox>
      </mat-cell>
    </ng-container>


    <mat-header-row
      *matHeaderRowDef="['date','effectDate', 'recipient','activite','amount','used', 'remaining','action','select']; sticky: true"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: ['date','effectDate', 'recipient','activite','amount','used', 'remaining','action','select'];"></mat-row>

  </mat-table>
</div>

