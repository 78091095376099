<h2 mat-dialog-title>Prevision</h2>

<mat-dialog-content>


  <div class="form">
    <mat-form-field>
      <input #input [(ngModel)]="data.prevision.fonctionLibelle" class="form-control" disabled="data.prevision.id" matInput
             name="fonction"
             required>
    </mat-form-field>
  </div>

  <div class="form">
    <mat-form-field>
      <input matInput #input class="form-control" placeholder="Mandaté" [(ngModel)]="data.prevision.mandate"
             name="mandate"
             disabled="data.prevision.id"
             required>
    </mat-form-field>
  </div>


  <div class="form">
    <mat-form-field>
      <input matInput #input [matDatepicker]="picker" class="form-control" placeholder="Date d'effet"
             [(ngModel)]="data.prevision.effectDate" name="dateeffect" required
      disabled="date.prevision.id">
      <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form">


    <mat-form-field style="width:100px">
      <mat-label>{{msg('transfert.transfertHeures')}}</mat-label>
      <input matInput name="hoursToTranfert"
             [(ngModel)]='hours'
             class="number-right"
             inputmode="numeric"
             type="number"
             min="0"
             required/>
    </mat-form-field>
    <mat-form-field style="width:100px">
      <mat-label>{{msg('transfert.transfertMinutes')}}</mat-label>
      <input matInput name="minutesToTranfert"
             [(ngModel)]='minutes'
             class="number-right"
             type="number"
             inputmode="numeric"
             min="0"
             max="60"
             required/>
    </mat-form-field>

  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="mat-raised-button" (click)="close()">Annuler</button>
  <button class="mat-raised-button mat-primary" (click)="save()">Sauvegarder</button>
</mat-dialog-actions>
