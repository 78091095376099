<div class="container"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0" xmlns="http://www.w3.org/1999/html">
  <div fxFlex="auto"></div>
  <div class="titrePage" fxFlex>Traitement Optimisé des Temps des &Eacute;lus et Mandatés</div>
  <div fxFlex="auto"></div>
</div>

<br><br>
<div class="container"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0">

  <div class="item item-1" fxFlex="auto" fxFlex.lt-md="0em"></div>

  <div class="item item-2" fxFlex="auto"  fxFlexOffset.xs="0">

    <mat-card class="card-top">
      <mat-card-content>
        <mat-card-title>Gérer mon activité</mat-card-title>
        <mat-card-content>

          <button mat-raised-button class="bouton-totem" (click)="saisieDesHeures()">
            <mat-icon>date_range</mat-icon>
            <span>  {{msg('lib.saisieDesHeures')}}</span>
          </button>

          <button mat-raised-button class="bouton-totem" (click)="transfertHeures()">
            <mat-icon>supervisor_account</mat-icon>
            <span>{{msg('menu.transfertTemps')}}</span>
          </button>

          <button mat-raised-button class="bouton-totem" (click)="listeDesHeures()">
            <mat-icon>create</mat-icon>
            <span>{{msg('lib.listeDesHeures')}}</span>
          </button>

          <button mat-raised-button class="bouton-totem" (click)="extractionContingent()">
            <mat-icon>list_alt</mat-icon>
            <span>{{msg('menu.rapportActivite')}}</span>
          </button>


        </mat-card-content>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="item item-3" fxFlex="auto"  fxFlexOffset.xs="0" *ngIf="authenticationService.hasOrganisation()">
    <mat-card class="card-top">
      <mat-card-content>
        <mat-card-title>{{msg('organisations.title')}}</mat-card-title>
        <mat-card-content>
          <button mat-raised-button class="bouton-totem" (click)="goToRelationsSociales()" *ngIf="authenticationService.isRelationSociale()">
            <mat-icon>sentiment_satisfied</mat-icon>
            <span>{{msg('relationsSociales.title')}}</span>
          </button>
        </mat-card-content>
        <mat-card-content>
          <button mat-raised-button class="bouton-totem" (click)="goToCse()" *ngIf="authenticationService.isSecretaireCSE()">
            <mat-icon>sentiment_satisfied</mat-icon>
            <span>{{msg('cse.title')}}</span>
          </button>
        </mat-card-content>
        <mat-card-content>
          <button mat-raised-button class="bouton-totem" (click)="goToOS()" *ngIf="authenticationService.isDelegueCentralOS()">
            <mat-icon>sentiment_satisfied</mat-icon>
            <span>{{msg('OS.title')}}</span>
          </button>
        </mat-card-content>
      </mat-card-content>
    </mat-card>
  </div>



</div>



