import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {AppConfig} from './app/initializer/AppConfig';

/*if (AppConfig.settings.env.production) {
  enableProdMode();
}*/

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.debug(err));
