import {Component, OnInit, ViewChild} from '@angular/core';
import {ITransfert} from '../../../../entities/tranfert-de-temps/transfert.model';
import {TranfertService} from '../../../../services/tranfert.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog, MatDialogConfig, MatSort, MatTableDataSource} from '@angular/material';
import {ITransfertParticipant} from '../../../../entities/tranfert-de-temps/ITransfertParticipant';
import {TransfertRecurrentEditDialogComponent} from '../transfert-recurrent-edit-dialog/transfert-recurrent-edit-dialog.component';
import {TransfertRecurrentCreationComponent} from '../transfert-recurrent-creation/transfert-recurrent-creation.component';
import {ConfirmDialogComponent} from '../../../../layouts/confirm-dialog/confirm-dialog.component';
import {SelectionModel} from '@angular/cdk/collections';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'app-transfert-recurrent-management',
  templateUrl: './transfert-recurrent-management.component.html',
  styleUrls: ['./transfert-recurrent-management.component.scss']
})
export class TransfertRecurrentManagementComponent implements OnInit {


  transfertSource: ITransfertParticipant;
  possibleTransfertSources: ITransfertParticipant[];

  transferts: ITransfert[];

  transfertsDataSource: MatTableDataSource<ITransfert> = new MatTableDataSource<ITransfert>([]);
  selection = new SelectionModel<ITransfert>(true, []);

  @ViewChild(MatSort) sort: MatSort;

  constructor(private readonly tranfertService: TranfertService,
              private readonly editDialog: MatDialog,
              private readonly toastrService: ToastrService) {
  }

  ngOnInit() {
    this.tranfertService.getTransferableMandats().subscribe(mandats => this.possibleTransfertSources = mandats);
    this.transfertsDataSource = new MatTableDataSource<ITransfert>([]);
  }

  transfertSourceChanged() {
    this.loadTransferts();
  }

  private loadTransferts() {
    this.tranfertService.getTransfertsRecurrents(this.transfertSource).subscribe(transferts => {
      this.transferts = transferts;
      this.transfertsDataSource = new MatTableDataSource<ITransfert>(this.transferts);
      this.transfertsDataSource.sort = this.sort;
      this.transfertsDataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
        return this.getPropertyByPath(data, sortHeaderId);
      };
    }, error => this.toastrService.error(error.error.message));
    this.selection.clear();
  }

  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  modify(transfert: ITransfert) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {transfert};
    const dialogRef = this.editDialog.open(TransfertRecurrentEditDialogComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(
      () => this.loadTransferts());
  }

  recoverUnused(transfert: ITransfert) {
    if (+transfert.alreadyUsed === 0) {
      this.delete(transfert);
    } else {
      this.tranfertService.updateTransfert(transfert, +transfert.alreadyUsed).subscribe(
        () => this.loadTransferts(),
        error => this.toastrService.error(error.error.message)
      );
    }
  }

  massRecoverUnusedTime() {
    forkJoin(this.selection.selected.map(it => (+it.alreadyUsed === 0)
      ? this.tranfertService.deleteTransfert(it)
      : this.tranfertService.updateTransfert(it, +it.alreadyUsed)))
      .subscribe(() => {
          this.toastrService.success('Transferts ajustés');
          this.loadTransferts();
        }, error => this.toastrService.error(error.error.message)
      );
  }


  effectiveDeleteAfterConfirm(transfert: ITransfert) {
    this.tranfertService.deleteTransfert(transfert).subscribe(() => {
      this.toastrService.success('Transfert supprimé');
      this.loadTransferts();
    }, error => this.toastrService.error(error.error.message));
  }


  delete(transfert: ITransfert) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Suppression de transfert',
      message: `Supprimer le transfert ?`
    };
    const dialogRef = this.editDialog.open(ConfirmDialogComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(
      val => {
        if (val) {
          this.effectiveDeleteAfterConfirm(transfert);
        }
      }
    );
  }


  create() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {transfertSource: this.transfertSource};
    const dialogRef = this.editDialog.open(TransfertRecurrentCreationComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(
      value => {
        if (value) {
          this.loadTransferts();
        }
      }
    );
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.transfertsDataSource.data.filter(it => +it.alreadyUsed < +it.duree).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.transfertsDataSource.data.filter(it => +it.alreadyUsed < +it.duree).forEach(transfert => this.selection.select(transfert));

  }

  isMassrecovertTimeEnabled() {
    return this.selection.selected.length > 0 && this.selection.selected.every(it => +it.alreadyUsed < +it.duree);
  }

  confirmMassRecoverTime() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Ajustement des transferts',
      message: `Le nombre d'heures des transferts sélectionnés sera réduit au temps effectivement consommé par leur destinaire.<br />
      Les transferts sans aucune consommation seront supprimés.`
    };
    const dialogRef = this.editDialog.open(ConfirmDialogComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(
      val => {
        if (val) {
          this.massRecoverUnusedTime();
        }
      }
    );
  }

}
