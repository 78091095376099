import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import {AppConfig} from '../initializer/AppConfig';
import {IMandat} from '../entities/previsions/mandat.model';
import {ICreditIndividuel} from '../entities/creditIndividuel.model';
import {ITypeHeure} from '../entities/type-heure';
import {INatureActivite} from '../entities/nature-activite';
import {IContingentMax} from '../entities/contingentMax.model';

@Injectable({
  providedIn: 'root'
})
export class MandatService {

  constructor(private readonly http: HttpClient) {
  }

  getMandatsByUser(matricule: string): Observable<IMandat[]> {
    return this.http.get<IMandat[]>(`${AppConfig.settings.api.adminUrl}/mandats/${matricule}`);
  }

  public find(mandatId: number): Observable<HttpResponse<IMandat>> {
    return this.http.get<IMandat>(`${AppConfig.settings.api.adminUrl}/mandat/${mandatId}`, {observe: 'response'});
  }

  create(mandat: IMandat): Observable<HttpResponse<IMandat>> {
    return this.http.post<IMandat>(`${AppConfig.settings.api.adminUrl}/mandat`, mandat, {observe: 'response'});
  }

  update(mandat: IMandat): Observable<HttpResponse<IMandat>> {
    return this.http.put<IMandat>(`${AppConfig.settings.api.adminUrl}/mandat`, mandat, {observe: 'response'});
  }

  getCredits(mandat: IMandat, typeHeure: ITypeHeure): Observable<ICreditIndividuel[]> {

    const params = new HttpParams().set('mandatId', String(mandat.id)).set('typeHeureId', String(typeHeure.typeHeureId));

    return this.http.get<ICreditIndividuel[]>(`${AppConfig.settings.api.adminUrl}/credits`, {params});
  }


  updateCredit(credit: ICreditIndividuel): Observable<HttpResponse<ICreditIndividuel>> {
    return this.http.patch<ICreditIndividuel>(`${AppConfig.settings.api.adminUrl}/credit`, credit, {observe: 'response'});
  }

  initializeCredits(mandat: IMandat, typeHeure: ITypeHeure, year: number): Observable<HttpResponse<ICreditIndividuel[]>> {
    return this.http.post<ICreditIndividuel[]>(`${AppConfig.settings.api.adminUrl}/credits`, {
      mandatId: mandat.id,
      typeHeureId: typeHeure.typeHeureId,
      annee: year
    }, {observe: 'response'});
  }

  getNatureActivites(mandat: IMandat): Observable<INatureActivite[]> {
    return this.http.get<INatureActivite[]>(`${AppConfig.settings.api.adminUrl}/natureActivites/${mandat.fonction.id}`);
  }

  getContingentMax(mandat: IMandat): Observable<IContingentMax[]> {
    return this.http.get<IContingentMax[]>(`${AppConfig.settings.api.adminUrl}/credit/contingentMax/${mandat.id}`);
  }

}
