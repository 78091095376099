import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { AppConfig } from '../../initializer/AppConfig';
import { MatDialog } from '@angular/material';
import { ContactDialogComponent } from '../contact-dialog/contact-dialog.component';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(router, authenticationService, dialog) {
        this.router = router;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authenticationService.validateToken().subscribe({ error: function () { return _this.authenticationService.logout(); } });
    };
    /**
     * Accès aux textes/messages paramétrés
     * @param key
     */
    HeaderComponent.prototype.msg = function (key) {
        return AppConfig.msg(key);
    };
    Object.defineProperty(HeaderComponent.prototype, "tokenObject", {
        get: function () {
            return AuthenticationService.tokenObject();
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.goToLoginPage = function () {
        this.router.navigate(['/login']);
    };
    HeaderComponent.prototype.goToAccueil = function () {
        this.router.navigate(['/home']);
    };
    HeaderComponent.prototype.goToRelationsSociales = function () {
        this.router.navigate(['relationsSociales']);
    };
    HeaderComponent.prototype.goToCse = function () {
        this.router.navigate(['cse']);
    };
    HeaderComponent.prototype.goToOS = function () {
        this.router.navigate(['organisationSyndicale']);
    };
    HeaderComponent.prototype.logoutProcess = function () {
        console.debug('logging out');
        this.authenticationService.logout();
    };
    HeaderComponent.prototype.stopImpersonnation = function () {
        console.debug('Stopping impersonnation');
        this.authenticationService.stopImpersonation();
    };
    HeaderComponent.prototype.saisieDesHeures = function () {
        console.debug('saisieDesHeures');
        this.router.navigate(['saisieDesHeures']);
    };
    HeaderComponent.prototype.navigateToAdministration = function () {
        this.router.navigate(['administration']);
    };
    HeaderComponent.prototype.listeDesHeures = function () {
        console.debug('listeDesHeures');
        this.router.navigate(['listeDesHeures']);
    };
    HeaderComponent.prototype.transfertHeuresManagement = function () {
        this.router.navigate(['transfertManagement']);
    };
    HeaderComponent.prototype.extractionContingent = function () {
        console.debug('extractionContingent');
        this.router.navigate(['extractionContingent']);
    };
    HeaderComponent.prototype.transfertHeures = function () {
        console.debug('transfertHeures');
        this.router.navigate(['transfertHeures']);
    };
    HeaderComponent.prototype.goToUserManagement = function () {
        this.router.navigate(['users']);
    };
    HeaderComponent.prototype.openContactDialog = function () {
        var dialogRef = this.dialog.open(ContactDialogComponent, {
            width: '600px',
            hasBackdrop: true
        });
    };
    return HeaderComponent;
}());
export { HeaderComponent };
