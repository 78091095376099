import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {AppConfig} from '../../initializer/AppConfig';
import {ITransfert} from '../../entities/tranfert-de-temps/transfert.model';
import {TranfertService} from '../../services/tranfert.service';

@Component({
  selector: 'app-transfert-ponctuel-edit-dialog',
  templateUrl: './transfert-ponctuel-edit-dialog.component.html',
  styleUrls: ['./transfert-ponctuel-edit-dialog.component.scss']
})
export class TransfertPonctuelEditDialogComponent implements OnInit {
  transfert: ITransfert;

  hours?: number;
  minutes?: number;

  constructor(private readonly dialogRef: MatDialogRef<TransfertPonctuelEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { transfert: ITransfert },
              private readonly transfertService: TranfertService,
              private readonly toastrService: ToastrService) {
    this.transfert = data.transfert;

    this.hours = Math.trunc(this.transfert.duree / 3600000);
    this.minutes = (this.transfert.duree - this.hours * 3600000) / 60000;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.transfertService.updateTransfert(this.transfert, this.durationInMillis()).subscribe(response => {
      this.toastrService.success('Modification effectuée');
      this.transfert.duree = this.durationInMillis();
      this.dialogRef.close();
    }, error => this.toastrService.error(error.error.message));
  }

  msg(key) {
    return AppConfig.msg(key);
  }

  durationInMillis(): number {
    return this.hours * 3600000 + this.minutes * 60000;
  }

  isValid(): boolean {
    const durationInMillis = this.durationInMillis();
    return durationInMillis != null && durationInMillis >= this.transfert.alreadyUsed
      && (durationInMillis <= this.transfert.duree);
  }

}
