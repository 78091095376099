<div fxLayout="column"
     fxLayoutGap="5px"
     style="width: 70em">

  <div fxLayoutGap="1em" fxLayout="row" fxLayoutAlign="center baseline">
    <h4>Mandats de {{user.prenom}} {{user.nom}}</h4>
    <button mat-raised-button
            *ngIf="authenticationService.hasAnyAuthority(['ROLE_ADM_MANDAT_RW'])"
            (click)="create()">Ajouter un mandat
    </button>
  </div>


  <mat-table [dataSource]="mandatsDataSource" matSort>
    <ng-container matColumnDef="fonction">
      <mat-header-cell *matHeaderCellDef mat-sort-header="fonction.libelle"> Fonction</mat-header-cell>
      <mat-cell *matCellDef="let mandat"> {{mandat.fonction.libelle}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="organisation">
      <mat-header-cell *matHeaderCellDef mat-sort-header="cse.libelle">
        Organisation
      </mat-header-cell>
      <mat-cell *matCellDef="let mandat"> {{mandat.cse.libelle}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="begin">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Début
      </mat-header-cell>
      <mat-cell *matCellDef="let mandat"> {{mandat.begin|date }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="end">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Fin
      </mat-header-cell>
      <mat-cell *matCellDef="let mandat"> {{mandat.end|date }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="modifiedBy">
      <mat-header-cell *matHeaderCellDef>
        Dernière modification
      </mat-header-cell>
      <mat-cell
        *matCellDef="let mandat"> {{mandat.lastModifiedBy ? mandat.lastModifiedBy : mandat.createdBy  }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef>Mandats
      </mat-header-cell>
      <mat-cell *matCellDef="let mandat">
        <button mat-button (click)="modify(mandat)" class="actionButton"
                *ngIf="authenticationService.hasAnyAuthority(['ROLE_ADM_MANDAT_RW']) && (canModifyFonctionSensible() || !mandat.fonction.sensible)">
          <mat-icon matTooltip="Modifier" matTooltipPosition="right" disabled="buttonDisabled">edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="contingents">
      <mat-header-cell *matHeaderCellDef>Contingents
      </mat-header-cell>
      <mat-cell *matCellDef="let mandat">
        <button mat-button (click)="showContingents(mandat)" class="actionButton"
                *ngIf="authenticationService.hasAnyAuthority(['ROLE_ADM_MANDAT_RW']) && mandat.canHaveCreditIndividuel && (canModifyFonctionSensible() || !mandat.fonction.sensible)">
          <mat-icon matTooltip="Gérer les contingents individuels" matTooltipPosition="right" disabled="buttonDisabled">
            alarm_add
          </mat-icon>
        </button>
      </mat-cell>


    </ng-container>
    <mat-header-row
      *matHeaderRowDef="['fonction','organisation','begin','end','modifiedBy','action','contingents']; sticky: true"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: ['fonction','organisation','begin','end','modifiedBy','action','contingents'];"></mat-row>
  </mat-table>
</div>
