import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {formatDate} from '@angular/common';

import {IActiviteDTO} from '../entities/iActiviteDTO';
import {IDebitDTO} from '../entities/iDebitDTO';
import {AppConfig} from '../initializer/AppConfig';

/**
 * Service de liaison vers le backend pour la gestion des heures
 */
@Injectable({
  providedIn: 'root'
})
export class GestionDesHeuresService {

  constructor(private http: HttpClient) {
  }

  /**
   * Récupère la liste des activites depuis le backend
   * @param michelinId
   * @param date
   */
  public getActivitesByDate(michelinId: string, date: Date): Observable<IActiviteDTO[]> {
    return this.http
      .get<IActiviteDTO[]>(AppConfig.settings.api.saisieTempsUrl + '/' + michelinId
        + '/getContingentsByDate?dateDebit='
        + formatDate(date, 'yyyy-MM-dd', 'fr'));
  }

  /**
   * recupere la date de la derniere paye
   *@param date
   */
  public getLatestPaye(): Observable<Date> {
    return this.http
      .get<Date>(AppConfig.settings.api.saisieTempsUrl + '/getLatestPaye');
  }


  /**
   * recupere la date limite de saisie et de modification
   *@param date
   */
  public getHorizonSaisie(): Observable<Date> {
    return this.http
      .get<Date>(AppConfig.settings.api.saisieTempsUrl + '/getHorizonSaisie');
  }


  /**
   * get debit by Id
   * @param id
   */
  public getDebit(id: number): Observable<IDebitDTO> {
    return this.http.get<IDebitDTO>(AppConfig.settings.api.saisieTempsUrl + '/getDebitById?debitId=' + id);
  }


  /**
   * Déclare une nouvelle saisie vers le backend
   * @param conso
   */
  public addDebit(conso: IDebitDTO): Observable<any> {
    const consoEnvoi = {
      debitId: conso.debitId,
      dateDebit: formatDate(conso.dateDebit, 'yyyy-MM-dd', 'fr'),
      heureDebut: conso.heureDebut,
      duree: conso.duree,
      typeHeureId: conso.typeHeureId,
      mandatCseId: conso.mandatCseId,
      commentaire: conso.commentaire
    };
    return this.http.post(AppConfig.settings.api.saisieTempsUrl + '/addDebit', consoEnvoi);
  }


  /**
   * supprime un debit
   * @param debitId
   */
  public supprimeUnDebit(debitId: number): Observable<any> {
    return this.http
      .delete(AppConfig.settings.api.saisieTempsUrl + '/supprimedebitbyid?debitId=' + debitId);
  }

  /**
   * Dmet à jours un débit
   * @param conso
   */
  public updateDebit(conso: IDebitDTO): Observable<any> {
    const consoEnvoi = {
      debitId: conso.debitId,
      dateDebit: formatDate(conso.dateDebit, 'yyyy-MM-dd', 'fr'),
      heureDebut: conso.heureDebut,
      duree: conso.duree,
      typeHeureId: conso.typeHeureId,
      mandatCseId: conso.mandatCseId,
      commentaire: conso.commentaire
    };
    return this.http.put(AppConfig.settings.api.saisieTempsUrl + '/updateDebit', consoEnvoi);
  }
}
