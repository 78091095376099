import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var MessageService = /** @class */ (function () {
    function MessageService() {
        /**
         * message entre composant type string
         */
        this.messageSource = new BehaviorSubject(null);
        /**
         * message entre composant type IDebitExtractDTO
         */
        this.messageDebit = new BehaviorSubject(null);
        /**
         * message pour recharger une page
         */
        this.rechargePageListDebitMessage = this.messageSource.asObservable();
        /**
         * pour transferer objet debitExtract de la page listDebit vers SaisieHeures
         */
        this.transfertParamsDebitModif = this.messageDebit.asObservable();
    }
    MessageService.prototype.changeMessage = function (message) {
        this.messageSource.next(message);
    };
    MessageService.prototype.changeMessageDebitExtract = function (message) {
        this.messageDebit.next(message);
    };
    MessageService.ngInjectableDef = i0.defineInjectable({ factory: function MessageService_Factory() { return new MessageService(); }, token: MessageService, providedIn: "root" });
    return MessageService;
}());
export { MessageService };
