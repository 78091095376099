import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AppConfig} from '../../initializer/AppConfig';
import {HttpErrorResponse} from '@angular/common/http';
import {IActiviteDTO} from '../../entities/iActiviteDTO';
import * as moment from 'moment';
import {TranfertService} from '../../services/tranfert.service';
import {ITransfertParticipant} from '../../entities/tranfert-de-temps/ITransfertParticipant';

@Component({
  selector: 'app-trancsfert-ponctuel-creation',
  templateUrl: './transfert-ponctuel-creation.component.html',
  styleUrls: ['./transfert-ponctuel-creation.component.scss']
})
export class TransfertPonctuelCreationComponent implements OnInit {

 // public mandatSelected?: number;
  public transfertRecipient?: number;
  public availableMandats: Array<IActiviteDTO>;
  public selectedActivity?: IActiviteDTO;

  public availableTransfertRecipients: ITransfertParticipant[];
  public hoursToTranfert = 0;
  public minutesToTranfert = 0;
  public pageTitle = this.msg('menu.transfertTemps');
  public buttonDisabled = true;


  constructor(public toastr: ToastrService, private tranfertService: TranfertService) {
  }

  ngOnInit() {
    this.loadAvailableMandats();
  }

  loadAvailableMandats() {
    this.availableMandats = [];
    this.tranfertService.getTransferableContingents().subscribe(contingentList => {
        this.successContingent(contingentList);
      },
      (err: HttpErrorResponse) => {
        console.error(`Backend returned code ${err.status}, body was: ${err.error.message}`);
        this.toastr.error(err.error.message, this.msg('lib.Error'));
      });
  }

  /**
   * Le backend a bien retourné une liste de contingent (éventuellement vide)
   * @param contingentList
   */
  private successContingent(contingentList: Array<IActiviteDTO>) {

    if (contingentList.length === 0) {
      this.toastr.error(this.msg('transfert.err.NoTransferableMandat'), this.msg('lib.Error'));
    } else {
      this.availableMandats = contingentList;
      for (const contingent of this.availableMandats) {
        contingent.libelleSaisie = contingent.libelleSaisie
          + ' (' + this.msg('transfert.disponibleTransfert') + ' '
          + moment.duration(contingent.saisieMax).format('h[h]mm[m]')
          + 'in)';
      }
      if (contingentList.length === 1) {
        this.selectedActivity = contingentList[0];
        // this.mandatSelected = contingentList[0].mandatId;
        this.mandatChanged(this.selectedActivity);
      }
    }
  }

  mandatChanged(activite: IActiviteDTO) {
    this.availableTransfertRecipients = [];
    this.tranfertService.getPossibleRecipents(this.selectedActivity.mandatId)
      .subscribe(recipientList => this.successRecipients(recipientList));
  }

  private successRecipients(recipientList: Array<ITransfertParticipant>) {
    this.availableTransfertRecipients = [];
    if (recipientList.length === 0) {
      this.toastr.error(this.msg('transfert.err.noTransfertRecipient'), this.msg('lib.Error'));
    } else {
      this.availableTransfertRecipients = [...recipientList].sort((a, b) => a.name.localeCompare(b.name));
      if (recipientList.length === 1) {
        this.transfertRecipient = recipientList[0].mandatCseId;
        this.transfertRecipientChanged(this.transfertRecipient);
      }
    }
  }


  transfertRecipientChanged(recipientMandatId: number) {
    this.updateButtonStatus();
  }

  hoursToTranfertChanged() {
    this.updateButtonStatus();
  }

  minutesToTranfertChanged() {
    this.updateButtonStatus();
  }

  updateButtonStatus() {
    this.buttonDisabled = this.selectedActivity == null || this.transfertRecipient == null
      || (this.hoursToTranfert <= 0 && this.minutesToTranfert <= 0);
  }

  submitTransfert() {
    this.tranfertService.createTransfertPonctuel({
      sourceMandatCseId: this.selectedActivity.mandatId,
      targetMandatCseId: this.transfertRecipient,
      transferredAmountInMinutes: this.hoursToTranfert * 60 + this.minutesToTranfert,
      typeHeureId: this.selectedActivity.typeHeureId
    }).subscribe(result => {
      this.successSaisie(result);
    }, (err: HttpErrorResponse) => {
      this.errorSaisie(err);
    });
  }

  /**
   * Accès aux textes/messages paramétrés
   * @param key
   */
  msg(key) {
    return AppConfig.msg(key);
  }

  /**
   * Si le backend a bien enregistré la saisie...
   * @param result
   */
  private successSaisie(result) {
    // console.log('successSaisie ok: ' + result);
    this.toastr.success(this.msg('saisieHeures.succ.saisie'), this.msg('lib.Success'));
    this.resetForm();
    this.buttonDisabled = true;
  }

  /**
   * Si le backend a rejeté la saisie...
   * @param err
   */
  private errorSaisie(err) {
    // console.log('successSaisie PB: ' + err.message);
    this.toastr.error(this.msg('saisieHeures.err.prefix') + err.error.message, this.msg('lib.Error'));
    // this.router.navigate(['listeDesHeures']);
  }


  private resetForm() {
    this.minutesToTranfert = 0;
    this.hoursToTranfert = 0;
    this.selectedActivity = null;
    this.loadAvailableMandats();
  }

}
