import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {IDebitExtractDTO} from '../../entities/iDebitExtractDTO';

@Component({
  selector: 'app-detail-debit-suppr-dialog',
  templateUrl: './detail-debit-suppr-dialog.component.html',
  styleUrls: ['./detail-debit-suppr-dialog.component.scss']
})
export class DetailDebitSupprDialogComponent {

  constructor(public dialogRef: MatDialogRef<DetailDebitSupprDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IDebitExtractDTO[]) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmeSupr() {
    console.debug('confirmeSupr: ' + this.data);
    this.onNoClick();
  }
}
