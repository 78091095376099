<div>
  <mat-dialog-content>
    <form>
      <div fxLayout="row"
           fxLayoutAlign="center"
           fxLayoutGap="5px">
        <mat-form-field style="width:100px">
          <mat-label>{{msg('transfert.transfertHeures')}}</mat-label>
          <input matInput name="hoursToTranfert"
                 [(ngModel)]='hours'
                 class="number-right"
                 inputmode="numeric"
                 type="number"
                 min="0"
                 required/>
        </mat-form-field>
        <mat-form-field style="width:100px">
          <mat-label>{{msg('transfert.transfertMinutes')}}</mat-label>
          <input matInput name="minutesToTranfert"
                 [(ngModel)]='minutes'
                 class="number-right"
                 type="number"
                 inputmode="numeric"
                 min="0"
                 max="60"
                 required/>
        </mat-form-field>

      </div>
      <div> Crédit consommé : {{(credit.alreadyUsed ? credit.alreadyUsed : 0)|millisToHoursMinutes}}
      </div>
      <div *ngIf="contingentMax"> Crédit maximum : {{contingentMax.quantiteMax|millisToHoursMinutes}}</div>

    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Annuler</button>
    <button class="mat-raised-button mat-primary" (click)="save()" [disabled]="!isValid()">Sauvegarder</button>
  </mat-dialog-actions>

</div>
