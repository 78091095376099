<div fxLayout="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px">
  <div style="text-align: center">
    <h3>{{mandat.mandate}} {{mandat.fonction.libelle}}</h3>
  </div>


  <div fxLayout="row"
       fxLayoutAlign="space-around baseline"
       fxLayoutGap="20px">

    <div fxFlex="none" fxFlexAlign="center">
      <button mat-raised-button routerLink="../../../mandats">
        <mat-icon>arrow_back</mat-icon>
        Revenir aux mandats
      </button>
    </div>

    <mat-form-field fxFlex="4em">
      <mat-label>Année</mat-label>
      <mat-select [(ngModel)]="selectedYear" (selectionChange)="selectedYearChanged()">
        <mat-option *ngFor="let year of selectableYears" [value]="year">
          {{year}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="20em">
      <mat-label>Activité</mat-label>
      <mat-select [(ngModel)]="selectedActivite" (selectionChange)="selectedActiviteChanged()"
                  [compareWith]="compareNatureActivite">
        <mat-option *ngFor="let natureActivite of natureActivites" [value]="natureActivite">
          {{natureActivite.libelleActiviteSaisie}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div fxFlex="none" fxFlexAlign="center">
      <button mat-raised-button (click)="initializeCredits()"
              [disabled]="!selectedActivite || selectedYear < elevenMonthesAgo.getFullYear() || !selectedContingentMax">
        Initialiser les crédits
      </button>
    </div>


  </div>

  <div *ngIf="!selectedContingentMax?.quantiteMax">
    <em>Permet d'ouvrir le droit à la formation. Attention! les crédits de formations restants sont à contrôler via
      l'extraction prévue à cet effet.</em>
  </div>

  <mat-table [dataSource]="creditsDataSource" style="min-width: 60em;">

    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef>
        Période
      </mat-header-cell>
      <mat-cell
        *matCellDef="let credit"> {{credit.effectDate|date:selectedActivite.typeHeure.mensuelle ? 'MMM yyyy' : 'yyyy'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="activite">
      <mat-header-cell *matHeaderCellDef>
        Activité
      </mat-header-cell>
      <mat-cell *matCellDef="let credit"> {{credit.natureActiviteSaisie}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="allocated">
      <mat-header-cell *matHeaderCellDef>
        Crédit alloué
      </mat-header-cell>
      <mat-cell *matCellDef="let credit"> {{credit.allocated|millisToHoursMinutes}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="alreadyUsed">
      <mat-header-cell *matHeaderCellDef>
        Crédit consommé
      </mat-header-cell>
      <mat-cell *matCellDef="let credit"> {{credit.alreadyUsed|millisToHoursMinutes}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="remaining">
      <mat-header-cell *matHeaderCellDef>
        Crédit restant
      </mat-header-cell>
      <mat-cell *matCellDef="let credit"> {{credit.remaining|millisToHoursMinutes}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef>Action
      </mat-header-cell>
      <mat-cell *matCellDef="let credit">
        <button mat-button (click)="modify(credit)" *ngIf="isCreditModifiable(credit)"
                class="actionButton">
          <mat-icon matTooltip="Modifier" matTooltipPosition="right" disabled="buttonDisabled">edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>


    <mat-header-row
      *matHeaderRowDef="['date', 'activite','allocated','alreadyUsed', 'remaining','action']; sticky: true"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: ['date', 'activite','allocated','alreadyUsed', 'remaining','action'];"></mat-row>

  </mat-table>
</div>


