import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
// Import BlockUI decorator & optional NgBlockUI type
import { BlockUI } from 'ng-block-ui';
import { VersionCheckService } from './auto-reload/version-check.service';
import { environment } from '../environments/environment';
var AppComponent = /** @class */ (function () {
    function AppComponent(versionCheckService) {
        this.versionCheckService = versionCheckService;
        this.title = 'kze_front';
    }
    AppComponent.prototype.ngOnInit = function () {
        this.versionCheckService.initVersionCheck(environment.versionCheckURL, environment.refreshRate);
        this.versionCheckService.loadBackVersionKze().then();
    };
    tslib_1.__decorate([
        BlockUI(),
        tslib_1.__metadata("design:type", Object)
    ], AppComponent.prototype, "blockUI", void 0);
    return AppComponent;
}());
export { AppComponent };
