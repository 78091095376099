<mat-toolbar color="primary">
  <div fxLayout="row"
       fxLayout.xs="0"
       fxLayoutAlign="space-between center"
       fxLayoutGap="10px"
       fxLayoutGap.xs="0"
       fxFlexFill>

    <div fxFlexOrder="1" fxFlex="none">

      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
        Menu
      </button>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (click)="goToLoginPage()" *ngIf="!authenticationService.isAuthenticated()">
          <mat-icon>security</mat-icon>
          <span>Login</span>
        </button>
        <div *ngIf="authenticationService.isAuthenticated()">
            <button mat-menu-item (click)="goToAccueil()" >
              <mat-icon>home</mat-icon>
              <span>Accueil</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="gereractivite" >
              <mat-icon>create</mat-icon>
              <span>Gérer mon activité</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="organisations" *ngIf="authenticationService.hasOrganisation()">
              <mat-icon>people</mat-icon>
              <span>{{msg('organisations.title')}}</span>
            </button>

            <button mat-menu-item [matMenuTriggerFor]="administrer" *ngIf="authenticationService.isUserManager()">
              <mat-icon>people</mat-icon>
              <span>{{msg('administration.title')}}</span>
            </button>

            <button mat-menu-item (click)="navigateToAdministration()" *ngIf="authenticationService.isAdmin()">
              <mat-icon>warning</mat-icon>
              <span>Impersonnation</span>
            </button>


            <button mat-menu-item (click)="logoutProcess()" >
              <mat-icon>exit_to_app</mat-icon>
              <span>{{msg('lib.disconnect')}}</span>
            </button>
          </div>
        </mat-menu>

        <!--https://material.io/tools/icons/?style=baseline liste icon-->
        <mat-menu #gereractivite="matMenu">
          <button mat-menu-item (click)="saisieDesHeures()">
            <mat-icon>date_range</mat-icon>
            <span>{{msg('lib.saisieDesHeures')}}</span>
          </button>
          <button mat-menu-item (click)="transfertHeures()">
            <mat-icon>supervisor_account</mat-icon>
            <span>{{msg('menu.transfertTemps')}}</span>
          </button>
          <button mat-menu-item (click)="listeDesHeures()">
            <mat-icon>create</mat-icon>
            <span>{{msg('lib.listeDesHeures')}}</span>
          </button>
          <button mat-menu-item (click)="extractionContingent()">
            <mat-icon>list_alt</mat-icon>
            <span>{{msg('menu.rapportActivite')}}</span>
          </button>
        </mat-menu>


        <mat-menu #organisations="matMenu" >
            <button *ngIf="authenticationService.isRelationSociale()" mat-menu-item (click)="goToRelationsSociales()">
              <mat-icon>people</mat-icon>
              <span>{{msg('relationsSociales.title')}}</span>
            </button>
            <button *ngIf="authenticationService.isSecretaireCSE()" mat-menu-item (click)="goToCse()">
              <mat-icon>people</mat-icon>
              <span>{{msg('cse.title')}}</span>
            </button>
            <button *ngIf="authenticationService.isDelegueCentralOS()" mat-menu-item (click)="goToOS()">
              <mat-icon>people</mat-icon>
              <span>{{msg('OS.title')}}</span>
            </button>

        </mat-menu>

        <mat-menu #administrer="matMenu">
          <button *ngIf="authenticationService.isUserManager()" mat-menu-item (click)="goToUserManagement()"
                  style="white-space: normal" class="multiline" fxLayout="row">
            <mat-icon style="height: 100%">people</mat-icon>
            <span>{{msg('user-management.title')}}</span>
          </button>
        </mat-menu>

    </div>

    <div class="texte-titre" fxFlexOrder="2" fxFlex="3em" fxHide.lt-sm="true">
      <span>{{msg('lib.nomAppli')}}</span>
    </div>

    <div class="logo" fxFlexOrder="3" fxFlex="nogrow" fxHide.lt-sm="true">
      <img class="logo" src="assets/images/michelin-logo-blue-horizontal.svg" alt="logo-Michelin">
    </div>

    <div class="texte-nom" fxFlexOrder="4" fxFlex="nogrow" fxHide.lt-sm="true">
      <span *ngIf="tokenObject">Bonjour {{tokenObject?.firstName}} </span>
    </div>


    <div fxFlexOrder="5" fxFlex="nogrow">
      <button mat-button style="color:#27509b" (click)="openContactDialog()">
        <mat-icon>mail</mat-icon>
        Contact
      </button>
    </div>

    <div class="texte-nom" fxFlexOrder="6" fxFlex="none">
      <button mat-button *ngIf="authenticationService.isImpersonation()" style="color:#27509b"
              (click)="stopImpersonnation()">
        <mat-icon>exit_to_app</mat-icon>
        Arrêter l'impersonnation
      </button>
      <button mat-button *ngIf="authenticationService.isAuthenticated()" style="color:#27509b"
              (click)="logoutProcess()">
        <mat-icon>exit_to_app</mat-icon>
        {{msg('lib.disconnect')}}</button>
    </div>



  </div>
</mat-toolbar>

