import {Component, OnInit} from '@angular/core';
import {IMandat} from '../../entities/previsions/mandat.model';
import {ActivatedRoute} from '@angular/router';
import {MandatService} from '../../services/mandat.service';
import {INatureActivite} from '../../entities/nature-activite';
import {ICreditIndividuel} from '../../entities/creditIndividuel.model';
import {MatDialog, MatDialogConfig, MatTableDataSource} from '@angular/material';
import {ContingentEditComponent} from './contingent-edit/contingent-edit.component';
import {ToastrService} from 'ngx-toastr';
import {IContingentMax} from '../../entities/contingentMax.model';
import {ConfirmDialogComponent} from '../../layouts/confirm-dialog/confirm-dialog.component';
import {MillisToHoursMinutesPipe} from '../../pipes/hours-minutes.pipe';


@Component({
  selector: 'app-contingent-management',
  templateUrl: './contingent-management.component.html',
  styleUrls: ['./contingent-management.component.scss']
})
export class ContingentManagementComponent implements OnInit {

  mandat: IMandat;
  natureActivites: INatureActivite[];
  selectedActivite: INatureActivite;

  contingentsMax: IContingentMax[];
  selectedContingentMax: IContingentMax;

  selectableYears: number[];
  selectedYear: number;
  elevenMonthesAgo: Date;

  creditsDataSource = new MatTableDataSource<ICreditIndividuel>();


  private readonly millisToHoursMinutesPipe: MillisToHoursMinutesPipe = new MillisToHoursMinutesPipe();


  credits: ICreditIndividuel[];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly mandatService: MandatService,
    private readonly editDialog: MatDialog,
    private readonly toastrService: ToastrService
  ) {
  }

  ngOnInit() {

    const elevenMonthesAgo = new Date();
    elevenMonthesAgo.setMonth(elevenMonthesAgo.getMonth() - 11);
    elevenMonthesAgo.setDate(1);
    elevenMonthesAgo.setHours(0, 0, 0, 0);
    this.elevenMonthesAgo = elevenMonthesAgo;


    this.activatedRoute.data.subscribe(({mandat}) => {
      this.mandat = mandat;

      const minYear = new Date(this.mandat.begin).getFullYear();
      const maxYear = new Date(this.mandat.end).getFullYear();
      const currentYear = new Date().getFullYear();

      this.selectableYears = Array.from({length: maxYear - minYear + 1}, (_v, k) => minYear + k);
      this.selectedYear = (currentYear >= minYear && currentYear <= maxYear) ? currentYear : minYear;
    });


    this.mandatService.getNatureActivites(this.mandat).subscribe(natureActivites => {
      this.natureActivites = natureActivites;
      this.selectedActivite = this.natureActivites[0];
      this.selectedActiviteChanged();
    });
  }


  selectedActiviteChanged() {
    if (this.contingentsMax) {
      this.selectedContingentMax = this.contingentsMax.find(
        contingentMax => contingentMax.typeHeureId === this.selectedActivite.typeHeure.typeHeureId);
    } else {
      this.mandatService.getContingentMax(this.mandat).subscribe(contingentsMax => {
        this.contingentsMax = contingentsMax;
        this.selectedContingentMax = this.contingentsMax.find(
          contingentMax => contingentMax.typeHeureId === this.selectedActivite.typeHeure.typeHeureId);
      });
    }


    this.reloadCredits();
  }

  selectedYearChanged() {
    this.reloadCredits();
  }

  reloadCredits() {
    if (this.selectedActivite) {
      this.mandatService.getCredits(this.mandat, this.selectedActivite.typeHeure).subscribe(
        credits => {
          this.credits = credits;
          this.creditsDataSource = new MatTableDataSource<ICreditIndividuel>(
            this.credits.filter(credit => new Date(credit.effectDate).getFullYear() === this.selectedYear));
        }, error => {
          this.credits = [];
          this.creditsDataSource = new MatTableDataSource<ICreditIndividuel>(this.credits);
          this.toastrService.error(error.error.message);
        });
    } else {
      this.creditsDataSource = new MatTableDataSource<ICreditIndividuel>();
    }
  }

  compareNatureActivite(o1: INatureActivite, o2: INatureActivite) {
    if (!o1 || !o2) {
      return !o1 && !o2;
    }
    return o1 === o2 || (o1.typeHeure.typeHeureId === o2.typeHeure.typeHeureId);
  }

  modify(credit: ICreditIndividuel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {credit, contingentMax: this.selectedContingentMax};
    const dialogRef = this.editDialog.open(ContingentEditComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(
      () => this.reloadCredits());
  }


  initializeCredits() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Initialisatation des crédits',
      message: this.selectedContingentMax.quantiteMax ?
        // tslint:disable-next-line:max-line-length
        `Les crédits de la période vont être réinitialisés à la valeur par défaut (${this.millisToHoursMinutesPipe.transform(this.selectedContingentMax.quantiteMax)}).` :
        `Les crédits de la période vont être réinitialisés .`
    };
    const dialogRef = this.editDialog.open(ConfirmDialogComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(
      val => {
        if (val) {
          this.initializeCreditsAfterConfirm();
        }
      }
    );
  }


  private initializeCreditsAfterConfirm() {
    this.mandatService.initializeCredits(this.mandat, this.selectedActivite.typeHeure, this.selectedYear).subscribe(() => {
      this.toastrService.success('Initialisation OK');
      this.reloadCredits();
    }, error => this.toastrService.error(error.error.message));
  }

  public isCreditModifiable(credit: ICreditIndividuel): boolean {
    return (!this.selectedActivite.typeHeure.illimite) && new Date(credit.effectDate) >= this.elevenMonthesAgo;
  }
}
