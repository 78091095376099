import { Observable } from 'rxjs';
import { AppConfig } from '../initializer/AppConfig';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RoleService = /** @class */ (function () {
    function RoleService(http) {
        this.http = http;
    }
    RoleService.prototype.getAssignableRoles = function () {
        return this.http.get(AppConfig.settings.api.rolesUrl + "/assignableRoles");
    };
    RoleService.ngInjectableDef = i0.defineInjectable({ factory: function RoleService_Factory() { return new RoleService(i0.inject(i1.HttpClient)); }, token: RoleService, providedIn: "root" });
    return RoleService;
}());
export { RoleService };
