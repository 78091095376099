<div>
  <mat-form-field>
    <mat-label>Date</mat-label>
    <input matInput [matDatepicker]="beginPicker"
           [(ngModel)]="date" name="date" required>
    <mat-datepicker-toggle matSuffix [for]="beginPicker"></mat-datepicker-toggle>
    <mat-datepicker #beginPicker></mat-datepicker>
  </mat-form-field>
</div>
<div>
  Date {{date}} <br/> Date Json {{date | json}}
</div>

<div>
  <mat-form-field>
    <mat-label>Moment</mat-label>
    <input matInput [matDatepicker]="endPicker"
           [(ngModel)]="moment" name="moment" required>
    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
    <mat-datepicker #endPicker></mat-datepicker>
  </mat-form-field>
</div>
<div>
  Moment {{moment}} <br/>
  Moment Json {{moment | json}}
</div>
