import { AppConfig } from '../initializer/AppConfig';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PrevisionsService = /** @class */ (function () {
    function PrevisionsService(http) {
        this.http = http;
    }
    PrevisionsService.prototype.getCreditCollectifs = function () {
        return this.http.get(AppConfig.settings.api.previsionsUrl
            + '/credits');
    };
    PrevisionsService.prototype.getCreditCollectifDetails = function (id) {
        return this.http.get(AppConfig.settings.api.previsionsUrl
            + '/credit/' + id);
    };
    PrevisionsService.prototype.delete = function (previsionId) {
        return this.http.delete(AppConfig.settings.api.previsionsUrl
            + '/previsions/' + previsionId, { observe: 'response' });
    };
    PrevisionsService.prototype.update = function (prevision) {
        return this.http.put(AppConfig.settings.api.previsionsUrl
            + '/prevision/', prevision);
    };
    PrevisionsService.prototype.create = function (prevision) {
        return this.http.post(AppConfig.settings.api.previsionsUrl
            + '/previsions/', prevision);
    };
    PrevisionsService.prototype.createMultiple = function (previsions) {
        return this.http.put(AppConfig.settings.api.previsionsUrl
            + '/previsions/', previsions);
    };
    PrevisionsService.ngInjectableDef = i0.defineInjectable({ factory: function PrevisionsService_Factory() { return new PrevisionsService(i0.inject(i1.HttpClient)); }, token: PrevisionsService, providedIn: "root" });
    return PrevisionsService;
}());
export { PrevisionsService };
