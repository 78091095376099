<!--<app-header></app-header>-->
<div class="app-container">


  <form novalidate autocomplete="off" #loginForm="ngForm" (ngSubmit)="loginProcess(loginForm.value)">


    <mat-card class="app-login-block">
      <mat-card-content>
        <h2 class="example-h2" >{{msg('login.title')}}</h2>


        <mat-form-field class="app-item">
          <input type="text" matInput placeholder="{{msg('lib.login')}}" id="login" name="login"
                 [(ngModel)]="credentials.login" required>

        </mat-form-field>

        <mat-form-field class="app-item">
          <input type="password" matInput placeholder="{{msg('lib.password')}}" id="password" name="password"
                 [(ngModel)]="credentials.password" required
                 [type]="hide ? 'password' : 'text'">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>


        <button mat-button type="submit" class="app-item">{{msg('lib.connect')}}</button>


      </mat-card-content>
    </mat-card>


  </form>


</div>
<!--<app-footer></app-footer>-->
