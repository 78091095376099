import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IMandat} from '../../entities/previsions/mandat.model';
import {ICse} from '../../entities/previsions/cse.model';
import {CseService} from '../../services/cse.service';
import {IFonction} from '../../entities/previsions/fonction.model';
import {MandatService} from '../../services/mandat.service';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {MAT_DATE_LOCALE} from '@angular/material';
import {FonctionService} from '../../services/fonction.service';
import {MatSelectChange} from '@angular/material/select';


export const APP_DATE_FORMATS = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
  },
  display: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
    monthYearLabel: {year: 'numeric'},
  }
};

@Component({
  selector: 'app-mandat-details',
  templateUrl: './mandat-details.component.html',
  styleUrls: ['./mandat-details.component.scss'],
  providers: [{
    provide: MAT_DATE_LOCALE,
    useValue: 'fr'
  }]
})
export class MandatDetailsComponent implements OnInit {


  mandat: IMandat;
  selectableCse: ICse[];
  selectableFonctions: IFonction[];

  constructor(
    private readonly dialogRef: MatDialogRef<MandatDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { mandat: IMandat },
    private readonly cseService: CseService,
    private readonly fonctionService: FonctionService,
    private readonly mandatService: MandatService,
    private readonly toastr: ToastrService
  ) {
    this.mandat = data.mandat;
  }

  ngOnInit() {
    this.cseService.getAssignableCse(this.mandat.matricule).subscribe(cses => this.selectableCse = cses);
    if (this.mandat.cse) {
      this.fonctionService.getAssignableFonctions(this.mandat.cse.id).subscribe(fonctions => this.selectableFonctions = fonctions);
    }
  }

  save() {
    if (this.mandat.id) {
      this.update();
    } else {
      this.create();
    }
  }

  onCseChanged(event: MatSelectChange) {
    this.fonctionService.getAssignableFonctions(event.value.id).subscribe(fonctions => this.selectableFonctions = fonctions);
    if (!this.mandat.end) {
      this.mandat.end = this.mandat.cse.fin;
    }
    if (!this.mandat.begin) {
      this.mandat.begin = (this.mandat.cse.debut > new Date()) ? this.mandat.cse.debut : new Date();
    }
  }

  private update() {

    this.mandatService.update(this.mandat).subscribe(
      () => {
        this.toastr.success('Mandat mis à jour');
        this.dialogRef.close(this.mandat);
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);
      }
    )
    ;
  }

  private create() {

    this.mandatService.create(this.mandat).subscribe(
      () => {
        this.toastr.success('Mandat créé');
        this.dialogRef.close(this.mandat);
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

  compareCses(o1: ICse, o2: ICse) {
    if (!o1 || !o2) {
      return !o1 && !o2;
    }
    return o1 === o2 || o1.id === o2.id;
  }

  compareFonctions(o1: IFonction, o2: IFonction) {
    if (!o1 || !o2) {
      return !o1 && !o2;
    }
    return o1 === o2 || o1.id === o2.id;
  }

}
