
<form autocomplete="off" #editForm="ngForm">
  <div fxLayout="column"
       fxLayoutAlign="center"
       fxLayoutGap="5px">
    <mat-form-field>
      <mat-label>{{msg("user-details.matricule.label")}}</mat-label>
      <input matInput [(ngModel)]="user.matricule" name="matricule" required [disabled]="!isNew"
             pattern="[1279][0-9]{7}" #matricule=ngModel>
      <mat-error *ngIf="matricule.hasError('pattern') && !matricule.hasError('required')">
        {{msg('error.message.malformedMatricule')}}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{msg("user-details.michelinId.label")}}</mat-label>
      <input matInput [(ngModel)]="user.michelinId" name="michelinId" required (change)="lowerCaseMichelinId()"
             pattern="[a-zA-Z][a-zA-Z0-9][0-9]{5}" #michelinId=ngModel>
      <mat-error *ngIf="michelinId.hasError('pattern') && !michelinId.hasError('required')">
        {{msg('error.message.malformedMichelinId')}}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{msg("user-details.nom.label")}}</mat-label>
      <input matInput [(ngModel)]="user.nom" name="lastName" required [maxLength]="128">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{msg("user-details.prenom.label")}}</mat-label>
      <input matInput [(ngModel)]="user.prenom" name="firstName" required [maxLength]="40">
    </mat-form-field>

    <div class="col-lg-6">
      <div class="full-width">
        <mat-checkbox [(ngModel)]="user.active" name="active">{{msg("user-details.active.label")}}</mat-checkbox>
      </div>
    </div>

    <mat-form-field>
      <mat-label>{{msg("user-details.mail.label")}}</mat-label>
      <input matInput [(ngModel)]="user.mail" name="mail" required [email]="true" #mail=ngModel>
      <mat-error *ngIf="mail.hasError('email') && !mail.hasError('required')">
        {{msg('error.message.malformedEmail')}}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{msg("user-details.managerMail.label")}}</mat-label>
      <input matInput [(ngModel)]="user.managerMail" name="managerMail" [email]="true" required #managerMail=ngModel>
      <mat-error *ngIf="managerMail.hasError('email') && !managerMail.hasError('required')">
        {{msg('error.message.malformedEmail')}}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{msg("user-details.etablissment.label")}}</mat-label>
      <mat-select [(ngModel)]="user.etablissementId" name="etablissment" required>
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let etablissment of selectableEtablissments" [value]="etablissment.etablissementId">
          {{etablissment.code}} {{etablissment.libelle}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{msg("user-details.os.label")}}</mat-label>
      <mat-select [(ngModel)]="user.osId" name="os">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let os of selectableOss" [value]="os.osId">
          {{os.libelle}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{msg("user-details.roles.label")}}</mat-label>
      <mat-select [(ngModel)]="user.roles" name="roles" multiple [compareWith]="compareRoles">
        <mat-option *ngFor="let role of selectableRoles" [value]="role">
          {{role.libelle}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <button mat-raised-button type="submit" (click)="save()">Enregister</button>
      <button mat-raised-button type="button" (click)="cancel()">Annuler</button>
    </div>

  </div>
</form>

