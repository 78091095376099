import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IDebitExtractDTO} from '../entities/iDebitExtractDTO';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  /**
   * message entre composant type string
   */
  private messageSource = new BehaviorSubject<string>(null) ;

  /**
   * message entre composant type IDebitExtractDTO
   */
  private messageDebit = new BehaviorSubject<IDebitExtractDTO>(null);

  /**
   * message pour recharger une page
   */
  rechargePageListDebitMessage = this.messageSource.asObservable();

  /**
   * pour transferer objet debitExtract de la page listDebit vers SaisieHeures
   */
  transfertParamsDebitModif = this.messageDebit.asObservable();

  constructor() { }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  changeMessageDebitExtract(message: IDebitExtractDTO) {
    this.messageDebit.next(message);
  }

}

// https://angularfirebase.com/lessons/sharing-data-between-angular-components-four-methods/
