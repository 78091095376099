import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {AppConfig} from '../../initializer/AppConfig';
import {VersionCheckService, VersionKze} from '../../auto-reload/version-check.service';

@Component({
  templateUrl: './contact-dialog.html',
})
export class ContactDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<ContactDialogComponent>,
    private versionCheckService : VersionCheckService) {}


  close() {
    this.dialogRef.close();
  }

  msg(key) {
    return AppConfig.msg(key);
  }

  frontVersion(): string {
    const frontVersionKze: VersionKze = this.versionCheckService.frontVersionKze;
    return `front ${frontVersionKze.version} (${frontVersionKze.branch}, ${frontVersionKze.log})`;
  }
  backVersion(): string {
    const backVersionKze: VersionKze = this.versionCheckService.backVersionKze;
    if (backVersionKze)
      return `back ${backVersionKze.version} (${backVersionKze.branch}, ${backVersionKze.log})`;
    else
      return `back version inconnue pour le moment`
  }
}
