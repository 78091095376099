import { OnInit } from '@angular/core';
import { User } from '../../entities/user/user.model';
import { AppConfig } from '../../initializer/AppConfig';
import { ToastrService } from 'ngx-toastr';
import { EtablissmentService } from '../../services/etablissment.service';
import { OsService } from '../../services/os.service';
import { RoleService } from '../../services/role.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
var UserDetailsComponent = /** @class */ (function () {
    function UserDetailsComponent(activatedRoute, router, toastr, userService, etablissmentService, osService, roleService) {
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.toastr = toastr;
        this.userService = userService;
        this.etablissmentService = etablissmentService;
        this.osService = osService;
        this.roleService = roleService;
        this.user = new User();
    }
    UserDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.data.subscribe(function (_a) {
            var user = _a.user;
            _this.user = user;
            if (user.matricule === undefined) {
                _this.isNew = true;
                _this.user.active = true;
            }
        });
        this.etablissmentService.getAssignableEtablissments().subscribe(function (etablissments) {
            _this.selectableEtablissments = etablissments;
            if (!_this.user.etablissementId && _this.selectableEtablissments.length === 1) {
                _this.user.etablissementId = _this.selectableEtablissments[0].etablissementId;
            }
        });
        this.osService.getOss().subscribe(function (oss) { return _this.selectableOss = oss; });
        this.roleService.getAssignableRoles().subscribe(function (roles) { return _this.selectableRoles = roles; });
    };
    UserDetailsComponent.prototype.compareRoles = function (o1, o2) {
        if (!o1 || !o2) {
            return !o1 && !o2;
        }
        return o1 === o2 || o1.roleId === o2.roleId;
    };
    UserDetailsComponent.prototype.msg = function (key) {
        return AppConfig.msg(key);
    };
    UserDetailsComponent.prototype.cancel = function () {
        this.reload();
    };
    UserDetailsComponent.prototype.save = function () {
        if (this.isNew) {
            this.create();
        }
        else {
            this.update();
        }
    };
    UserDetailsComponent.prototype.create = function () {
        var _this = this;
        this.userService.create(this.user).subscribe(function () {
            _this.toastr.success('Utilisateur créé');
            _this.router.navigate(['users']);
        }, function (err) {
            _this.toastr.error(err.error.message);
        });
    };
    UserDetailsComponent.prototype.update = function () {
        var _this = this;
        this.userService.update(this.user).subscribe(function () {
            _this.toastr.success('Utilisateur mis à jour');
            _this.router.navigate(['users']);
        }, function (err) {
            _this.toastr.error(err.error.message);
        });
    };
    UserDetailsComponent.prototype.reload = function () {
        this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.activatedRoute });
    };
    UserDetailsComponent.prototype.lowerCaseMichelinId = function () {
        if (this.user) {
            this.user.michelinId = this.user.michelinId.toLowerCase();
        }
    };
    return UserDetailsComponent;
}());
export { UserDetailsComponent };
