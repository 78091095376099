import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfig} from '../initializer/AppConfig';
import {IFonction} from '../entities/previsions/fonction.model';

@Injectable({
  providedIn: 'root'
})
export class FonctionService {


  constructor(private readonly http: HttpClient) {
  }

  getAssignableFonctions(cseId: number): Observable<IFonction[]> {
    return this.http.get<IFonction[]>(`${AppConfig.settings.api.adminUrl}/fonctions/cse/${cseId}`);
  }
}
