import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConfig} from '../initializer/AppConfig';
import {HttpClient} from '@angular/common/http';
import {ICse} from '../entities/previsions/cse.model';

@Injectable({
  providedIn: 'root'
})
export class CseService {

  constructor(private readonly http: HttpClient) {
  }

  getAssignableCse(matricule: String): Observable<ICse[]> {
    return this.http.get<ICse[]>(`${AppConfig.settings.api.adminUrl}/assignableCses/${matricule}`);
  }
}


