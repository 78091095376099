<div class="container"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0">
  <div fxFlex="auto"></div>
  <div class="titrePage" fxFlex>{{pageTitle}}</div>
  <div fxFlex="auto"></div>
</div>

<br><br>

<div class="container"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0">
  <div class="item item-5" fxFlex="15em" fxFlex.lt-md="0em"></div>


  <div class="item item-4" fxFlex fxFlexOffset="50px" fxFlexOffset.xs="0">


    <mat-card *ngIf="authenticationService.hasAnyAuthority(['ROLE_EXTRACT_ETAB'])">
      <mat-card-header>{{msg('extractionContingentEtablissement.header')}}</mat-card-header>
      <mat-card-content>
        <mat-form-field style="width:50px;">
          <mat-label>{{msg('extractionContingentEtablissement.year')}}</mat-label>
          <input matInput name="year"
                 [(ngModel)]='selectedYearForAllRS'
                 class="number-right"
                 inputmode="numeric"
                 type="number"
                 [min]="minYear"
                 [max]="maxYear"
                 required/>
        </mat-form-field>
        <form [formGroup]='selectForm' style="display: inline-block; margin-left: 10px;">
          <mat-form-field style="min-width: 250px;">
            <input matInput
                   formControlName='userInput'
                   [matAutocomplete]="auto"
                   placeholder="Utilisateur ou Mandaté"
                   (change)="selectUser()" />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectUser()">
              <mat-option *ngFor="let user of filteredUsers" [value]="user">
                <span>{{user.prenom}} {{user.nom}} </span>
                <small> | ID: {{user.michelinId}}</small>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
        <div class="generate-button-panel">
          <button mat-raised-button style="display: inline;"
                  class="app-item"
                  (click)="extractionActivity()">
            {{msg('extractionContingentEtablissement.btn')}}
          </button>
        </div>
      </mat-card-content>
    </mat-card>


    <mat-card *ngIf="authenticationService.hasAnyAuthority(['ROLE_EXTRACT_FORM'])">
      <mat-card-header>{{msg('extractionMandatsFormations.header')}}</mat-card-header>
      <mat-card-content>
        <mat-form-field style="width:50px;">
          <mat-label>{{msg('extractionContingentEtablissement.year')}}</mat-label>
          <input matInput name="year"
                 [(ngModel)]='selectedYearForMandatsFormations'
                 class="number-right"
                 inputmode="numeric"
                 type="number"
                 [min]="minYear"
                 [max]="maxYear"
                 required/>
        </mat-form-field>
        <div class="generate-button-panel">
          <button mat-raised-button style="display: inline;"
                  class="app-item"
                  (click)="extractionMandatsFormations()">
            {{msg('extractionMandatsFormations.btn')}}
          </button>
        </div>
      </mat-card-content>
    </mat-card>


    <mat-card *ngIf="authenticationService.hasAnyAuthority(['ROLE_EXTRACT_TAUX'])">
      <mat-card-header>{{msg('extractionUsageCse.header')}}</mat-card-header>
      <mat-card-content>
        <div class="generate-button-panel">
          <button mat-raised-button
                  class="app-item"
                  [disabled]="false"
                  (click)="extractionUsage()">
            {{msg('extractionUsageCse.btn')}}
          </button>
        </div>
      </mat-card-content>
    </mat-card>


  </div>

  <button mat-raised-button style="display: inline;"
          *ngIf="authenticationService.hasAnyAuthority(['ROLE_TRANSFERTS_ALL'])"
          class="app-item"
          (click)="this.router.navigate(['relations-sociales/transfertRecurrent'])">
    Transferts récurrents
  </button>

  <div class="item item-5" fxFlex="auto"></div>
</div>




