/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./administration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./impersonnation/impersonnation.component.ngfactory";
import * as i3 from "./impersonnation/impersonnation.component";
import * as i4 from "@angular/forms";
import * as i5 from "../services/authentication.service";
import * as i6 from "../services/user.service";
import * as i7 from "./administration.component";
var styles_AdministrationComponent = [i0.styles];
var RenderType_AdministrationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdministrationComponent, data: {} });
export { RenderType_AdministrationComponent as RenderType_AdministrationComponent };
export function View_AdministrationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-impersonnation", [], null, null, null, i2.View_ImpersonnationComponent_0, i2.RenderType_ImpersonnationComponent)), i1.ɵdid(1, 114688, null, 0, i3.ImpersonnationComponent, [i4.FormBuilder, i5.AuthenticationService, i6.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AdministrationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-administration", [], null, null, null, View_AdministrationComponent_0, RenderType_AdministrationComponent)), i1.ɵdid(1, 114688, null, 0, i7.AdministrationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdministrationComponentNgFactory = i1.ɵccf("app-administration", i7.AdministrationComponent, View_AdministrationComponent_Host_0, {}, {}, []);
export { AdministrationComponentNgFactory as AdministrationComponentNgFactory };
