import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-donnees-person-dialog',
  templateUrl: './donnees-person-dialog.component.html',
  styleUrls: ['./donnees-person-dialog.component.scss'],
})
export class DonneesPersonDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DonneesPersonDialogComponent>) {}

  ngOnInit() {
    console.debug('DonneesPersonDialogComponent ngOnInit');
  }

  onNavigate() {
    window.open('https://www.cnil.fr/', '_blank');
  }

  envoieMailTo(adresse: String) {
    window.open('mailto:' + adresse);
  }

  close() {
    this.dialogRef.close();
  }
}
