import * as tslib_1 from "tslib";
var TransfertPonctuelCreation = /** @class */ (function () {
    function TransfertPonctuelCreation(sourceMandatCseId, targetMandatCseId, transferredAmountInMinutes, typeHeureId) {
        this.sourceMandatCseId = sourceMandatCseId;
        this.targetMandatCseId = targetMandatCseId;
        this.transferredAmountInMinutes = transferredAmountInMinutes;
        this.typeHeureId = typeHeureId;
    }
    return TransfertPonctuelCreation;
}());
export { TransfertPonctuelCreation };
var TransfertRecurrentCreation = /** @class */ (function (_super) {
    tslib_1.__extends(TransfertRecurrentCreation, _super);
    function TransfertRecurrentCreation(creditSourceId, sourceMandatCseId, targetMandatCseId, transferredAmountInMinutes, typeHeureId) {
        var _this = _super.call(this, sourceMandatCseId, targetMandatCseId, transferredAmountInMinutes, typeHeureId) || this;
        _this.creditSourceId = creditSourceId;
        return _this;
    }
    return TransfertRecurrentCreation;
}(TransfertPonctuelCreation));
export { TransfertRecurrentCreation };
