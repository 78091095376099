<h2 mat-dialog-title>{{data.fonction.libelle}}</h2>

<mat-dialog-content>




  <div class="form">
    <mat-form-field>
      <mat-select [(ngModel)]="selectedMandats" placeholder="Mandaté" multiple #selectMandates>
          <mat-option *ngFor="let mandat of data.fonction.activeMandats" [value]="mandat">
            {{mandat.mandate}}
          </mat-option>
      </mat-select>
    </mat-form-field>
  </div>


  <div class="form">
    <mat-form-field>
      <mat-select placeholder="Mois" [(ngModel)]="selectedDates" multiple #selectMonthes >
          <button mat-raised-button  (click)="selectAll()">
            Tous
          </button>
        <button mat-raised-button (click)="unselectAll()">
          Aucun
        </button>
        <mat-option *ngFor="let month of selectableDates" [value]="month">
          {{month|date:'LLLL'}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-form-field style="width:100px">
    <mat-label>{{msg('transfert.transfertHeures')}}</mat-label>
    <input matInput name="hoursToTranfert" #hoursInput
           [(ngModel)]='hours'
           class="number-right"
           inputmode="numeric"
           type="number"
           min="0"
           required/>
  </mat-form-field>

  <mat-form-field style="width:100px">
    <mat-label>{{msg('transfert.transfertMinutes')}}</mat-label>
    <input matInput name="minutesToTranfert" #minutesInput
           [(ngModel)]='minutes'
           class="number-right"
           type="number"
           inputmode="numeric"
           min="0"
           max="60"
           required/>
  </mat-form-field>


</mat-dialog-content>


<mat-dialog-actions>
  <button class="mat-raised-button" (click)="close()">Annuler</button>
  <button class="mat-raised-button mat-primary"
          [disabled]="buttonDisabled || selectMandates.empty || selectMonthes.empty || (hoursInput.value<=0 && minutesInput.value<=0)"
          (click)="saveMultiple()">Modifier
  </button>
</mat-dialog-actions>
