import {Component, OnInit} from '@angular/core';
import {ExtractionContingentService} from '../../services/extraction-contingent.service';
import {ToastrService} from 'ngx-toastr';
import {AppConfig} from '../../initializer/AppConfig';
import * as fileSaver from 'file-saver';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cse',
  templateUrl: './cse.component.html',
  styleUrls: ['./cse.component.scss']
})
export class CseComponent implements OnInit {

  fileSytemName = 'data';
  selectedYear: number;
  minYear: number;
  maxYear: number;

  /**
   * Titre injecté dans la page
   */
  pageTitle = this.msg('cse.title');

  /**
   * Accès aux textes/messages paramétrés
   * @param key
   */
  msg(key) {
    return AppConfig.msg(key);
  }

  constructor(private extractionContingent: ExtractionContingentService,
              public authenticationService: AuthenticationService,
              private toastr: ToastrService,
              public router: Router) {
  }

  ngOnInit(): void {
    this.extractionContingent.availableYears().subscribe(availableYears => {
        this.selectedYear = (new Date()).getFullYear();
        if (availableYears) {
          this.maxYear = availableYears[0];
          this.minYear = availableYears[availableYears.length - 1];
        } else {
          this.minYear = this.selectedYear;
          this.maxYear = this.selectedYear;
        }
      }
    );
  }


  extractionCSE() {
    this.extractionContingent.downloadExtractionCSE(this.selectedYear)
      .subscribe(response => {
          const contentDisposition = response.headers.get('content-disposition');
          console.debug(contentDisposition);
          const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          console.debug(filename);
          this.saveFile(response.body, filename);
        }, (err) => {
          console.debug(`Backend returned code ${err.status}, body was: ${err.error.message}`);
          const reader = new FileReader();
          reader.addEventListener('loadend', (e) => {
            const out = JSON.parse(e.srcElement['result']);
            console.error(out);
            this.toastr.error(this.msg(out.hint));
          });
          reader.readAsText(err.error);
        }
      );
  }


  private saveFile(data: any, filename?: string) {
    const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    fileSaver.saveAs(blob, filename);

  }


}
