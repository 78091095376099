import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IPrevision} from '../../../../entities/previsions/prevision.model';
import {AppConfig} from '../../../../initializer/AppConfig';
import {ICreditCollectif} from '../../../../entities/previsions/creditCollectif.model';
import {IMandat} from '../../../../entities/previsions/mandat.model';
import {IFonction} from '../../../../entities/previsions/fonction.model';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOption} from '@angular/material/core';
import {ToastrService} from 'ngx-toastr';
import {PrevisionsService} from '../../../../services/previsions.service';
import {MatSelect} from '@angular/material';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-prevision-create-dialog',
  templateUrl: './prevision-mass-edit-dialog.component.html',
  styleUrls: ['./prevision-mass-edit-dialog.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PrevisionMassEditDialogComponent implements OnInit {

  hours = 0;
  minutes = 0;

  selectedMandats: IMandat[];

  selectedDates: Date[];
  selectableDates: Date[];

  buttonDisabled = false;

  allSelected = false;
  @ViewChild('selectMonthes') monthSelector: MatSelect;

  constructor(public toastr: ToastrService, private gestionPrevisionsService: PrevisionsService,
              private dialogRef: MatDialogRef<PrevisionMassEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { creditCollectif: ICreditCollectif, fonction: IFonction }) {
  }

  msg(key) {
    return AppConfig.msg(key);
  }

  ngOnInit() {
    const previsions = ([] as IPrevision[])
      .concat(...this.data.fonction.activeMandats.map(mandat => mandat.previsions));
    this.selectableDates = Array.from(new Set(previsions.map(prevision => prevision.effectDate))).sort();
    console.debug(JSON.stringify(this.selectableDates));
  }

  selectAll() {
    this.monthSelector.options.forEach((item: MatOption) => item.select());
    this.monthSelector.close();
  }

  unselectAll() {
    this.monthSelector.options.forEach((item: MatOption) => item.deselect());
    this.monthSelector.close();
  }

  saveMultiple() {
    this.buttonDisabled = true;
    // const previsions: Prevision[] = [];
    const duration = (this.hours * 3600 + this.minutes * 60) * 1000;
    const previsions: IPrevision[] = ([] as IPrevision[])
      .concat(...this.selectedMandats.map(mandat => mandat.previsions))
      .filter(prevision => this.selectedDates.map(date => date.valueOf()).includes(prevision.effectDate.valueOf())
      );
    console.debug(JSON.stringify(this.selectedMandats));
    console.debug(JSON.stringify(this.selectedDates.map(date => date.valueOf())));
    previsions.forEach(prevision => prevision.duration = duration);
    console.debug(JSON.stringify(previsions));

    this.gestionPrevisionsService.createMultiple(previsions as IPrevision[]).subscribe(result => {
        this.toastr.success(result.length + ' prévision(s) modifiées');
        this.dialogRef.close(result);

      }, err => this.toastr.error(err),
      () => this.buttonDisabled = false);
  }

  close() {
    this.dialogRef.close();
  }
}
