import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {Icredentials} from '../entities/user/Icredentials';
import {AppConfig} from '../initializer/AppConfig';


/**
 * Composant de gestion du login
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService, vcr: ViewContainerRef) {
    authenticationService.setRootViewContainerRef(vcr);
  }

  public credentials: Icredentials = {};
  hide = true;

  /**
   * Accès aux textes/messages paramétrés
   * @param key
   */
  msg(key) {
    return AppConfig.msg(key);
  }

  ngOnInit() {
    AuthenticationService.removeToken();
  }

  /**
   * Gestion du login, récupéerr le token depuis le backend
   * @param formValues
   */
  loginProcess(formValues) {

    //console.debug(formValues);

    if (formValues.login === undefined || formValues.password === undefined) {
      console.debug('Not logging in because login and password are null');
    } else {
      // Could use this.credentials
      this.authenticationService.login(formValues.login.toLowerCase(), formValues.password, formValues.rememberMe);
    }


  }

}
