<div class="container"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0">
  <div fxFlex="auto"></div>
  <div class="titrePage" fxFlex>{{pageTitle}}</div>
  <div fxFlex="auto"></div>
</div>

<div class="container"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0">
  <div class="item item-5" fxFlex="15em" fxFlex.lt-md="0em"></div>

  <div class="item item-4" *ngIf="accessUserList()" fxFlex fxFlexOffset="50px" fxFlexOffset.xs="0">
    <form [formGroup]='usersForm'>
      <mat-form-field style="min-width: 250px;" *ngIf="accessUserList()">
        <input matInput
               formControlName='userInput'
               required
               [matAutocomplete]="auto"
               placeholder="Mandaté">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="mandateChanged()">
          <mat-option *ngFor="let user of filteredUsers" [value]="user">
            <span>{{user.prenom}} {{user.nom}} </span>
            <small> | ID: {{user.michelinId}}</small>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </div>
  <div class="item item-4" *ngIf="listDebit" fxFlex fxFlexOffset="50px" fxFlexOffset.xs="0">
    <mat-list role="list">
      <div *ngFor="let debit of listDebit">
        <app-detail-debit (rafraichirListeDebit)="rafraichirListeDebit()" [debit]="debit"></app-detail-debit>
      </div>
      </mat-list>
  </div>

  <div class="item item-4" *ngIf="listDebit.length==0" fxFlex fxFlexOffset="50px"  fxFlexOffset.xs="0">

    <mat-card class="card-liste-vide">
      <mat-card-content>
        <div mat-card-title>{{msg('listeHeures.vide')}}</div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="item item-5" fxFlex="auto"></div>
</div>


