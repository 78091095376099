<div fxLayout="column"
     fxLayoutAlign="start center"
     fxLayoutGap="10px">


  <div class="titrePage" fxFlex>Mes transferts</div>

  <button fxFlexOffset="2em" [routerLink]="['../transfertHeures']" mat-raised-button
          class="app-item">
    <mat-icon>supervisor_account</mat-icon>
    Transférer des heures
  </button>

  <mat-table [dataSource]="transfertsDataSource" style="min-width: 60em;" fxFlexOffset="1em">

    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef>
        Date
      </mat-header-cell>
      <mat-cell
        *matCellDef="let transfert"> {{transfert.dateDebit|date}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="activite">
      <mat-header-cell *matHeaderCellDef>
        Activité
      </mat-header-cell>
      <mat-cell *matCellDef="let transfert"> {{transfert.natureActiviteComplet}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="recipient">
      <mat-header-cell *matHeaderCellDef>
        Destinataire
      </mat-header-cell>
      <mat-cell *matCellDef="let transfert"> {{transfert.targetMandat.mandate}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef>
        Transféré
      </mat-header-cell>
      <mat-cell *matCellDef="let transfert"> {{transfert.duree|millisToHoursMinutes}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="used">
      <mat-header-cell *matHeaderCellDef>
        Consommé
      </mat-header-cell>
      <mat-cell *matCellDef="let transfert"> {{transfert.alreadyUsed|millisToHoursMinutes}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="remaining">
      <mat-header-cell *matHeaderCellDef>
        Restant
      </mat-header-cell>
      <mat-cell
        *matCellDef="let transfert"> {{transfert.duree - transfert.alreadyUsed|millisToHoursMinutes}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef>Action
      </mat-header-cell>
      <mat-cell *matCellDef="let transfert">
        <button mat-button (click)="modify(transfert)" class="actionButton"
                [disabled]="+transfert.alreadyUsed === +transfert.duree">
          <mat-icon matTooltip="Modifier" matTooltipPosition="right">edit</mat-icon>
        </button>
        <!--
                <button mat-button (click)="recoverUnused(transfert)" class="actionButton"
                        [disabled]="+transfert.alreadyUsed === +transfert.duree">
                  <mat-icon matTooltip="Restituer le non consommé" matTooltipPosition="right">restore</mat-icon>
                </button>
        -->
        <button mat-button (click)="delete(transfert)" class="actionButton" [disabled]="+transfert.alreadyUsed > 0">
          <mat-icon matTooltip="Supprimer" matTooltipPosition="right">delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>


    <mat-header-row
      *matHeaderRowDef="['date', 'recipient','activite','amount','used', 'remaining','action']; sticky: true"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: ['date', 'recipient','activite','amount','used', 'remaining','action'];"></mat-row>


  </mat-table>

</div>

