import {Component, forwardRef, Inject, Input, OnInit} from '@angular/core';
import {DetailDebitSupprDialogComponent} from './detail-debit-suppr-dialog/detail-debit-suppr-dialog.component';
import {MatDialog} from '@angular/material';
import {IDebitExtractDTO} from '../entities/iDebitExtractDTO';
import {GestionDesHeuresService} from '../services/gestion-des-heures.service';
import {ToastrService} from 'ngx-toastr';
import {ListeDesHeuresComponent} from '../liste-des-heures/liste-des-heures.component';
import {MessageService} from '../services/message.service';
import {AppConfig} from '../initializer/AppConfig';

@Component({
  selector: 'app-detail-debit',
  templateUrl: './detail-debit.component.html',
  styleUrls: ['./detail-debit.component.scss']
})
export class DetailDebitComponent implements OnInit {

  @Input() debit;

  debitWrapper = [];

  today: number = Date.now();

  messageVersListeDebit: string;

  messageModifDebit: IDebitExtractDTO;

  constructor(public dialog: MatDialog,
              public gestionDesHeuresService: GestionDesHeuresService,
              public toast: ToastrService,
              private message: MessageService,
              @Inject(forwardRef(() => ListeDesHeuresComponent)) private listeDesHeuresComponent: ListeDesHeuresComponent) {}



  /**
   * initialise messages vert ecran parent liste des heures
   */
  ngOnInit(): void {
    this.message.rechargePageListDebitMessage
      .subscribe(message => this.messageVersListeDebit = message);
    this.message.transfertParamsDebitModif
      .subscribe(message => this.messageModifDebit = message);

    this.debitWrapper.push(this.debit);
  }



  /**
   * Accès aux textes/messages paramétrés
   * @param key
   */
  msg(key) {
    return AppConfig.msg(key);
  }

  /**
   * trigger du click sur boutton valider suppression
   */
  suppression(): void {
    console.debug('suppression-detail: ' + this.debit.debitId);
    const dialogRef = this.dialog.open(DetailDebitSupprDialogComponent, {
        width: '250px',
        data: {debit: this.debit},
        disableClose: false,
        autoFocus: true,
        hasBackdrop: true,
        panelClass: 'z-index=1001',
      });
      dialogRef.afterClosed().subscribe(result => {
        console.debug('dialog fermé, result: ' + result);
        if (result !== undefined) {
          console.debug('suppr valide');
          this.supprimer(result);
          console.debug('suppr cest fait');
        } else {
          console.debug('suppr annule');
        }
      });
    }

  /**
   * envoyer message au composant parent ListeDesHeuresComponent
   * pour recharger la liste des debits
   */
  envoieMessageRechargePage() {
      this.message.changeMessage('recharge_page');
    }

  /**
   * suppression effective du debit
   */
  supprimer(debit: IDebitExtractDTO) {
    this.gestionDesHeuresService.supprimeUnDebit(this.debit.debitId).subscribe(
      success => {
        console.debug('result supprimeUnDebit SUCCE: ' + success);
        this.toast.success(this.msg('listeHeures.succ.supprime'), this.msg('lib.Success'));
        this.envoieMessageRechargePage();
        },
      error => {
        console.debug('result supprimeUnDebit ERROR : ' + error );
        this.toast.error(this.msg('listeHeures.err.supprime'), this.msg('lib.Error'));
      }
    );
  }

  modification() {
    console.debug('modification-detail: ' + this.debit.debitId);
    this.message.changeMessageDebitExtract(this.debit);
  }
}
