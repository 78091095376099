import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ConfirmDialogComponent } from '../layouts/confirm-dialog/confirm-dialog.component';
import { AppConfig } from '../initializer/AppConfig';
var VersionCheckService = /** @class */ (function () {
    function VersionCheckService(http, reloadDialog) {
        this.http = http;
        this.reloadDialog = reloadDialog;
        // On est déjà en train de poser la question (évite les milles-feuilles)
        this.alreadyAskingUser = false;
        this.frontVersionKze = VersionCheckService.getFrontVersionKze();
        this.backVersionKze = null;
    }
    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in seconds, defaults to 30 minutes
     */
    VersionCheckService.prototype.initVersionCheck = function (url, frequency) {
        var _this = this;
        if (frequency === void 0) { frequency = 60 * 30; }
        console.info('Version checking polling starting with refresh rate of', frequency, 'seconds.');
        this.checkVersion(url);
        setInterval(function () {
            _this.checkVersion(url);
        }, frequency * 1000);
    };
    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    VersionCheckService.prototype.checkVersion = function (url) {
        var _this = this;
        if (!this.alreadyAskingUser) {
            // timestamp these requests to invalidate caches
            this.http.get(url + '?t=' + new Date().getTime())
                .subscribe(function (response) {
                var newHash = response.hash;
                var lastVersion = VersionCheckService.getFrontVersionKze();
                // If no version returned (dev build?)
                if (!newHash) {
                    console.warn('Front version not returned correctly (dev build?), doing nothing');
                }
                else {
                    // If new version, do something
                    if (!lastVersion || newHash !== lastVersion.hash) {
                        console.info('Front version change detected, asking user to reload');
                        _this.confirmReload(response);
                    }
                    else {
                        console.info('Front version not changed, doing nothing');
                    }
                }
            }, function (err) {
                console.error(err, 'Could not get version');
            });
        }
    };
    /**
     * Confirmation de l'utilisateur pour recharger l'appli après avoir noté la nouvelle version
     * @param newVersionKze
     * @private
     */
    VersionCheckService.prototype.confirmReload = function (newVersionKze) {
        var _this = this;
        var dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: "Mise \u00E0 jour TOTEM " + newVersionKze.version,
            message: "Une nouvelle version de TOTEM est disponible !<br />Il est fortement conseill\u00E9 d'appliquer la mise \u00E0 jour.<br />Si vous \u00EAtes au milieu d'une saisie,<br />choisissez 'Plus tard', terminez puis faites Ctrl+F5",
            cancelButton: 'Plus tard',
            okButtonText: 'Mettre à jour maintenant'
        };
        this.alreadyAskingUser = true;
        var dialogRef = this.reloadDialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(function (reload) {
            _this.alreadyAskingUser = false;
            if (reload) {
                console.info('Front version change detected, user agreed to reload');
                localStorage.setItem('versionKzeFront', JSON.stringify(newVersionKze));
                _this.frontVersionKze = newVersionKze;
                location.reload();
            }
        });
    };
    /**
     * Récupération du numéro de version depuis le localStorage.
     * Retourne null si c'est pas du Json comifo
     * @private
     */
    VersionCheckService.getFrontVersionKze = function () {
        try {
            return JSON.parse(localStorage.getItem('versionKzeFront'));
        }
        catch (_a) {
            return null;
        }
    };
    /**
     * Récupération de la version du backend, à retenter tant qu'on prend une erreur
     * (ce qui est normal quand on n'est pas connecté)
     */
    VersionCheckService.prototype.loadBackVersionKze = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var retry, _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.info('Trying to get versionKzeBack');
                        localStorage.setItem('versionKzeBack', null);
                        retry = function (fn, ms) {
                            if (ms === void 0) { ms = 10000; }
                            return new Promise(function (resolve) {
                                fn()
                                    .then(resolve)
                                    .catch(function () {
                                    setTimeout(function () {
                                        console.warn('Retrying to get versionKzeBack...');
                                        retry(fn, ms).then(resolve);
                                    }, ms);
                                });
                            });
                        };
                        _a = this;
                        return [4 /*yield*/, retry(function () { return _this.http.get(AppConfig.settings.api.versionUrl + "/get").toPromise(); })];
                    case 1:
                        _a.backVersionKze = _b.sent();
                        localStorage.setItem('versionKzeBack', JSON.stringify(this.backVersionKze));
                        console.info("versionKzeBack retrieved : " + this.backVersionKze.version);
                        return [2 /*return*/];
                }
            });
        });
    };
    return VersionCheckService;
}());
export { VersionCheckService };
