import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authenticationService.isAuthenticated()) {

      console.debug(`Setting JWT Token in Header!!! ${AuthenticationService.getToken()}`);

      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${AuthenticationService.getToken()}`
        }
      });

    } else {
      console.debug('No JWT Token!!! Normal only for initial login.');
    }

    console.debug('CustomHttpInterceptor:' + request);

    // return next.handle(request);

    return next.handle(request)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {

            console.debug('All looks good');
            if (event.headers.get('X-Auth-Token')) {
              // console.log('refreshing token' + event.headers.msg('X-Auth-Token'));
              AuthenticationService.setToken(event.headers.get('X-Auth-Token'));
            } else {
              console.debug('X-Auth-Token not in response');
            }
            // http response status code
            console.debug(event.status);
          }
        }, error => {

          // http response status code
          console.error('Interceptor error response, status code:');
          console.error(error.status);
          console.error(error.message);

          if (error.error.code === 0) {
            console.error('Interceptor error, redirecting to login');
            this.authenticationService.logout();
          }
        })
      );

  }
}

