import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {AppConfig} from '../initializer/AppConfig';


@Injectable({
  providedIn: 'root'
})
export class ExtractionContingentService {

  constructor(private http: HttpClient) {
  }

  downloadExtraction(): Observable<HttpResponse<Blob>> {

    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    // });

    // return this.http.get(EXTRACT_URL + '/' + filename,{
    return this.http.get(AppConfig.settings.api.extractionUrl + '/excelExtract', {
      //  headers: headers,
      observe: 'response',
      responseType: 'blob'
    });

  }

  downloadExtractionOS(year: number): Observable<HttpResponse<Blob>> {

    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    // });

    // return this.http.get(EXTRACT_URL + '/' + filename,{
    return this.http.get(AppConfig.settings.api.extractionUrl + '/excelExtractOS?year=' + year, {
      //  headers: headers,
      observe: 'response',
      responseType: 'blob'
    });

  }


  downloadExtractionCSE(year: number): Observable<HttpResponse<Blob>> {

    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    // });

    // return this.http.get(EXTRACT_URL + '/' + filename,{
    return this.http.get(AppConfig.settings.api.extractionUrl + '/excelExtractCSE?year=' + year, {
      //  headers: headers,
      observe: 'response',
      responseType: 'blob'
    });

  }



  downloadExtractionEtablissement(year: number): Observable<HttpResponse<Blob>> {
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    // });

    // return this.http.get(EXTRACT_URL + '/' + filename,{
    return this.http.get(AppConfig.settings.api.extractionUrl + '/excelExtractEtablissement?year=' + year, {
      //  headers: headers,
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadExtractionUser(year: number, user: string): Observable<HttpResponse<Blob>> {
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    // });

    // return this.http.get(EXTRACT_URL + '/' + filename,{
    return this.http.get(AppConfig.settings.api.extractionUrl + '/excelExtract?year=' + year + '&michelinId=' + user, {
      //  headers: headers,
      observe: 'response',
      responseType: 'blob'
    });
  }


  downloadExtractionMandtatsFormations(year: number): Observable<HttpResponse<Blob>> {
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    // });

    // return this.http.get(EXTRACT_URL + '/' + filename,{
    return this.http.get(AppConfig.settings.api.extractionUrl + '/excelExtractMandatsFormations?year=' + year, {
      //  headers: headers,
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadExtractionUsage(): Observable<HttpResponse<Blob>> {
    // const header = new HttpHeaders({
    //   'Content-Type': 'application/vnd.openxmlfomats-officedocument.spreadsheetml.sheet'
    // });

    return this.http.get(AppConfig.settings.api.extractionUrl + '/excelExtractTauxAppli', {
      observe: 'response',
      responseType: 'blob'
    });
  }

  availableYears(): Observable<number[]> {
    return this.http.get<number[]>(AppConfig.settings.api.extractionUrl + '/availableYears', );
  }

}
