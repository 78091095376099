// Template component
// Use block-ui-template class to center div if desired
import {Component} from '@angular/core';

@Component({
  template: `
    <div id="spinner" class="spinnerDiv">
      <div class="loaderSpin">
        <div class="innerSpin one"></div>
        <div class="innerSpin two"></div>
        <div class="innerSpin three"></div>
      </div>
    </div>
  `
})
export class SpinnerComponent {}
