import {Injectable} from '@angular/core';
import {Icredentials} from '../entities/user/Icredentials';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

import {JwtHelperService} from '@auth0/angular-jwt';
import {Itoken} from '../entities/user/Itoken';
import {Idecodedtoken} from '../entities/user/Idecodedtoken';
import {AppConfig} from '../initializer/AppConfig';
import {Observable} from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private static readonly tokenItemName = 'token';
  private static readonly tokenSaveItemName = 'token_save';

  private static readonly jwtHelperService: JwtHelperService = new JwtHelperService();

  private loginError = false;

  private rootViewContainer: any;

  constructor(private readonly http: HttpClient, private readonly router: Router, public readonly toastr: ToastrService) {
  }

  static setToken(token) {
    localStorage.setItem(AuthenticationService.tokenItemName, token);
  }

  static getToken() {
    return localStorage.getItem(AuthenticationService.tokenItemName);
  }

  static getTokenObject(): any {
    return AuthenticationService.jwtHelperService.decodeToken(AuthenticationService.getToken());
  }

  static removeToken() {
    localStorage.removeItem(AuthenticationService.tokenItemName);
  }

  static tokenObject(): Idecodedtoken {
    return AuthenticationService.getTokenObject();
  }

  static getRoles() {
    return AuthenticationService.getTokenObject().auth.split(',');
  }


  static setTokenSave(tokenSave) {
    localStorage.setItem(AuthenticationService.tokenSaveItemName, tokenSave);
  }

  static getTokenSave() {
    return localStorage.getItem(AuthenticationService.tokenSaveItemName);
  }

  static removeTokenSave() {
    localStorage.removeItem(AuthenticationService.tokenSaveItemName);
  }

  static isImpersonation() {
    return this.getTokenSave() != null;
  }


  static isActuator(): boolean {
    return AuthenticationService.getRoles().includes('ROLE_ACTUATOR');
  }

  isAdmin(): boolean {
    return AuthenticationService.getRoles().includes('ROLE_ADMIN');
  }

  hasAnyAuthority(authorities: string[]): boolean {
    if (!this.isAuthenticated() || !AuthenticationService.getToken() || !AuthenticationService.getRoles()) {
      return false;
    }
    for (const authority of authorities) {
      if (AuthenticationService.getRoles().includes(authority)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Accès aux textes/messages paramétrés
   * @param key
   */
  msg(key) {
    return AppConfig.msg(key);
  }

  /**
   * //Can't inject viewContainer into Authentication service so have to set it from Component Class
   * @param viewContainerRef
   */
  public setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  login(login: string, password: string, rememberMe: boolean) {

    const credentials: Icredentials = {};
    credentials.login = login;
    credentials.password = password;
    credentials.rememberMe = rememberMe;

    this.http.post(AppConfig.settings.api.loginUrl.toString(), credentials, httpOptions).subscribe((data: Itoken) => {

        this.loginError = false;

        if (data) {
          // Store Current User in localStorage to workaround F5 issue
          AuthenticationService.setToken(data.token);
          this.router.navigate(['/home']);
        }
      },
      (err: HttpErrorResponse) => {

        AuthenticationService.removeToken();
        this.loginError = true;

        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', err.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
        }
        this.toastr.error(this.msg('login.error'), this.msg('lib.Error'));

      });

  }

  public validateToken(): Observable<void> {
    return this.http
      .get<void>(AppConfig.settings.api.validateTokenUrl.toString());
  }


  isAuthenticated() {
    return !!AuthenticationService.getToken();
  }

  /**
   * Cleanup localStorage and redirect to login page
   */
  logout() {
    AuthenticationService.removeToken();
    AuthenticationService.removeTokenSave();
    this.router.navigate(['/login']);
  }


  public hasOrganisation(): boolean {
    return this.isRelationSociale() || this.isSecretaireCSE() || this.isDelegueCentralOS();
  }

  public isSecretaireCSE(): boolean {
    return this.hasAnyAuthority(['ROLE_Secrétaire', 'ROLE_SecrétaireCfe', 'ROLE_SecrétaireCsec', 'ROLE_ADMINCSE']);
  }

  public isDelegueCentralOS(): boolean {
    return this.hasAnyAuthority(['ROLE_Dsc']);
  }

  public isRelationSociale(): boolean {
    return this.hasAnyAuthority(['ROLE_RS_SITE', 'ROLE_RS_CENTRAL']);
  }

  public isUserManager(): boolean {
    return this.hasAnyAuthority(['ROLE_ADM_USER_LST']);
  }

  doImpersonation(token) {
    AuthenticationService.setTokenSave(AuthenticationService.getToken());
    AuthenticationService.setToken(token);
    this.toastr.info(
      `You are now impersonating ${AuthenticationService.getTokenObject().firstName} `
      + `${AuthenticationService.getTokenObject().lastName}. Please wait...`
    );
  }

  isImpersonation(): boolean {
    return !!AuthenticationService.getTokenSave();
  }

  stopImpersonation() {
    AuthenticationService.setToken(AuthenticationService.getTokenSave());
    AuthenticationService.removeTokenSave();
    this.toastr.info(
      `Welcome back ${AuthenticationService.getTokenObject().firstName} ${AuthenticationService.getTokenObject().lastName} ! Please wait...`
    );
    setTimeout(() => location.reload(), 2000);
  }


  /**
   * Impersonate a user
   *
   * @param username The username of the user that we want to impersonate
   */
  getImpersonateToken(username: string): Observable<Itoken> {
    const url = `${AppConfig.settings.api.usersUrl}/${username}/impersonate`;
    return this.http.post<Itoken>(url, null);
  }


}
