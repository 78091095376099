import {Component, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {PrevisionsService} from '../../../services/previsions.service';
import {ICreditCollectif} from '../../../entities/previsions/creditCollectif.model';
import {AppConfig} from '../../../initializer/AppConfig';
import {HttpErrorResponse} from '@angular/common/http';
import {MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTable, MatTableDataSource, Sort} from '@angular/material';
import {IPrevision} from '../../../entities/previsions/prevision.model';
import {PrevisionEditDialogComponent} from './prevision-edit-dialog/prevision-edit-dialog.component';
import {IFonction} from '../../../entities/previsions/fonction.model';
import {PrevisionMassEditDialogComponent} from './prevision-mass-edit-dialog/prevision-mass-edit-dialog.component';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-gestion-previsions',
  templateUrl: './previsions.component.html',
  styleUrls: ['./previsions.component.scss']
})
export class PrevisionsComponent implements OnInit {

  pageTitle = 'Gestion des prévisions';

  selectableCredits?: ICreditCollectif[];
  selectedCredit?: ICreditCollectif;
  dataSource = new MatTableDataSource<IPrevision>();
  filteredTotal: number;
  filteredUsedTotal: number;
  buttonDisabled = false;


  @ViewChild(MatTable) datatable: MatTable<IPrevision>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  mandateFilter = new FormControl();

  filteredValues = {fonction: '', mandate: ''};
  commission: IFonction;
  selectedCreditId: number;


  constructor(public toastr: ToastrService, private gestionPrevisionsService: PrevisionsService, private dialog: MatDialog) {
  }

  ngOnInit() {

    this.gestionPrevisionsService.getCreditCollectifs().subscribe(
      creditCollectifs => this.selectableCredits = creditCollectifs,
      err => this.toastr.error(err.error.message)
    );
    this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;


    this.mandateFilter.valueChanges.subscribe((mandateFilterValue) => {
      this.filteredValues['mandate'] = mandateFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.connect().subscribe(
      () => {
        this.filteredTotal = this.dataSource.filteredData.reduce((sum, prevision) => sum += +prevision.duration, 0);
        this.filteredUsedTotal = this.dataSource.filteredData.reduce((sum, prevision) => sum += +prevision.durationUsed, 0);
      }
    );
  }


  customFilterPredicate() {
    const myFilterPredicate = function (data: IPrevision, filter: string): boolean {
      const searchString = JSON.parse(filter);
      const mandateFound = data.mandate.trim().toLowerCase().indexOf(searchString.mandate.toLowerCase()) !== -1;

      return mandateFound;

    };
    return myFilterPredicate;
  }

  applyFilter(filterValue: string) {
    const filter = {
      name: filterValue.trim().toLowerCase(),
      position: filterValue.trim().toLowerCase()
    };
    this.dataSource.filter = JSON.stringify(filter);
  }


  msg(key) {
    return AppConfig.msg(key);
  }

  loadCreditCollectif() {
    this.gestionPrevisionsService.getCreditCollectifDetails(this.selectedCreditId).subscribe(creditCollectif => {
        this.selectedCredit = creditCollectif;
        this.commission = this.selectedCredit.eligibleFonctions.find(fct => this.commission && fct.id === this.commission.id);
        this.dataSource.data = (this.selectedCredit && this.commission)
          ? ([] as IPrevision[]).concat(...this.commission.activeMandats.map(mandat => mandat.previsions)) :
          [];
        this.datatable.renderRows();
        const sortState: Sort = {active: 'effectDate', direction: 'asc'};
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message, this.msg('lib.Error'));
      });
  }

  creditSelectedChanged() {
    this.loadCreditCollectif();
  }

  modify(prevision: IPrevision) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {prevision: prevision, creditCollectif: this.selectedCredit};
    const dialogRef = this.dialog.open(PrevisionEditDialogComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(
      val => {
        if (val) {
          this.update(val);
        }
      }
    );

  }

  createMultiple() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {creditCollectif: this.selectedCredit, fonction: this.commission};
    const dialogRef = this.dialog.open(PrevisionMassEditDialogComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(
      val => {
        if (val) {
          this.loadCreditCollectif();
        }
      }
    );
  }


  update(prevision: IPrevision) {
    this.gestionPrevisionsService.update(prevision).subscribe(() => {
      this.toastr.success('Prévision modifiée');
      this.loadCreditCollectif();
    }, err => this.toastr.error(err.error.message, this.msg('lib.Error')));

  }

  delete(prevision: IPrevision) {
    this.buttonDisabled = true;
    this.gestionPrevisionsService.delete(prevision.id).subscribe(() => {
        this.toastr.success('Suppression effectée');
        this.loadCreditCollectif();
      },
      err => this.toastr.error(err.error.message, this.msg('lib.Error')),
      () => this.buttonDisabled = false);

  }

  commissionChanged() {
    this.loadCreditCollectif();

  }

  compareFonctions(o1: IFonction, o2: IFonction) {
    if (!o1 || !o2) {
      return !o1 && !o2;
    }
    return (o1 === o2 || o1.id === o2.id);
  }
}
