<h2 mat-dialog-title>Données Personnelles</h2>

<mat-dialog-content>

    <p>
      Le service des relations sociales de la Manufacture Française des Pneumatiques Michelin, en qualité de responsable de traitement, met en œuvre des traitements de données à caractère personnel ayant pour finalité la gestion des temps des élus et des mandatés.
      Ces traitements sont basés sur le respect de la règlementation en vigueur.<br>
      Les données traitées sont indispensables à ces traitements et sont utilisées par les services concernés de Michelin et le cas échéant, ses sous-traitants. Elles peuvent dans certains cas être transférées hors de l’Union Européenne, notamment en Inde où l’un de ses sous-traitants est basé.
    </p>
    <p>
      Afin d'offrir des garanties adéquates concernant la protection de ces données, une convention de flux transfrontière reprenant les clauses types de la Commission européenne a été signée entre la MFPM et ses sous-traitants, disponible sur simple demande.
    </p>
    <p>
      Des transferts à l’intérieur du Groupe Michelin, en Chine, peuvent également avoir lieu et se trouvent encadrés par les Règles Contraignantes d’Entreprise du Groupe qui ont été validées par la CNIL (disponibles sur michelin.com).
      Votre manager a également accès aux données déclarées. La durée de conservation des données correspond à deux mandats.
    </p>
    <p>
      Conformément à la Loi Informatique et Libertés du 6 janvier 1978 modifiée et au Règlement Général sur la Protection des Données, vous pouvez accéder et obtenir copie des données vous concernant, vous opposer au traitement de ces données pour des raisons légitimes, les faire rectifier ou effacer.<br>
      Vous disposez également d'un droit à la limitation du traitement de vos données, d’un droit à la portabilité de vos données dans les cas définis par la règlementation applicable et vous pouvez définir le sort de vos données après votre décès.<br>
      Pour exercer l’un de ces droits veuillez-vous adresser au Centre d’Excellence et de Services Partagés - Service au Personnel aux coordonnées ci-après :
      <button class="adresse" mat-button tabindex="-1" (click)="envoieMailTo('compte-fonction.cds-au-personnel@michelin.com')">compte-fonction.cds-au-personnel@michelin.com</button><br>
      Vous avez la possibilité d’écrire un courriel au service en charge de la protection des données personnelles à :
      <button class="adresse" mat-button tabindex="-1" (click)="envoieMailTo('privacy.fr@michelin.com')">privacy.fr@michelin.com</button><br>
      Dans le cas où votre demande serait insatisfaite, vous avez également la possibilité de former une réclamation auprès de la CNIL sur son site internet.
      <button class="adresse" mat-button tabindex="-1" (click)="onNavigate()">www.cnil.fr</button>
    </p>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>Fermer</button>
</mat-dialog-actions>


