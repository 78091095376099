import { AppConfig } from '../initializer/AppConfig';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OsService = /** @class */ (function () {
    function OsService(http) {
        this.http = http;
    }
    OsService.prototype.getOss = function () {
        return this.http.get(AppConfig.settings.api.adminUrl + "/oss/");
    };
    OsService.ngInjectableDef = i0.defineInjectable({ factory: function OsService_Factory() { return new OsService(i0.inject(i1.HttpClient)); }, token: OsService, providedIn: "root" });
    return OsService;
}());
export { OsService };
