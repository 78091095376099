import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ICreditIndividuel} from '../../../entities/creditIndividuel.model';
import {AppConfig} from '../../../initializer/AppConfig';
import {MandatService} from '../../../services/mandat.service';
import {ToastrService} from 'ngx-toastr';
import {IContingentMax} from '../../../entities/contingentMax.model';

@Component({
  selector: 'app-contingent-edit',
  templateUrl: './contingent-edit.component.html',
  styleUrls: ['./contingent-edit.component.scss']
})
export class ContingentEditComponent implements OnInit {
  credit: ICreditIndividuel;
  contingentMax: IContingentMax;

  hours?: number;
  minutes?: number;

  constructor(private readonly dialogRef: MatDialogRef<ContingentEditComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { credit: ICreditIndividuel, contingentMax: IContingentMax },
              private readonly mandatService: MandatService,
              private readonly toastrService: ToastrService) {
    this.credit = data.credit;
    this.contingentMax = data.contingentMax;
    this.hours = Math.trunc(this.credit.allocated / 3600000);
    this.minutes = (this.credit.allocated - this.hours * 3600000) / 60000;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.credit.allocated = this.durationInMillis();
    this.mandatService.updateCredit(this.credit).subscribe(response => {
      this.toastrService.success('Modification effectuée');
      this.dialogRef.close();
    }, error => this.toastrService.error(error.error.message));
  }

  msg(key) {
    return AppConfig.msg(key);
  }

  durationInMillis(): number {
    return this.hours * 3600000 + this.minutes * 60000;
  }

  isValid(): boolean {
    const durationInMillis = this.durationInMillis();
    return durationInMillis != null && durationInMillis >= this.credit.alreadyUsed
      && (this.contingentMax == null || durationInMillis <= this.contingentMax.quantiteMax);
  }

}
