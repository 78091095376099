<div>
    <div class="row">
        <div class="col-md-4">
            <span class="hipster img-fluid rounded"></span>
        </div>
        <div class="col-md-8">
            <h1 >Page d'erreur !</h1>

            <div [hidden]="!errorMessage">
                <div class="alert alert-danger">{{errorMessage}}
                </div>
            </div>
            <div [hidden]="!error403" class="alert alert-danger" >Vous n'avez pas l'autorisation de voir cette page.
            </div>
            <div [hidden]="!error404" class="alert alert-danger" >Cette page n'existe pas.
            </div>
        </div>
    </div>
</div>
