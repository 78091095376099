import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppConfig } from '../../../../initializer/AppConfig';
import { MatOption } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import { PrevisionsService } from '../../../../services/previsions.service';
import { MatSelect } from '@angular/material';
export var MY_FORMATS = {
    parse: {
        dateInput: 'MMM YYYY',
    },
    display: {
        dateInput: 'MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
var PrevisionMassEditDialogComponent = /** @class */ (function () {
    function PrevisionMassEditDialogComponent(toastr, gestionPrevisionsService, dialogRef, data) {
        this.toastr = toastr;
        this.gestionPrevisionsService = gestionPrevisionsService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.hours = 0;
        this.minutes = 0;
        this.buttonDisabled = false;
        this.allSelected = false;
    }
    PrevisionMassEditDialogComponent.prototype.msg = function (key) {
        return AppConfig.msg(key);
    };
    PrevisionMassEditDialogComponent.prototype.ngOnInit = function () {
        var _a;
        var previsions = (_a = []).concat.apply(_a, this.data.fonction.activeMandats.map(function (mandat) { return mandat.previsions; }));
        this.selectableDates = Array.from(new Set(previsions.map(function (prevision) { return prevision.effectDate; }))).sort();
        console.debug(JSON.stringify(this.selectableDates));
    };
    PrevisionMassEditDialogComponent.prototype.selectAll = function () {
        this.monthSelector.options.forEach(function (item) { return item.select(); });
        this.monthSelector.close();
    };
    PrevisionMassEditDialogComponent.prototype.unselectAll = function () {
        this.monthSelector.options.forEach(function (item) { return item.deselect(); });
        this.monthSelector.close();
    };
    PrevisionMassEditDialogComponent.prototype.saveMultiple = function () {
        var _this = this;
        var _a;
        this.buttonDisabled = true;
        // const previsions: Prevision[] = [];
        var duration = (this.hours * 3600 + this.minutes * 60) * 1000;
        var previsions = (_a = []).concat.apply(_a, this.selectedMandats.map(function (mandat) { return mandat.previsions; })).filter(function (prevision) { return _this.selectedDates.map(function (date) { return date.valueOf(); }).includes(prevision.effectDate.valueOf()); });
        console.debug(JSON.stringify(this.selectedMandats));
        console.debug(JSON.stringify(this.selectedDates.map(function (date) { return date.valueOf(); })));
        previsions.forEach(function (prevision) { return prevision.duration = duration; });
        console.debug(JSON.stringify(previsions));
        this.gestionPrevisionsService.createMultiple(previsions).subscribe(function (result) {
            _this.toastr.success(result.length + ' prévision(s) modifiées');
            _this.dialogRef.close(result);
        }, function (err) { return _this.toastr.error(err); }, function () { return _this.buttonDisabled = false; });
    };
    PrevisionMassEditDialogComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    return PrevisionMassEditDialogComponent;
}());
export { PrevisionMassEditDialogComponent };
