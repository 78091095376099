import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IPrevision} from '../../../../entities/previsions/prevision.model';
import {AppConfig} from '../../../../initializer/AppConfig';
import {ICreditCollectif} from '../../../../entities/previsions/creditCollectif.model';
import {IMandat} from '../../../../entities/previsions/mandat.model';
import {IFonction} from '../../../../entities/previsions/fonction.model';
import * as moment from 'moment';

@Component({
  selector: 'app-prevision-edit' +
    '-dialog',
  templateUrl: './prevision-edit-dialog.component.html',
  styleUrls: ['./prevision-edit-dialog.component.scss']
})
export class PrevisionEditDialogComponent implements OnInit {

  hours?: number;
  minutes?: number;
  selectedMandat: IMandat;
  selectedFonction: IFonction;

  constructor(
    private dialogRef: MatDialogRef<PrevisionEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { prevision: IPrevision, creditCollectif: ICreditCollectif }) {
    var duree = moment.duration(this.data.prevision.duration);
    this.hours = Math.trunc(duree.asHours());
    this.minutes = duree.minutes();
  }

  msg(key) {
    return AppConfig.msg(key);
  }

  ngOnInit() {

  }


  save() {
    this.data.prevision.duration = (this.hours * 3600 + this.minutes * 60) * 1000;
    this.dialogRef.close(this.data.prevision);
  }

  close() {
    this.dialogRef.close();
  }
}
