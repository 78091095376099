import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../initializer/AppConfig';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FonctionService = /** @class */ (function () {
    function FonctionService(http) {
        this.http = http;
    }
    FonctionService.prototype.getAssignableFonctions = function (cseId) {
        return this.http.get(AppConfig.settings.api.adminUrl + "/fonctions/cse/" + cseId);
    };
    FonctionService.ngInjectableDef = i0.defineInjectable({ factory: function FonctionService_Factory() { return new FonctionService(i0.inject(i1.HttpClient)); }, token: FonctionService, providedIn: "root" });
    return FonctionService;
}());
export { FonctionService };
