<h2 mat-dialog-title>Contact</h2>

<mat-dialog-content>

  <ul matlist>

      <li>{{msg('contact.niveau1')}}</li>

      <li [innerHTML]="msg('contact.niveau2')"> </li>

      <li [innerHTML]="msg('contact.niveau3')"> </li>

  </ul>
  <div class="versionInfo">Infos à communiquer au support, si besoin
    <span class="versionInfo component" [innerHTML]="frontVersion()"></span>
    <span class="versionInfo component" [innerHTML]="backVersion()"></span>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Fermer</button>
</mat-dialog-actions>
