import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {IActuatorHealth} from './IActuatorHealth';
import {IActuatorInfo} from './IActuatorInfo';
import {AppConfig} from '../../initializer/AppConfig';

@Injectable({
  providedIn: 'root'
})
export class ActuatorService {

  constructor(private http: HttpClient) {

  }


// ***** SPECIFIC *******

  // API: GET /management/health
  public getActuatorHealth(): Observable<IActuatorHealth> {
    return this.http
      .get<IActuatorHealth>(AppConfig.settings.api.actuatorUrl + '/health');
  }

  // API: GET /management/info
  public getActuatorInfo(): Observable<IActuatorInfo> {
    return this.http
      .get<IActuatorInfo>(AppConfig.settings.api.actuatorUrl + '/info');
  }

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return observableThrowError(error);
  }

}
