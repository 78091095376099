import {Component, OnInit} from '@angular/core';
import {Moment} from 'moment';

@Component({
  selector: 'app-test-timezone',
  templateUrl: './test-timezone.component.html',
  styleUrls: ['./test-timezone.component.scss']
})
export class TestTimezoneComponent implements OnInit {

  date: Date;
  moment: Moment;

  constructor() {
  }

  ngOnInit() {
  }

}
