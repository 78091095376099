<div class="container"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0">
  <div fxFlex="auto"></div>
  <div class="titrePage" fxFlex>{{pageTitle}}</div>
  <div fxFlex="auto"></div>
</div>

<div class="container"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="10px">
  <div class="item item-5" fxFlex="15em" fxFlex.lt-md="0em"></div>
  <div class="item item-4" fxFlex fxFlexOffset="50px" fxFlexOffset.xs="0">

    <form novalidate autocomplete="off" #saisieForm="ngForm" (ngSubmit)="submitTransfert()">


      <button [routerLink]="['../transfertManagement']" mat-raised-button
              class="app-item" style="margin: 20px">
        <mat-icon>list_alt</mat-icon>
        Gérer les transferts existants
      </button>

      <mat-form-field>
        <mat-label>{{msg('transfert.choixMandat')}}</mat-label>
        <mat-select name="mandatSelect"
                    [(ngModel)]='selectedActivity'
                    (selectionChange)="mandatChanged($event.value)"
                    required>
          <mat-option *ngFor="let mandat of availableMandats"
                      [value]="mandat">
            {{mandat.libelleSaisie}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
      <em style="color: darkslategray">{{msg('transfert.disponibleTransfert.description')}}</em>
      <br/>
      <mat-form-field>
        <mat-label>{{msg('transfert.choixDestinataire')}}</mat-label>
        <mat-select name="mandatSelect"
                    [(ngModel)]='transfertRecipient'
                    (selectionChange)="transfertRecipientChanged($event.value)"
                    required>
          <mat-option *ngFor="let recipient of availableTransfertRecipients"
                      [value]="recipient.mandatCseId">
            {{recipient.name}} {{recipient.firstName}} - {{recipient.functionLabel}} - {{recipient.cseLabel}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br/>

      <mat-label>{{msg('transfert.choixMontantTransfert')}}</mat-label>
      <div fxLayout="row">

        <mat-form-field style="width:100px">
          <mat-label>{{msg('transfert.transfertHeures')}}</mat-label>
          <input matInput name="hoursToTranfert"
                 [(ngModel)]='hoursToTranfert'
                 (change)="hoursToTranfertChanged()"
                 class="number-right"
                 inputmode="numeric"
                 type="number"
                 min="0"
                 required/>
        </mat-form-field>

        <mat-form-field style="width:100px">
          <mat-label>{{msg('transfert.transfertMinutes')}}</mat-label>
          <input matInput name="minutesToTranfert"
                 [(ngModel)]='minutesToTranfert'
                 (change)="minutesToTranfertChanged()"
                 class="number-right"
                 type="number"
                 inputmode="numeric"
                 min="0"
                 max="60"
                 required/>
        </mat-form-field>

      </div>
      <span>
      <button mat-raised-button
              class="app-item"
              [disabled]="buttonDisabled">
        Valider
      </button>

        </span>
    </form>

  </div>

  <div class="item item-5" fxFlex="auto"></div>
</div>






