import {Component, OnInit} from '@angular/core';
import {IUser} from '../entities/user/user.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthenticationService} from '../services/authentication.service';
import {UserService} from '../services/user.service';
import {debounceTime, filter, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  filteredUsers: IUser[] = [];
  selectedUser: IUser;
  usersForm: FormGroup;

  constructor(private readonly fb: FormBuilder,
              public readonly authenticationService: AuthenticationService,
              private readonly userService: UserService,
              private readonly router: Router) {
  }

  ngOnInit() {
    this.usersForm = this.fb.group({
      userInput: null
    });

    this.usersForm
      .get('userInput')
      .valueChanges
      .pipe(
        filter(value => value.length >= 2),
        debounceTime(300),
        switchMap(value => this.userService.getFilteredAdministrableUsers(value)
        )
      ).subscribe(users => this.filteredUsers = users);
  }

  displayFn(user: IUser): string {
    return user ? `${user.prenom} ${user.nom}` : '';
  }

  select(): void {
    if (this.usersForm.get('userInput').value && this.usersForm.get('userInput').value.michelinId) {
      this.selectedUser = this.usersForm.get('userInput').value;
      this.router.navigate(['users']);
    } else {
      this.selectedUser = null;
      this.router.navigate(['users']);
    }
  }

  modify(): void {
    if (this.selectedUser && this.selectedUser.matricule) {
      this.router.navigate(['users', this.selectedUser.matricule, 'edit']);
    } else {
      this.selectedUser = null;
    }
  }

  manageMandats(): void {
    if (this.selectedUser && this.selectedUser.matricule) {
      this.router.navigate(['users', this.selectedUser.matricule, 'mandats']);
    } else {
      this.selectedUser = null;
    }
  }


  create(): void {
    this.router.navigate(['users', 'create']);
  }

}
