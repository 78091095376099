import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../initializer/AppConfig';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TranfertService = /** @class */ (function () {
    function TranfertService(http) {
        this.http = http;
    }
    /**
     * Récupère la liste des activites transférables à la date du jour pour l'utilisateur courant depuis le backend
  
     */
    TranfertService.prototype.getTransferableContingents = function () {
        return this.http
            .get(AppConfig.settings.api.transfertTempsUrl
            + '/transferableContingents');
    };
    TranfertService.prototype.getPossibleRecipents = function (mandatCseId) {
        return this.http.get(AppConfig.settings.api.transfertTempsUrl
            + '/availableTransfertRecipients', {
            params: { 'mandatCseId': mandatCseId.toString() }
        });
    };
    TranfertService.prototype.createTransfertPonctuel = function (transfert) {
        return this.http.post(AppConfig.settings.api.transfertTempsUrl, transfert, { observe: 'response' });
    };
    TranfertService.prototype.getTransferableMandats = function () {
        return this.http.get(AppConfig.settings.api.transfertTempsUrl
            + '/transferableMandats');
    };
    TranfertService.prototype.getTransferableCredits = function (mandatId) {
        return this.http.get(AppConfig.settings.api.transfertTempsUrl + "/transferableCredits?mandatId=" + mandatId);
    };
    TranfertService.prototype.createTransfertsRecurrents = function (transferts) {
        return this.http.post(AppConfig.settings.api.transfertTempsUrl + '/transfertsRecurrents', transferts, { observe: 'response' });
    };
    TranfertService.prototype.getTransfertsPonctuels = function () {
        return this.http.get(AppConfig.settings.api.transfertTempsUrl + "/transfertsPonctuels");
    };
    TranfertService.prototype.getTransfertsRecurrents = function (source) {
        return this.http.get(AppConfig.settings.api.transfertTempsUrl + "/transfertsRecurrents?mandatId=" + source.mandatCseId);
    };
    TranfertService.prototype.deleteTransfert = function (transfert) {
        return this.http.delete(AppConfig.settings.api.transfertTempsUrl + "/transfert/" + transfert.debitId, { observe: 'response' });
    };
    TranfertService.prototype.updateTransfert = function (transfert, newDurationInMills) {
        return this.http.patch(AppConfig.settings.api.transfertTempsUrl + "/transfert/" + transfert.debitId, newDurationInMills);
    };
    TranfertService.ngInjectableDef = i0.defineInjectable({ factory: function TranfertService_Factory() { return new TranfertService(i0.inject(i1.HttpClient)); }, token: TranfertService, providedIn: "root" });
    return TranfertService;
}());
export { TranfertService };
