import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../initializer/AppConfig';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ListeDesHeuresService = /** @class */ (function () {
    function ListeDesHeuresService(http) {
        this.http = http;
    }
    /**
     * recupere la liste des debits
     */
    ListeDesHeuresService.prototype.getListDesHeures = function (michelinId) {
        return this.http.get(AppConfig.settings.api.extractionUrl + '/' + michelinId + '/debitsAfterLastMonth');
    };
    ListeDesHeuresService.ngInjectableDef = i0.defineInjectable({ factory: function ListeDesHeuresService_Factory() { return new ListeDesHeuresService(i0.inject(i1.HttpClient)); }, token: ListeDesHeuresService, providedIn: "root" });
    return ListeDesHeuresService;
}());
export { ListeDesHeuresService };
