import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConfig} from '../initializer/AppConfig';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {ICreditCollectif} from '../entities/previsions/creditCollectif.model';
import {IPrevision} from '../entities/previsions/prevision.model';

@Injectable({
  providedIn: 'root'
})
export class PrevisionsService {

  constructor(private http: HttpClient) {
  }

  public getCreditCollectifs(): Observable<ICreditCollectif[]> {
    return this.http.get<ICreditCollectif[]>(AppConfig.settings.api.previsionsUrl
      + '/credits');
  }

  public getCreditCollectifDetails(id: number): Observable<ICreditCollectif> {
    return this.http.get<ICreditCollectif>(AppConfig.settings.api.previsionsUrl
      + '/credit/' + id);
  }

  delete(previsionId: number): Observable<HttpResponse<{}>> {
    return this.http.delete(AppConfig.settings.api.previsionsUrl
      + '/previsions/' + previsionId, {observe: 'response'});
  }

  update(prevision: IPrevision): Observable<IPrevision> {
    return this.http.put<IPrevision>(AppConfig.settings.api.previsionsUrl
      + '/prevision/', prevision);
  }

  create(prevision: IPrevision): Observable<IPrevision> {
    return this.http.post<IPrevision>(AppConfig.settings.api.previsionsUrl
      + '/previsions/', prevision);
  }

  createMultiple(previsions: IPrevision[]): Observable<IPrevision[]> {
    return this.http.put<IPrevision[]>(AppConfig.settings.api.previsionsUrl
      + '/previsions/', previsions);
  }
}
