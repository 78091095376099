import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Idecodedtoken} from '../../entities/user/Idecodedtoken';
import {AuthenticationService} from '../../services/authentication.service';
import {AppConfig} from '../../initializer/AppConfig';
import {MatDialog} from '@angular/material';
import {ContactDialogComponent} from '../contact-dialog/contact-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private readonly router: Router,
              public authenticationService: AuthenticationService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.authenticationService.validateToken().subscribe({error: () => this.authenticationService.logout()});
  }

  /**
   * Accès aux textes/messages paramétrés
   * @param key
   */
  msg(key) {
    return AppConfig.msg(key);
  }

  get tokenObject(): Idecodedtoken {
    return AuthenticationService.tokenObject();
  }

  goToLoginPage() {
    this.router.navigate(['/login']);
  }

  goToAccueil() {
    this.router.navigate(['/home']);
  }

  goToRelationsSociales() {
    this.router.navigate(['relationsSociales']);
  }

  goToCse() {
    this.router.navigate(['cse']);
  }

  goToOS() {
    this.router.navigate(['organisationSyndicale']);
  }

  logoutProcess() {
    console.debug('logging out');
    this.authenticationService.logout();
  }

  stopImpersonnation() {
    console.debug('Stopping impersonnation');
    this.authenticationService.stopImpersonation();
  }

  saisieDesHeures() {
    console.debug('saisieDesHeures');
    this.router.navigate(['saisieDesHeures']);
  }

  navigateToAdministration() {
    this.router.navigate(['administration']);
  }

  listeDesHeures() {
    console.debug('listeDesHeures');
    this.router.navigate(['listeDesHeures']);
  }

  transfertHeuresManagement() {
    this.router.navigate(['transfertManagement']);
  }

  extractionContingent() {
    console.debug('extractionContingent');
    this.router.navigate(['extractionContingent']);
  }

  transfertHeures() {
    console.debug('transfertHeures');
    this.router.navigate(['transfertHeures']);
  }


  goToUserManagement() {
    this.router.navigate(['users']);
  }

  openContactDialog(): void {
    const dialogRef = this.dialog.open(ContactDialogComponent, {
      width: '600px',
      hasBackdrop: true
    });
  }
}


