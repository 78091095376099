import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {IRole} from '../entities/user/role.model';
import {AppConfig} from '../initializer/AppConfig';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private readonly http: HttpClient) {
  }

  getAssignableRoles(): Observable<IRole[]> {
    return this.http.get<IRole[]>(`${AppConfig.settings.api.rolesUrl}/assignableRoles`);
  }
}
