import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { AppConfig } from '../initializer/AppConfig';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Service de liaison vers le backend pour la gestion des heures
 */
var GestionDesHeuresService = /** @class */ (function () {
    function GestionDesHeuresService(http) {
        this.http = http;
    }
    /**
     * Récupère la liste des activites depuis le backend
     * @param michelinId
     * @param date
     */
    GestionDesHeuresService.prototype.getActivitesByDate = function (michelinId, date) {
        return this.http
            .get(AppConfig.settings.api.saisieTempsUrl + '/' + michelinId
            + '/getContingentsByDate?dateDebit='
            + formatDate(date, 'yyyy-MM-dd', 'fr'));
    };
    /**
     * recupere la date de la derniere paye
     *@param date
     */
    GestionDesHeuresService.prototype.getLatestPaye = function () {
        return this.http
            .get(AppConfig.settings.api.saisieTempsUrl + '/getLatestPaye');
    };
    /**
     * recupere la date limite de saisie et de modification
     *@param date
     */
    GestionDesHeuresService.prototype.getHorizonSaisie = function () {
        return this.http
            .get(AppConfig.settings.api.saisieTempsUrl + '/getHorizonSaisie');
    };
    /**
     * get debit by Id
     * @param id
     */
    GestionDesHeuresService.prototype.getDebit = function (id) {
        return this.http.get(AppConfig.settings.api.saisieTempsUrl + '/getDebitById?debitId=' + id);
    };
    /**
     * Déclare une nouvelle saisie vers le backend
     * @param conso
     */
    GestionDesHeuresService.prototype.addDebit = function (conso) {
        var consoEnvoi = {
            debitId: conso.debitId,
            dateDebit: formatDate(conso.dateDebit, 'yyyy-MM-dd', 'fr'),
            heureDebut: conso.heureDebut,
            duree: conso.duree,
            typeHeureId: conso.typeHeureId,
            mandatCseId: conso.mandatCseId,
            commentaire: conso.commentaire
        };
        return this.http.post(AppConfig.settings.api.saisieTempsUrl + '/addDebit', consoEnvoi);
    };
    /**
     * supprime un debit
     * @param debitId
     */
    GestionDesHeuresService.prototype.supprimeUnDebit = function (debitId) {
        return this.http
            .delete(AppConfig.settings.api.saisieTempsUrl + '/supprimedebitbyid?debitId=' + debitId);
    };
    /**
     * Dmet à jours un débit
     * @param conso
     */
    GestionDesHeuresService.prototype.updateDebit = function (conso) {
        var consoEnvoi = {
            debitId: conso.debitId,
            dateDebit: formatDate(conso.dateDebit, 'yyyy-MM-dd', 'fr'),
            heureDebut: conso.heureDebut,
            duree: conso.duree,
            typeHeureId: conso.typeHeureId,
            mandatCseId: conso.mandatCseId,
            commentaire: conso.commentaire
        };
        return this.http.put(AppConfig.settings.api.saisieTempsUrl + '/updateDebit', consoEnvoi);
    };
    GestionDesHeuresService.ngInjectableDef = i0.defineInjectable({ factory: function GestionDesHeuresService_Factory() { return new GestionDesHeuresService(i0.inject(i1.HttpClient)); }, token: GestionDesHeuresService, providedIn: "root" });
    return GestionDesHeuresService;
}());
export { GestionDesHeuresService };
