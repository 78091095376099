<div class="container"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="3px">
  <mat-card class="detail-debit">

    <div *ngFor="let dWrap of debitWrapper">
      <mat-card-content>
        <div class="item item-1" fxFlex="90%">
          <div mat-card-title>{{dWrap.motif}}</div>
          <div>le {{dWrap.dateDebit | date:'EEE dd/MM/yyyy'}}
                de {{dWrap.heureDebut | slice:0:5 }} à {{dWrap.heureFin | slice:0:5 }}</div>
          <div>{{dWrap.commentaire}}</div>
        </div>
        <div class="item item-2" fxFlex="13%">
          <button mat-button (click)="modification()">
            <mat-icon matTooltip="modifier" matTooltipPosition="right">edit</mat-icon>
          </button>
          <button  mat-button (click)="suppression()">
            <mat-icon matTooltip="supprimer" matTooltipPosition="right">delete</mat-icon>
          </button>
        </div>
      </mat-card-content>
    </div>
  </mat-card>
</div>
