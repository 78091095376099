<mat-toolbar class="app-toolbar" color="primary">
  <div class="flex-container"
       fxLayout.xs="0"
       fxLayoutGap="10px"
       fxLayoutGap.xs="0">

    <div flex-order="1"  >
      <span class="texte-footer">
        Copyright © {{year | date : 'yyyy'}}, Manufacture Française des Pneumatiques Michelin. Tous droits réservés. {{year | date : 'yyyy'}}
      </span>
    </div>


    <div class="texte-droite" flex-order="3" fxFlexOffset="1em">
        <span (click)="openDonneesPersoDialog()">Données personnelles</span>
    </div>

  </div>
</mat-toolbar>
