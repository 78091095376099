import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IActiviteDTO} from '../entities/iActiviteDTO';
import {AppConfig} from '../initializer/AppConfig';
import {ITransfertParticipant} from '../entities/tranfert-de-temps/ITransfertParticipant';
import {
  ITransfert,
  ITransfertPonctuelCreation,
  TransfertPonctuelCreation,
  TransfertRecurrentCreation
} from '../entities/tranfert-de-temps/transfert.model';
import {ICreditIndividuel} from '../entities/creditIndividuel.model';

@Injectable({
  providedIn: 'root'
})
export class TranfertService {

  constructor(private http: HttpClient) {
  }

  /**
   * Récupère la liste des activites transférables à la date du jour pour l'utilisateur courant depuis le backend

   */
  public getTransferableContingents(): Observable<IActiviteDTO[]> {
    return this.http
      .get<IActiviteDTO[]>(AppConfig.settings.api.transfertTempsUrl
        + '/transferableContingents');
  }

  public getPossibleRecipents(mandatCseId: number): Observable<ITransfertParticipant[]> {
    return this.http.get<ITransfertParticipant[]>(AppConfig.settings.api.transfertTempsUrl
      + '/availableTransfertRecipients', {
      params: {'mandatCseId': mandatCseId.toString()}
    });
  }


  public createTransfertPonctuel(transfert: TransfertPonctuelCreation): Observable<HttpResponse<ITransfertPonctuelCreation>> {
    return this.http.post<ITransfertPonctuelCreation>(AppConfig.settings.api.transfertTempsUrl, transfert, {observe: 'response'});
  }

  public getTransferableMandats(): Observable<ITransfertParticipant[]> {
    return this.http.get<ITransfertParticipant[]>(AppConfig.settings.api.transfertTempsUrl
      + '/transferableMandats');
  }

  public getTransferableCredits(mandatId: number): Observable<ICreditIndividuel[]> {
    return this.http.get<ICreditIndividuel[]>(`${AppConfig.settings.api.transfertTempsUrl}/transferableCredits?mandatId=${mandatId}`);
  }

  public createTransfertsRecurrents(transferts: TransfertRecurrentCreation[]): Observable<HttpResponse<TransfertRecurrentCreation[]>> {
    return this.http.post<TransfertRecurrentCreation[]>(AppConfig.settings.api.transfertTempsUrl + '/transfertsRecurrents', transferts,
      {observe: 'response'});

  }

  public getTransfertsPonctuels(): Observable<ITransfert[]> {
    return this.http.get<ITransfert[]>(`${AppConfig.settings.api.transfertTempsUrl}/transfertsPonctuels`);
  }

  public getTransfertsRecurrents(source: ITransfertParticipant): Observable<ITransfert[]> {
    return this.http.get<ITransfert[]>(`${AppConfig.settings.api.transfertTempsUrl}/transfertsRecurrents?mandatId=${source.mandatCseId}`);
  }

  public deleteTransfert(transfert: ITransfert): Observable<HttpResponse<{}>> {
    return this.http.delete(`${AppConfig.settings.api.transfertTempsUrl}/transfert/${transfert.debitId}`, {observe: 'response'});
  }

  public updateTransfert(transfert: ITransfert, newDurationInMills: number): Observable<ITransfert> {
    return this.http.patch <ITransfert>(`${AppConfig.settings.api.transfertTempsUrl}/transfert/${transfert.debitId}`,
      newDurationInMills);
  }


}
