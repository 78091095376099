import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {MatDialog} from '@angular/material';
import {DonneesPersonDialogComponent} from '../donnees-person-dialog/donnees-person-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  year = Date.now();

  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
    public dialog: MatDialog) { }


  ngOnInit() {
  }

  openDonneesPersoDialog(): void {
    console.debug('openDonneesPersoDialog');
    const dialogRef = this.dialog.open(DonneesPersonDialogComponent, {
      width: '1000px',
      hasBackdrop: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.debug('The dialog was closed');
    });
  }
}
