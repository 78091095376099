import {Component, OnInit} from '@angular/core';
import {ListeDesHeuresService} from '../services/liste-des-heures.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {IDebitExtractDTO} from '../entities/iDebitExtractDTO';
import {HttpErrorResponse} from '@angular/common/http';
import {AppConfig} from '../initializer/AppConfig';
import {Duration} from 'moment';
import {MessageService} from '../services/message.service';
import {AuthenticationService} from '../services/authentication.service';
import {IUser} from '../entities/user/user.model';
import {UserService} from '../services/user.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {debounceTime, filter, switchMap} from 'rxjs/operators';


/**
 * Liste des heures component
 */
@Component({
  selector: 'app-liste-des-heures',
  templateUrl: './liste-des-heures.component.html',
  styleUrls: ['./liste-des-heures.component.scss'],
})
export class ListeDesHeuresComponent implements OnInit {

  /**
   * Titre injecté dans la page
   */
  pageTitle = this.msg('listeHeures.title');

  /**
   * liste des debits
   */
  public listDebit: Array<IDebitExtractDTO>;

  /**
   * duree debit -----temp
   */
  public duree: Duration;

  /**
   * duree debit format date ----temp
   */
  public dateduree: Date;

  numbers: number[] = [];

  administrableUsers: IUser[];
  selectedMichelinId: string;


  filteredUsers: IUser[] = [];
  selectedUser: IUser;
  usersForm: FormGroup;


  /**
   *
   * @param listeDesHeuresService
   * @param toastr
   * @param router
   * @param location
   */
  constructor(private listeDesHeuresService: ListeDesHeuresService,
              public toastr: ToastrService,
              private router: Router,
              private location: Location,
              private message: MessageService,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              private readonly fb: FormBuilder) {
    this.listDebit = [];
  }

  ngOnInit() {
    if (this.accessUserList()) {

      this.usersForm = this.fb.group({
        userInput: null
      });
      this.usersForm
        .get('userInput')
        .valueChanges
        .pipe(
          filter(value => value.length >= 2),
          debounceTime(300),
          switchMap(value => this.userService.getFilteredAdministrableUsers(value)
          )
        ).subscribe(users => this.filteredUsers = users);

    } else {
      this.selectedMichelinId = AuthenticationService.getTokenObject().sub;
    }

    this.loadListDebit();
    this.message.rechargePageListDebitMessage
      .subscribe(message => {
        this.recoitMessage(message);
      });
    this.message.transfertParamsDebitModif
      .subscribe(message => {
        this.modifDebit(message);
      });
    if (!this.accessUserList()) {
      this.selectedMichelinId = AuthenticationService.getTokenObject().sub;
    }
  }

  recoitMessage(message) {
    if (message === 'recharge_page') {
      console.debug('recoitMessage: ' + message + '////////////////////////');
      this.rafraichirListeDebit();
    }
  }

  rafraichirListeDebit() {
    console.debug('rafraichirListeDebit');
    this.loadListDebit();
  }

  modifDebit(debit: IDebitExtractDTO) {
    if (debit != null) {
      this.message.changeMessageDebitExtract(null);
      this.router.navigate(['saisieDesHeures', debit.debitId]);
    }
  }

  /**
   * Accès aux textes/messages paramétrés
   * @param key
   */
  msg(key) {
    return AppConfig.msg(key);
  }

  /**
   *  Recuperer la liste des debits
   */
  public loadListDebit() {
    console.debug('debitList');
    this.listDebit = [];
    if (this.selectedMichelinId) {
      this.listeDesHeuresService.getListDesHeures(this.selectedMichelinId).subscribe(resultList => {
          this.successGetListDebit(resultList);
        },
        (err: HttpErrorResponse) => {
          this.errorGetListDebit(err);
        });
    }
  }


  /**
   * Le backend a bien retourné une liste des debits (éventuellement vide)
   * @param result
   */
  private successGetListDebit(list: Array<IDebitExtractDTO>) {
    console.debug(list);
    this.listDebit = [];
    if (list.length !== 0) {
      for (const debitIt of list) {
        console.debug('debitIt: ' + debitIt);
        const debit: IDebitExtractDTO = debitIt;
        console.debug('debitIt: ' + debitIt);
        console.debug('debitIt-duree a: ' + debitIt.duree);
        this.dateduree = debitIt.duree;
        console.debug('debitIt-duree b: ' + this.dateduree);
        this.listDebit.push(debit);
      }
    } else {
      console.debug('liste vide: ' + this.listDebit);
    }
  }

  /**
   * Le backend a retourné une erreur au lieu de la liste des debits
   * @param err
   */
  private errorGetListDebit(err: HttpErrorResponse) {
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error.message}`);
      console.error('err: ' + err);
      this.toastr.error(err.error.message, this.msg('lib.Error'));
    }
  }

  public accessUserList(): boolean {
    return this.authenticationService.hasAnyAuthority(['ROLE_ADM_USER_LST']);
  }

  mandateChanged() {
    if (this.usersForm.get('userInput').value && this.usersForm.get('userInput').value.michelinId) {
      this.selectedUser = this.usersForm.get('userInput').value;
      this.selectedMichelinId = this.selectedUser.michelinId;
    }

    this.rafraichirListeDebit();
  }

  displayFn(user: IUser): string {
    return user ? `${user.prenom} ${user.nom}` : '';
  }
}

