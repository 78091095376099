import {IRole} from './role.model';


export interface IUser {
  matricule?: string;
  michelinId?: string;
  nom?: string;
  prenom?: string;
  active?: boolean;
  roles?: IRole[];
  etablissementId?: number;
  osId?: string;
  mail?: string;
  managerMail?: string;
}

export class User implements IUser {


  constructor(public matricule?: string,
              public michelinId?: string,
              public nom?: string,
              public prenom?: string,
              public active?: boolean,
              public roles?: IRole[],
              public etablissementId?: number,
              public osId?: string,
              public mail?: string,
              public managerMail?: string) {


  }


}
