import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../initializer/AppConfig';
import {Observable} from 'rxjs';
import {IDebitExtractDTO} from '../entities/iDebitExtractDTO';

@Injectable({
  providedIn: 'root'
})
export class ListeDesHeuresService {

  constructor(private http: HttpClient) {
  }

  /**
   * recupere la liste des debits
   */
  public getListDesHeures(michelinId: string): Observable<IDebitExtractDTO[]> {
    return this.http.get<IDebitExtractDTO[]>(AppConfig.settings.api.extractionUrl + '/' + michelinId + '/debitsAfterLastMonth');
  }


}
