<div class="container"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0">
  <div fxFlex="auto"></div>
  <div class="titrePage" fxFlex>{{pageTitle}}</div>
  <div fxFlex="auto"></div>
</div>


<div class="container"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0">
  <div class="item item-5" fxFlex="15em" fxFlex.lt-md="0em"></div>

  <div class="item item-4" fxFlex fxFlexOffset="50px"  fxFlexOffset.xs="0">
      <button mat-raised-button
              class="app-item"
              [disabled]="false"
              (click)="extraction()">
        {{msg('extractionContingent.btn')}}
      </button>
  </div>



  <div class="item item-5" fxFlex="auto"></div>
</div>




