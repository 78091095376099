import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IUser} from '../entities/user/user.model';
import {AppConfig} from '../initializer/AppConfig';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private readonly http: HttpClient) {
  }

  public getAdministrableUsers(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${AppConfig.settings.api.usersUrl}/administrableUsers`);
  }

  public getFilteredAdministrableUsers(searchedPattern?: string): Observable<IUser[]> {
    const opts = { params: new HttpParams({fromString: `searchedPattern=${searchedPattern}`}) };
    return this.http.get<IUser[]>(`${AppConfig.settings.api.usersUrl}/filteredAdministrableUsers`, opts);
  }

  public getUsersMatching(namePattern: string): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${AppConfig.settings.api.usersUrl}/getUsersMatching?namePattern=${namePattern}`);
  }

  public find(matricule: string): Observable<HttpResponse<IUser>> {
    return this.http.get<IUser>(`${AppConfig.settings.api.usersUrl}/${matricule}`, {observe: 'response'});
  }

  create(user: IUser): Observable<HttpResponse<IUser>> {
    return this.http.post<IUser>(`${AppConfig.settings.api.usersUrl}/${user.matricule}`, user, { observe: 'response' });
  }

  update(user: IUser): Observable<HttpResponse<IUser>> {
    return this.http.put<IUser>(`${AppConfig.settings.api.usersUrl}/${user.matricule}`, user, { observe: 'response' });
  }

}
