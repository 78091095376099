import { OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../services/authentication.service';
import { ActuatorService } from '../entities/actuator/actuator.service';
import { AppConfig } from '../initializer/AppConfig';
import { Router } from '@angular/router';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(actuatorService, toastr, router, authenticationService) {
        this.actuatorService = actuatorService;
        this.toastr = toastr;
        this.router = router;
        this.authenticationService = authenticationService;
        /**
         * Titre injecté dans la page
         */
        this.pageTitle = 'Accueil';
    }
    /**
     * verif connection ok sinon goto login
     */
    HomeComponent.prototype.ngOnInit = function () {
        if (!this.tokenObject) {
            this.router.navigate(['/login']);
        }
    };
    /**
     * Accès aux textes/messages paramétrés
     * @param key
     */
    HomeComponent.prototype.msg = function (key) {
        return AppConfig.msg(key);
    };
    Object.defineProperty(HomeComponent.prototype, "tokenObject", {
        get: function () {
            return AuthenticationService.tokenObject();
        },
        enumerable: true,
        configurable: true
    });
    HomeComponent.prototype.saisieDesHeures = function () {
        this.router.navigate(['saisieDesHeures']);
    };
    HomeComponent.prototype.listeDesHeures = function () {
        this.router.navigate(['listeDesHeures']);
    };
    HomeComponent.prototype.extractionContingent = function () {
        this.router.navigate(['extractionContingent']);
    };
    HomeComponent.prototype.transfertHeures = function () {
        this.router.navigate(['transfertHeures']);
    };
    HomeComponent.prototype.transfertHeuresManagement = function () {
        this.router.navigate(['transfertManagement']);
    };
    HomeComponent.prototype.goToRelationsSociales = function () {
        this.router.navigate(['relationsSociales']);
    };
    HomeComponent.prototype.goToCse = function () {
        this.router.navigate(['cse']);
    };
    HomeComponent.prototype.goToOS = function () {
        this.router.navigate(['organisationSyndicale']);
    };
    return HomeComponent;
}());
export { HomeComponent };
