import {Component, OnInit} from '@angular/core';
import {User} from '../../entities/user/user.model';
import {AppConfig} from '../../initializer/AppConfig';
import {ToastrService} from 'ngx-toastr';
import {EtablissmentService} from '../../services/etablissment.service';
import {OsService} from '../../services/os.service';
import {RoleService} from '../../services/role.service';
import {IEtablissment} from '../../entities/user/etatblissment.model';
import {IOs} from '../../entities/user/os.model';
import {IRole} from '../../entities/user/role.model';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  selectableEtablissments: IEtablissment[];
  selectableOss: IOs[];
  selectableRoles: IRole[];
  user: User = new User();
  isNew: boolean;

  constructor(protected activatedRoute: ActivatedRoute,
              private readonly router: Router,
              public readonly  toastr: ToastrService,
              private readonly userService: UserService,
              private readonly etablissmentService: EtablissmentService,
              private readonly osService: OsService,
              private readonly roleService: RoleService) {
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({user}) => {
      this.user = user;
      if (user.matricule === undefined) {
        this.isNew = true;
        this.user.active = true;
      }
    });

    this.etablissmentService.getAssignableEtablissments().subscribe(etablissments => {
      this.selectableEtablissments = etablissments;
      if (!this.user.etablissementId && this.selectableEtablissments.length === 1) {
        this.user.etablissementId = this.selectableEtablissments[0].etablissementId;
      }
    });

    this.osService.getOss().subscribe(oss => this.selectableOss = oss);

    this.roleService.getAssignableRoles().subscribe(roles => this.selectableRoles = roles);

  }


  compareRoles(o1: IRole, o2: IRole) {
    if (!o1 || !o2) {
      return !o1 && !o2;
    }
    return o1 === o2 || o1.roleId === o2.roleId;
  }

  msg(key) {
    return AppConfig.msg(key);
  }

  cancel() {
    this.reload();
  }

  save() {
    if (this.isNew) {
      this.create();
    } else {
      this.update();
    }
  }

  private create() {
    this.userService.create(this.user).subscribe(
      () => {
        this.toastr.success('Utilisateur créé');
        this.router.navigate(['users']);
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  private update() {
    this.userService.update(this.user).subscribe(
      () => {
        this.toastr.success('Utilisateur mis à jour');
        this.router.navigate(['users']);
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message);
      }
    );

  }

  private reload() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['./'], {relativeTo: this.activatedRoute});
  }

  lowerCaseMichelinId() {
    if (this.user) {
      this.user.michelinId = this.user.michelinId.toLowerCase();
    }
  }
}
