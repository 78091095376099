<div class="container corps"
     fxLayout
     fxLayout.xs="column"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0">
  <div class="item item-5" fxFlex="20em" fxFlex.lt-md="0em"></div>
  <div class="item item-4" fxFlex fxFlexOffset="50px" fxFlexOffset.xs="0">
    <h3>Impersonnation</h3>
    <form [formGroup]='usersForm'>
      <mat-form-field style="min-width: 250px;">
        <input matInput
               formControlName='userInput'
               required
               [matAutocomplete]="auto"
               style="min-width: 500px">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" >
          <mat-option *ngFor="let user of filteredUsers" [value]="user">
            <span>{{user.prenom}} {{user.nom}} </span>
            <small> | ID: {{user.michelinId}}</small>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <button mat-raised-button
              (click)="impersonate($event)">
        Valider
      </button>
    </form>
  </div>
</div>
