<div>
  <mat-dialog-content>
    <form>
      <div fxLayout="column"
           fxLayoutAlign="center"
           fxLayoutGap="5px">
        <mat-form-field>
          <mat-label>Organisation</mat-label>
          <mat-select [(ngModel)]="mandat.cse" name="organisation" [compareWith]="compareCses" required
                      (selectionChange)="onCseChanged($event)" [disabled]="mandat.id">
            <mat-option *ngFor="let cse of selectableCse" [value]="cse">
              {{cse.libelle}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Fonction</mat-label>
          <mat-select [(ngModel)]="mandat.fonction" name="fonction" [compareWith]="compareFonctions" required
                      [disabled]="mandat.id">
            <mat-option *ngFor="let fonction of selectableFonctions" [value]="fonction">
              {{fonction.libelle}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Date de début</mat-label>
          <input matInput [matDatepicker]="beginPicker"
                 [(ngModel)]="mandat.begin" name="begin" required
                 [min]="mandat?.cse?.debut" [max]="mandat.end?mandat.end:mandat?.cse?.fin"
                 [disabled]="!mandat.cse">
          <mat-datepicker-toggle matSuffix [for]="beginPicker"></mat-datepicker-toggle>
          <mat-datepicker #beginPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Date de fin</mat-label>
          <input matInput [matDatepicker]="endPicker"
                 [(ngModel)]="mandat.end" name="end" required
                 [min]="mandat.begin?mandat.begin:mandat?.cse?.debut" [max]="mandat?.cse?.fin"
                 [disabled]="!mandat.cse">
          <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>


      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Annuler</button>
    <button n-submit class="mat-raised-button mat-primary" (click)="save()">Sauvegarder</button>
  </mat-dialog-actions>

</div>
