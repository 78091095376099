import {Component, Inject, OnInit} from '@angular/core';
import {ITransfertParticipant} from '../../../../entities/tranfert-de-temps/ITransfertParticipant';
import {ToastrService} from 'ngx-toastr';
import {TranfertService} from '../../../../services/tranfert.service';
import {ICreditIndividuel} from '../../../../entities/creditIndividuel.model';
import {AppConfig} from '../../../../initializer/AppConfig';
import {ITransfertRecurrentCreation, TransfertRecurrentCreation} from '../../../../entities/tranfert-de-temps/transfert.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-transfert-recurrent-creation',
  templateUrl: './transfert-recurrent-creation.component.html',
  styleUrls: ['./transfert-recurrent-creation.component.scss']
})
export class TransfertRecurrentCreationComponent implements OnInit {
  transfertSource: ITransfertParticipant;
  possibleTransfertSources: ITransfertParticipant[];

  transfertTarget: ITransfertParticipant;

  possibleTransfertTargets: ITransfertParticipant[];

  transferableCredits: ICreditIndividuel[];

  selectedCredits: ICreditIndividuel[];
  minutesToTranfert = 0;
  hoursToTranfert = 0;

  buttonDisabled = true;


  constructor(private readonly dialogRef: MatDialogRef<TransfertRecurrentCreationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { transfertSource: ITransfertParticipant },
              public toastr: ToastrService, private tranfertService: TranfertService) {
  }

  ngOnInit() {
    this.transfertSource = this.data.transfertSource;
    this.transfertSourceChanged();
  }

  transfertSourceChanged() {
    this.possibleTransfertTargets = null;
    this.tranfertService.getPossibleRecipents(this.transfertSource.mandatCseId)
      .subscribe(mandats => this.possibleTransfertTargets = mandats);

    this.tranfertService.getTransferableCredits(this.transfertSource.mandatCseId)
      .subscribe(credits => {
        this.transferableCredits = credits;
        this.selectedCredits = [];
        this.hoursToTranfert = 0;
        this.minutesToTranfert = 0;
        this.updateButtonStatus();
      });


  }

  submitTransfert() {
    const transfertToCreate = [] as ITransfertRecurrentCreation[];
    const duration = this.hoursToTranfert * 60 + this.minutesToTranfert;
    transfertToCreate.push(...this.selectedCredits
      .map(credit => new TransfertRecurrentCreation(credit.id,
        this.transfertSource.mandatCseId,
        this.transfertTarget.mandatCseId, duration, credit.typeHeureId)));
    this.tranfertService.createTransfertsRecurrents(transfertToCreate).subscribe(transferts => {
        this.toastr.success(transferts.body.length + ' transfert(s) créé(s)');
        this.transfertSourceChanged();
        this.dialogRef.close(true);
      },
      error => {
        this.toastr.error(error.error.message);
      }
    );
  }

  msg(key) {
    return AppConfig.msg(key);
  }


  getMaximumTransfert(): number {
    return this.selectedCredits && this.selectedCredits.length ? Math.max(0, Math.min(...this.selectedCredits.map(it => it.remaining))) : 0;
  }

  updateButtonStatus() {
    this.buttonDisabled = !this.transfertSource || !this.transfertTarget || !this.selectedCredits
      || (this.hoursToTranfert <= 0 && this.minutesToTranfert <= 0)
      || this.getMaximumTransfert() < this.hoursToTranfert * 3600 + this.minutesToTranfert * 60;
  }

  close() {
    this.dialogRef.close();
  }

}
