import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {Itoken} from '../../entities/user/Itoken';
import {IUser} from '../../entities/user/user.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {debounceTime, filter, switchMap} from 'rxjs/operators';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-impersonnation',
  templateUrl: './impersonnation.component.html',
  styleUrls: ['./impersonnation.component.scss']
})
export class ImpersonnationComponent implements OnInit {
  userIdToImpersonnate: string;

  filteredUsers: IUser[] = [];
  usersForm: FormGroup;

  constructor(private fb: FormBuilder, private authenticationService: AuthenticationService, private userService: UserService) {
  }

  ngOnInit() {
    this.usersForm = this.fb.group({
      userInput: null
    });

    this.usersForm
      .get('userInput')
      .valueChanges
      .pipe(
        filter(value => value.length >= 2),
        debounceTime(300),
        switchMap(value => this.userService.getUsersMatching(value)
        )
      ).subscribe(users => this.filteredUsers = users);
  }


  displayFn(user: IUser): string {
    return user ? user.prenom + ' ' + user.nom : '';
  }

  impersonate(event: Event): void {
    if (this.usersForm.get('userInput').value && this.usersForm.get('userInput').value.michelinId) {
      this.userIdToImpersonnate = this.usersForm.get('userInput').value.michelinId;

      this.authenticationService.getImpersonateToken(this.userIdToImpersonnate).subscribe((token: Itoken) => {
        const tokenStr = token.token;
        this.authenticationService.doImpersonation(tokenStr);
        setTimeout(() => location.reload(), 3000);
      });
    }
    event.stopPropagation();
  }
}
