import { OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PrevisionsService } from '../../../services/previsions.service';
import { AppConfig } from '../../../initializer/AppConfig';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { PrevisionEditDialogComponent } from './prevision-edit-dialog/prevision-edit-dialog.component';
import { PrevisionMassEditDialogComponent } from './prevision-mass-edit-dialog/prevision-mass-edit-dialog.component';
import { FormControl } from '@angular/forms';
var PrevisionsComponent = /** @class */ (function () {
    function PrevisionsComponent(toastr, gestionPrevisionsService, dialog) {
        this.toastr = toastr;
        this.gestionPrevisionsService = gestionPrevisionsService;
        this.dialog = dialog;
        this.pageTitle = 'Gestion des prévisions';
        this.dataSource = new MatTableDataSource();
        this.buttonDisabled = false;
        this.mandateFilter = new FormControl();
        this.filteredValues = { fonction: '', mandate: '' };
    }
    PrevisionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.gestionPrevisionsService.getCreditCollectifs().subscribe(function (creditCollectifs) { return _this.selectableCredits = creditCollectifs; }, function (err) { return _this.toastr.error(err.error.message); });
        this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        this.mandateFilter.valueChanges.subscribe(function (mandateFilterValue) {
            _this.filteredValues['mandate'] = mandateFilterValue;
            _this.dataSource.filter = JSON.stringify(_this.filteredValues);
        });
        this.dataSource.filterPredicate = this.customFilterPredicate();
        this.dataSource.connect().subscribe(function () {
            _this.filteredTotal = _this.dataSource.filteredData.reduce(function (sum, prevision) { return sum += +prevision.duration; }, 0);
            _this.filteredUsedTotal = _this.dataSource.filteredData.reduce(function (sum, prevision) { return sum += +prevision.durationUsed; }, 0);
        });
    };
    PrevisionsComponent.prototype.customFilterPredicate = function () {
        var myFilterPredicate = function (data, filter) {
            var searchString = JSON.parse(filter);
            var mandateFound = data.mandate.trim().toLowerCase().indexOf(searchString.mandate.toLowerCase()) !== -1;
            return mandateFound;
        };
        return myFilterPredicate;
    };
    PrevisionsComponent.prototype.applyFilter = function (filterValue) {
        var filter = {
            name: filterValue.trim().toLowerCase(),
            position: filterValue.trim().toLowerCase()
        };
        this.dataSource.filter = JSON.stringify(filter);
    };
    PrevisionsComponent.prototype.msg = function (key) {
        return AppConfig.msg(key);
    };
    PrevisionsComponent.prototype.loadCreditCollectif = function () {
        var _this = this;
        this.gestionPrevisionsService.getCreditCollectifDetails(this.selectedCreditId).subscribe(function (creditCollectif) {
            var _a;
            _this.selectedCredit = creditCollectif;
            _this.commission = _this.selectedCredit.eligibleFonctions.find(function (fct) { return _this.commission && fct.id === _this.commission.id; });
            _this.dataSource.data = (_this.selectedCredit && _this.commission)
                ? (_a = []).concat.apply(_a, _this.commission.activeMandats.map(function (mandat) { return mandat.previsions; })) :
                [];
            _this.datatable.renderRows();
            var sortState = { active: 'effectDate', direction: 'asc' };
            _this.sort.active = sortState.active;
            _this.sort.direction = sortState.direction;
            _this.sort.sortChange.emit(sortState);
        }, function (err) {
            _this.toastr.error(err.error.message, _this.msg('lib.Error'));
        });
    };
    PrevisionsComponent.prototype.creditSelectedChanged = function () {
        this.loadCreditCollectif();
    };
    PrevisionsComponent.prototype.modify = function (prevision) {
        var _this = this;
        var dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = { prevision: prevision, creditCollectif: this.selectedCredit };
        var dialogRef = this.dialog.open(PrevisionEditDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(function (val) {
            if (val) {
                _this.update(val);
            }
        });
    };
    PrevisionsComponent.prototype.createMultiple = function () {
        var _this = this;
        var dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = { creditCollectif: this.selectedCredit, fonction: this.commission };
        var dialogRef = this.dialog.open(PrevisionMassEditDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(function (val) {
            if (val) {
                _this.loadCreditCollectif();
            }
        });
    };
    PrevisionsComponent.prototype.update = function (prevision) {
        var _this = this;
        this.gestionPrevisionsService.update(prevision).subscribe(function () {
            _this.toastr.success('Prévision modifiée');
            _this.loadCreditCollectif();
        }, function (err) { return _this.toastr.error(err.error.message, _this.msg('lib.Error')); });
    };
    PrevisionsComponent.prototype.delete = function (prevision) {
        var _this = this;
        this.buttonDisabled = true;
        this.gestionPrevisionsService.delete(prevision.id).subscribe(function () {
            _this.toastr.success('Suppression effectée');
            _this.loadCreditCollectif();
        }, function (err) { return _this.toastr.error(err.error.message, _this.msg('lib.Error')); }, function () { return _this.buttonDisabled = false; });
    };
    PrevisionsComponent.prototype.commissionChanged = function () {
        this.loadCreditCollectif();
    };
    PrevisionsComponent.prototype.compareFonctions = function (o1, o2) {
        if (!o1 || !o2) {
            return !o1 && !o2;
        }
        return (o1 === o2 || o1.id === o2.id);
    };
    return PrevisionsComponent;
}());
export { PrevisionsComponent };
