import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
// pour Data Picker
import {
  MAT_DATE_LOCALE,
  MatAutocompleteModule,
  MatButtonModule,
  MatDatepickerModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatRadioModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule
} from '@angular/material';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatGridListModule} from '@angular/material/grid-list';

import {AppComponent} from './app.component';
import {ErrorComponent, FooterComponent} from './layouts';
import {HeaderComponent} from './layouts/header/header.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HomeComponent} from './home/home.component';

import {ToastrModule} from 'ngx-toastr';

import {AppRoutingModule} from './app-routing.module';
import {AuthenticationService} from './services/authentication.service';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CustomHttpInterceptor} from './entities/user/customhttp.interceptor';
import {LoginComponent} from './login/login.component';

import {SaisieDesHeuresComponent} from './saisie-des-heures/saisie-des-heures.component';
// grid
import {FlexLayoutModule} from '@angular/flex-layout';
import {ListeDesHeuresComponent} from './liste-des-heures/liste-des-heures.component';
import {ExtractionContingentComponent} from './extraction-contingent/extraction-contingent.component';
/**
 * Import de la locale 'french'
 */
import {AppConfig} from './initializer/AppConfig';
import {DetailDebitComponent} from './detail-debit/detail-debit.component';
import {DetailDebitSupprDialogComponent} from './detail-debit/detail-debit-suppr-dialog/detail-debit-suppr-dialog.component';
import {TransfertPonctuelCreationComponent} from './transfert-ponctuel/transfert-ponctuel-creation/transfert-ponctuel-creation.component';
import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common';
import {ContactDialogComponent} from './layouts/contact-dialog/contact-dialog.component';
import {DonneesPersonDialogComponent} from './layouts/donnees-person-dialog/donnees-person-dialog.component';
import {RelationsSocialesComponent} from './organisations/relations-sociales/relations-sociales.component';
import {CseComponent} from './organisations/cse/cse.component';
import {OrganisationSyndicaleComponent} from './organisations/organisation-syndicale/organisation-syndicale.component';
import {PrevisionsComponent} from './organisations/cse/previsions/previsions.component';
import {PrevisionEditDialogComponent} from './organisations/cse/previsions/prevision-edit-dialog/prevision-edit-dialog.component';
// tslint:disable-next-line:max-line-length
import {PrevisionMassEditDialogComponent} from './organisations/cse/previsions/prevision-mass-edit-dialog/prevision-mass-edit-dialog.component';

import {BlockUIModule} from 'ng-block-ui';
import {BlockUIHttpModule} from 'ng-block-ui/http';
import {SpinnerComponent} from './layouts/blocker/spinner.component';
import {DebounceClickDirective} from './directive/debounceClick.directive';
import {AdministrationComponent} from './administration/administration.component';
import {ImpersonnationComponent} from './administration/impersonnation/impersonnation.component';
import {TransfertRecurrentCreationComponent} from './organisations/relations-sociales/transferts-recurrents/transfert-recurrent-creation/transfert-recurrent-creation.component';
import {MillisToHoursMinutesPipe, SecondsToHoursMinutesPipe} from './pipes/hours-minutes.pipe';
import {UserManagementComponent} from './user-management/user-management.component';
import {UserDetailsComponent} from './user-management/user-details/user-details.component';
import {MandatComponent} from './user-management/mandat/mandat.component';
import {MandatDetailsComponent} from './user-management/mandat-details/mandat-details.component';
import {TestTimezoneComponent} from './test-timezone/test-timezone.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {ContingentManagementComponent} from './user-management/contingent-management/contingent-management.component';
import {ContingentEditComponent} from './user-management/contingent-management/contingent-edit/contingent-edit.component';
import {ConfirmDialogComponent} from './layouts/confirm-dialog/confirm-dialog.component';
import {TransfertPonctuelManagementComponent} from './transfert-ponctuel/transfert-ponctuel-management/transfert-ponctuel-management.component';
import {TransfertPonctuelEditDialogComponent} from './transfert-ponctuel/transfert-ponctuel-edit-dialog/transfert-ponctuel-edit-dialog.component';
import {TransfertRecurrentManagementComponent} from './organisations/relations-sociales/transferts-recurrents/transfert-recurrent-management/transfert-recurrent-management.component';
import {TransfertRecurrentEditDialogComponent} from './organisations/relations-sociales/transferts-recurrents/transfert-recurrent-edit-dialog/transfert-recurrent-edit-dialog.component';
import {VersionCheckService} from './auto-reload/version-check.service';
import {DisableButtonOnSubmitDirective} from './directive/disable-button-on-submit.directive';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ContactDialogComponent,
    FooterComponent,
    ErrorComponent,
    HomeComponent,
    LoginComponent,
    SaisieDesHeuresComponent,
    ListeDesHeuresComponent,
    ExtractionContingentComponent,
    DetailDebitComponent,
    DetailDebitSupprDialogComponent,
    TransfertPonctuelCreationComponent,
    DonneesPersonDialogComponent,
    RelationsSocialesComponent,
    CseComponent,
    OrganisationSyndicaleComponent,
    PrevisionsComponent,
    PrevisionEditDialogComponent,
    PrevisionMassEditDialogComponent,
    SpinnerComponent,
    DebounceClickDirective,
    AdministrationComponent,
    ImpersonnationComponent,
    TransfertRecurrentCreationComponent,
    SecondsToHoursMinutesPipe,
    MillisToHoursMinutesPipe,
    UserManagementComponent,
    UserDetailsComponent,
    MandatComponent,
    MandatDetailsComponent,
    TestTimezoneComponent,
    ContingentManagementComponent,
    ContingentEditComponent,
    TransfertPonctuelManagementComponent,
    TransfertPonctuelEditDialogComponent,
    TransfertRecurrentManagementComponent,
    TransfertRecurrentEditDialogComponent,
    ConfirmDialogComponent,
    DisableButtonOnSubmitDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    MatGridListModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 7000
    }),
    AppRoutingModule,
    HttpClientModule,
    MatDatepickerModule, MatRadioModule, MatSelectModule, MatDialogModule, MatTooltipModule, MatAutocompleteModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    FlexLayoutModule,
    MatListModule, MatTableModule, MatSortModule, MatPaginatorModule, ReactiveFormsModule,
    BlockUIModule.forRoot(({
      delayStart: 2000,
      delayStop: 2000
    })),
    BlockUIHttpModule.forRoot(),
    MatMomentDateModule
  ],
  entryComponents: [
    DetailDebitSupprDialogComponent,
    ContactDialogComponent,
    DonneesPersonDialogComponent,
    PrevisionEditDialogComponent,
    PrevisionMassEditDialogComponent,
    MandatDetailsComponent,
    ContingentEditComponent,
    SpinnerComponent,
    TransfertPonctuelEditDialogComponent,
    TransfertRecurrentEditDialogComponent,
    TransfertRecurrentCreationComponent,
    ConfirmDialogComponent
  ],
  providers: [
    VersionCheckService,
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'fr'
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr'
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {useUtc: true}
    },
    [ // Chargement de la configuration au démarrage de l'appli (config json + messages du backend)
      AppConfig,
      {
        provide: APP_INITIALIZER,
        useFactory: (appConfig: AppConfig) => () => appConfig.getSettings(),
        deps: [AppConfig],
        multi: true
      }
    ]
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

