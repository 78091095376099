import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../services/authentication.service';
import {Idecodedtoken} from '../entities/user/Idecodedtoken';
import {ActuatorService} from '../entities/actuator/actuator.service';
import {AppConfig} from '../initializer/AppConfig';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  /**
   * Titre injecté dans la page
   */
  pageTitle = 'Accueil';

  constructor(private readonly actuatorService: ActuatorService,
              public toastr: ToastrService,
              private readonly router: Router,
              public authenticationService: AuthenticationService,
  ) {
  }

  /**
   * verif connection ok sinon goto login
   */
  ngOnInit(): void {
    if (!this.tokenObject) {
      this.router.navigate(['/login']);
    }
  }

  /**
   * Accès aux textes/messages paramétrés
   * @param key
   */
  msg(key) {
    return AppConfig.msg(key);
  }

  get tokenObject(): Idecodedtoken {
    return AuthenticationService.tokenObject();
  }

  saisieDesHeures() {
    this.router.navigate(['saisieDesHeures']);
  }

  listeDesHeures() {
    this.router.navigate(['listeDesHeures']);
  }

  extractionContingent() {
    this.router.navigate(['extractionContingent']);
  }

  transfertHeures() {
    this.router.navigate(['transfertHeures']);
  }

  transfertHeuresManagement() {
    this.router.navigate(['transfertManagement']);
  }

  goToRelationsSociales() {
    this.router.navigate(['relationsSociales']);
  }

  goToCse() {
    this.router.navigate(['cse']);
  }

  goToOS() {
    this.router.navigate(['organisationSyndicale']);
  }


}
