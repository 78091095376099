<div class="container"
     fxLayout="column"
     fxLayout.xs="column"
     fxLayoutAlign="center center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0">


  <div class="titrePage" fxFlex>Transferts récurrents</div>
  <div>

    <form autocomplete="off" #saisieForm="ngForm">
      <mat-dialog-content fxLayout="column">
        <!-- <mat-form-field>
           <mat-label>Elu donneur</mat-label>
           <mat-select name="mandatSourceSelect"
                       [(ngModel)]='transfertSource'
                       (selectionChange)="transfertSourceChanged()"
                       required>
             <mat-option *ngFor="let mandat of possibleTransfertSources"
                         [value]="mandat">
               {{mandat.name}} {{mandat.firstName}} {{mandat.functionLabel}}
             </mat-option>
           </mat-select>
         </mat-form-field>-->

        <div class="list-transfert">
          <mat-selection-list [(ngModel)]='selectedCredits' name="credits" (selectionChange)="updateButtonStatus()">
            <mat-list-option *ngFor="let credit of transferableCredits" [value]="credit">
              {{credit.effectDate|date:'LLL yyyy'|titlecase }} restant {{credit.remaining|millisToHoursMinutes}}
            </mat-list-option>
          </mat-selection-list>
        </div>

        <mat-form-field>
          <mat-label>Elu receveur</mat-label>
          <mat-select name="mandatTargetSelect"
                      [(ngModel)]='transfertTarget'
                      (selectionChange)="updateButtonStatus()"
                      required>
            <mat-option *ngFor="let mandat of possibleTransfertTargets"
                        [value]="mandat">
              {{mandat.name}} {{mandat.firstName}} {{mandat.functionLabel}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-label>{{msg('transfert.choixMontantTransfert')}} (max {{getMaximumTransfert()|millisToHoursMinutes}})
        </mat-label>
        <div fxLayout="row">

          <mat-form-field style="width:100px">
            <mat-label>{{msg('transfert.transfertHeures')}}</mat-label>
            <input matInput name="hoursToTranfert"
                   [(ngModel)]='hoursToTranfert'
                   (change)="updateButtonStatus()"
                   class="number-right"
                   inputmode="numeric"
                   type="number"
                   min="0"
                   required/>
          </mat-form-field>
          <mat-form-field style="width:100px">
            <mat-label>{{msg('transfert.transfertMinutes')}}</mat-label>
            <input matInput name="minutesToTranfert"
                   [(ngModel)]='minutesToTranfert'
                   (change)="updateButtonStatus()"

                   class="number-right"
                   type="number"
                   inputmode="numeric"
                   min="0"
                   max="59"
                   required/>
          </mat-form-field>

        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button class="mat-raised-button" (click)="close()">Annuler</button>
        <button n-submit mat-raised-button type="submit"
                class="app-item" (click)="submitTransfert()"
                [disabled]="buttonDisabled">
          Valider
        </button>
      </mat-dialog-actions>
    </form>

  </div>
</div>







