import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfig } from '../initializer/AppConfig';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
    }
    UserService.prototype.getAdministrableUsers = function () {
        return this.http.get(AppConfig.settings.api.usersUrl + "/administrableUsers");
    };
    UserService.prototype.getFilteredAdministrableUsers = function (searchedPattern) {
        var opts = { params: new HttpParams({ fromString: "searchedPattern=" + searchedPattern }) };
        return this.http.get(AppConfig.settings.api.usersUrl + "/filteredAdministrableUsers", opts);
    };
    UserService.prototype.getUsersMatching = function (namePattern) {
        return this.http.get(AppConfig.settings.api.usersUrl + "/getUsersMatching?namePattern=" + namePattern);
    };
    UserService.prototype.find = function (matricule) {
        return this.http.get(AppConfig.settings.api.usersUrl + "/" + matricule, { observe: 'response' });
    };
    UserService.prototype.create = function (user) {
        return this.http.post(AppConfig.settings.api.usersUrl + "/" + user.matricule, user, { observe: 'response' });
    };
    UserService.prototype.update = function (user) {
        return this.http.put(AppConfig.settings.api.usersUrl + "/" + user.matricule, user, { observe: 'response' });
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
