import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../initializer/AppConfig';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ExtractionContingentService = /** @class */ (function () {
    function ExtractionContingentService(http) {
        this.http = http;
    }
    ExtractionContingentService.prototype.downloadExtraction = function () {
        // const headers = new HttpHeaders({
        //   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        // });
        // return this.http.get(EXTRACT_URL + '/' + filename,{
        return this.http.get(AppConfig.settings.api.extractionUrl + '/excelExtract', {
            //  headers: headers,
            observe: 'response',
            responseType: 'blob'
        });
    };
    ExtractionContingentService.prototype.downloadExtractionOS = function (year) {
        // const headers = new HttpHeaders({
        //   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        // });
        // return this.http.get(EXTRACT_URL + '/' + filename,{
        return this.http.get(AppConfig.settings.api.extractionUrl + '/excelExtractOS?year=' + year, {
            //  headers: headers,
            observe: 'response',
            responseType: 'blob'
        });
    };
    ExtractionContingentService.prototype.downloadExtractionCSE = function (year) {
        // const headers = new HttpHeaders({
        //   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        // });
        // return this.http.get(EXTRACT_URL + '/' + filename,{
        return this.http.get(AppConfig.settings.api.extractionUrl + '/excelExtractCSE?year=' + year, {
            //  headers: headers,
            observe: 'response',
            responseType: 'blob'
        });
    };
    ExtractionContingentService.prototype.downloadExtractionEtablissement = function (year) {
        // const headers = new HttpHeaders({
        //   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        // });
        // return this.http.get(EXTRACT_URL + '/' + filename,{
        return this.http.get(AppConfig.settings.api.extractionUrl + '/excelExtractEtablissement?year=' + year, {
            //  headers: headers,
            observe: 'response',
            responseType: 'blob'
        });
    };
    ExtractionContingentService.prototype.downloadExtractionUser = function (year, user) {
        // const headers = new HttpHeaders({
        //   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        // });
        // return this.http.get(EXTRACT_URL + '/' + filename,{
        return this.http.get(AppConfig.settings.api.extractionUrl + '/excelExtract?year=' + year + '&michelinId=' + user, {
            //  headers: headers,
            observe: 'response',
            responseType: 'blob'
        });
    };
    ExtractionContingentService.prototype.downloadExtractionMandtatsFormations = function (year) {
        // const headers = new HttpHeaders({
        //   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        // });
        // return this.http.get(EXTRACT_URL + '/' + filename,{
        return this.http.get(AppConfig.settings.api.extractionUrl + '/excelExtractMandatsFormations?year=' + year, {
            //  headers: headers,
            observe: 'response',
            responseType: 'blob'
        });
    };
    ExtractionContingentService.prototype.downloadExtractionUsage = function () {
        // const header = new HttpHeaders({
        //   'Content-Type': 'application/vnd.openxmlfomats-officedocument.spreadsheetml.sheet'
        // });
        return this.http.get(AppConfig.settings.api.extractionUrl + '/excelExtractTauxAppli', {
            observe: 'response',
            responseType: 'blob'
        });
    };
    ExtractionContingentService.prototype.availableYears = function () {
        return this.http.get(AppConfig.settings.api.extractionUrl + '/availableYears');
    };
    ExtractionContingentService.ngInjectableDef = i0.defineInjectable({ factory: function ExtractionContingentService_Factory() { return new ExtractionContingentService(i0.inject(i1.HttpClient)); }, token: ExtractionContingentService, providedIn: "root" });
    return ExtractionContingentService;
}());
export { ExtractionContingentService };
