import {Component, OnInit} from '@angular/core';
import {ExtractionContingentService} from '../../services/extraction-contingent.service';
import {AppConfig} from '../../initializer/AppConfig';
import * as fileSaver from 'file-saver';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
import {IUser} from '../../entities/user/user.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {debounceTime, filter, switchMap} from 'rxjs/operators';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-relations-sociales',
  templateUrl: './relations-sociales.component.html',
  styleUrls: ['./relations-sociales.component.scss']
})
export class RelationsSocialesComponent implements OnInit {

  fileSytemName = 'data';
  selectedYearForAllRS: number;
  minYear: number;
  maxYear: number;
  selectedYearForMandatsFormations: number;
  selectForm: FormGroup;
  selectedUser: IUser;
  filteredUsers: IUser[] = [];

  /**
   * Titre injecté dans la page
   */
  pageTitle = this.msg('relationsSociales.title');

  /**
   * Accès aux textes/messages paramétrés
   * @param key
   */
  msg(key) {
    return AppConfig.msg(key);
  }

  constructor(private extractionContingent: ExtractionContingentService,
              public authenticationService: AuthenticationService,
              private readonly userService: UserService,
              private readonly fb: FormBuilder,
              public router: Router) {
  }

  ngOnInit(): void {
    this.extractionContingent.availableYears().subscribe(availableYears => {
        this.selectedYearForAllRS = (new Date()).getFullYear();
        this.selectedYearForMandatsFormations = this.selectedYearForAllRS;
        if (availableYears) {
          this.maxYear = availableYears[0];
          this.minYear = availableYears[availableYears.length - 1];
        } else {
          this.minYear = this.selectedYearForAllRS;
          this.maxYear = this.selectedYearForAllRS;
        }
      }
    );
    this.selectForm = this.fb.group({
      userInput: null
    });
    this.selectForm.get('userInput').valueChanges.pipe(
      filter(value => value.length >= 2),
      debounceTime(500),
      switchMap(value => this.userService.getFilteredAdministrableUsers(value)
      )
    ).subscribe(users => this.filteredUsers = users);
  }

  extractionUsage() {
    this.extractionContingent.downloadExtractionUsage()
      .subscribe(response => {
        const contentDisposition = response.headers.get('content-disposition');
        const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        this.saveFile(response.body, filename);
      });
  }

  extractionActivity() {
    if (this.selectedUser != null && this.selectedUser.michelinId != null) {
      // extraction of selected user
      this.extractionContingent.downloadExtractionUser(this.selectedYearForAllRS, this.selectedUser.michelinId)
        .subscribe(response => {
          const contentDisposition = response.headers.get('content-disposition');
          const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          this.saveFile(response.body, filename);
        });
    } else {
      // extraction etablissement
      this.extractionContingent.downloadExtractionEtablissement(this.selectedYearForAllRS)
        .subscribe(response => {
          const contentDisposition = response.headers.get('content-disposition');
          const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          this.saveFile(response.body, filename);
        });
    }
  }


  extractionMandatsFormations() {
    this.extractionContingent.downloadExtractionMandtatsFormations(this.selectedYearForMandatsFormations)
      .subscribe(response => {
        const contentDisposition = response.headers.get('content-disposition');
        const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        this.saveFile(response.body, filename);
      });
  }


  private saveFile(data: any, filename?: string) {
    const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    fileSaver.saveAs(blob, filename);

  }

  displayFn(user: IUser): string {
    return user ? `${user.prenom} ${user.nom}` : '';
  }

  selectUser(): void {
    if (this.selectForm.get('userInput').value && this.selectForm.get('userInput').value.michelinId) {
      this.selectedUser = this.selectForm.get('userInput').value;
    } else {
      this.selectedUser = null;
    }
  }
}
