import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppConfig } from '../initializer/AppConfig';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "ngx-toastr";
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, router, toastr) {
        this.http = http;
        this.router = router;
        this.toastr = toastr;
        this.loginError = false;
    }
    AuthenticationService.setToken = function (token) {
        localStorage.setItem(AuthenticationService.tokenItemName, token);
    };
    AuthenticationService.getToken = function () {
        return localStorage.getItem(AuthenticationService.tokenItemName);
    };
    AuthenticationService.getTokenObject = function () {
        return AuthenticationService.jwtHelperService.decodeToken(AuthenticationService.getToken());
    };
    AuthenticationService.removeToken = function () {
        localStorage.removeItem(AuthenticationService.tokenItemName);
    };
    AuthenticationService.tokenObject = function () {
        return AuthenticationService.getTokenObject();
    };
    AuthenticationService.getRoles = function () {
        return AuthenticationService.getTokenObject().auth.split(',');
    };
    AuthenticationService.setTokenSave = function (tokenSave) {
        localStorage.setItem(AuthenticationService.tokenSaveItemName, tokenSave);
    };
    AuthenticationService.getTokenSave = function () {
        return localStorage.getItem(AuthenticationService.tokenSaveItemName);
    };
    AuthenticationService.removeTokenSave = function () {
        localStorage.removeItem(AuthenticationService.tokenSaveItemName);
    };
    AuthenticationService.isImpersonation = function () {
        return this.getTokenSave() != null;
    };
    AuthenticationService.isActuator = function () {
        return AuthenticationService.getRoles().includes('ROLE_ACTUATOR');
    };
    AuthenticationService.prototype.isAdmin = function () {
        return AuthenticationService.getRoles().includes('ROLE_ADMIN');
    };
    AuthenticationService.prototype.hasAnyAuthority = function (authorities) {
        if (!this.isAuthenticated() || !AuthenticationService.getToken() || !AuthenticationService.getRoles()) {
            return false;
        }
        for (var _i = 0, authorities_1 = authorities; _i < authorities_1.length; _i++) {
            var authority = authorities_1[_i];
            if (AuthenticationService.getRoles().includes(authority)) {
                return true;
            }
        }
        return false;
    };
    /**
     * Accès aux textes/messages paramétrés
     * @param key
     */
    AuthenticationService.prototype.msg = function (key) {
        return AppConfig.msg(key);
    };
    /**
     * //Can't inject viewContainer into Authentication service so have to set it from Component Class
     * @param viewContainerRef
     */
    AuthenticationService.prototype.setRootViewContainerRef = function (viewContainerRef) {
        this.rootViewContainer = viewContainerRef;
    };
    AuthenticationService.prototype.login = function (login, password, rememberMe) {
        var _this = this;
        var credentials = {};
        credentials.login = login;
        credentials.password = password;
        credentials.rememberMe = rememberMe;
        this.http.post(AppConfig.settings.api.loginUrl.toString(), credentials, httpOptions).subscribe(function (data) {
            _this.loginError = false;
            if (data) {
                // Store Current User in localStorage to workaround F5 issue
                AuthenticationService.setToken(data.token);
                _this.router.navigate(['/home']);
            }
        }, function (err) {
            AuthenticationService.removeToken();
            _this.loginError = true;
            if (err.error instanceof Error) {
                // A client-side or network error occurred. Handle it accordingly.
                console.error('An error occurred:', err.error.message);
            }
            else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                console.error("Backend returned code " + err.status + ", body was: " + err.error);
            }
            _this.toastr.error(_this.msg('login.error'), _this.msg('lib.Error'));
        });
    };
    AuthenticationService.prototype.validateToken = function () {
        return this.http
            .get(AppConfig.settings.api.validateTokenUrl.toString());
    };
    AuthenticationService.prototype.isAuthenticated = function () {
        return !!AuthenticationService.getToken();
    };
    /**
     * Cleanup localStorage and redirect to login page
     */
    AuthenticationService.prototype.logout = function () {
        AuthenticationService.removeToken();
        AuthenticationService.removeTokenSave();
        this.router.navigate(['/login']);
    };
    AuthenticationService.prototype.hasOrganisation = function () {
        return this.isRelationSociale() || this.isSecretaireCSE() || this.isDelegueCentralOS();
    };
    AuthenticationService.prototype.isSecretaireCSE = function () {
        return this.hasAnyAuthority(['ROLE_Secrétaire', 'ROLE_SecrétaireCfe', 'ROLE_SecrétaireCsec', 'ROLE_ADMINCSE']);
    };
    AuthenticationService.prototype.isDelegueCentralOS = function () {
        return this.hasAnyAuthority(['ROLE_Dsc']);
    };
    AuthenticationService.prototype.isRelationSociale = function () {
        return this.hasAnyAuthority(['ROLE_RS_SITE', 'ROLE_RS_CENTRAL']);
    };
    AuthenticationService.prototype.isUserManager = function () {
        return this.hasAnyAuthority(['ROLE_ADM_USER_LST']);
    };
    AuthenticationService.prototype.doImpersonation = function (token) {
        AuthenticationService.setTokenSave(AuthenticationService.getToken());
        AuthenticationService.setToken(token);
        this.toastr.info("You are now impersonating " + AuthenticationService.getTokenObject().firstName + " "
            + (AuthenticationService.getTokenObject().lastName + ". Please wait..."));
    };
    AuthenticationService.prototype.isImpersonation = function () {
        return !!AuthenticationService.getTokenSave();
    };
    AuthenticationService.prototype.stopImpersonation = function () {
        AuthenticationService.setToken(AuthenticationService.getTokenSave());
        AuthenticationService.removeTokenSave();
        this.toastr.info("Welcome back " + AuthenticationService.getTokenObject().firstName + " " + AuthenticationService.getTokenObject().lastName + " ! Please wait...");
        setTimeout(function () { return location.reload(); }, 2000);
    };
    /**
     * Impersonate a user
     *
     * @param username The username of the user that we want to impersonate
     */
    AuthenticationService.prototype.getImpersonateToken = function (username) {
        var url = AppConfig.settings.api.usersUrl + "/" + username + "/impersonate";
        return this.http.post(url, null);
    };
    AuthenticationService.tokenItemName = 'token';
    AuthenticationService.tokenSaveItemName = 'token_save';
    AuthenticationService.jwtHelperService = new JwtHelperService();
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.inject(i1.HttpClient), i0.inject(i2.Router), i0.inject(i3.ToastrService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
