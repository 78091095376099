import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IEtablissment} from '../entities/user/etatblissment.model';
import {AppConfig} from '../initializer/AppConfig';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EtablissmentService {

  constructor(private readonly http: HttpClient) {
  }

  getAssignableEtablissments(): Observable<IEtablissment[]> {
    return this.http.get<IEtablissment[]>(`${AppConfig.settings.api.adminUrl}/assignableEtablissements/`);
  }

}
