import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SaisieDesHeuresComponent } from './saisie-des-heures/saisie-des-heures.component';
import { UserRouteAccessService } from './services/user-route-access-service';
import { errorRoute } from './layouts';
import { ListeDesHeuresComponent } from './liste-des-heures/liste-des-heures.component';
import { ExtractionContingentComponent } from './extraction-contingent/extraction-contingent.component';
import { TransfertPonctuelCreationComponent } from './transfert-ponctuel/transfert-ponctuel-creation/transfert-ponctuel-creation.component';
import { RelationsSocialesComponent } from './organisations/relations-sociales/relations-sociales.component';
import { CseComponent } from './organisations/cse/cse.component';
import { OrganisationSyndicaleComponent } from './organisations/organisation-syndicale/organisation-syndicale.component';
import { PrevisionsComponent } from './organisations/cse/previsions/previsions.component';
import { AdministrationComponent } from './administration/administration.component';
import { TransfertRecurrentCreationComponent } from './organisations/relations-sociales/transferts-recurrents/transfert-recurrent-creation/transfert-recurrent-creation.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { userManagementRoute } from './user-management/user-management.route';
import { TestTimezoneComponent } from './test-timezone/test-timezone.component';
import { TransfertPonctuelManagementComponent } from './transfert-ponctuel/transfert-ponctuel-management/transfert-ponctuel-management.component';
import { TransfertRecurrentManagementComponent } from './organisations/relations-sociales/transferts-recurrents/transfert-recurrent-management/transfert-recurrent-management.component';
var ɵ0 = {
    authorities: []
}, ɵ1 = {
    authorities: ['ROLE_SAISIE_TEMPS_RW']
}, ɵ2 = {
    authorities: ['ROLE_SAISIE_TEMPS_RW']
}, ɵ3 = {
    authorities: ['ROLE_SAISIE_TEMPS_RO']
}, ɵ4 = {
    authorities: ['ROLE_SAISIE_TEMPS_RO']
}, ɵ5 = {
    authorities: ['ROLE_EXTRACT_ETAB', 'ROLE_EXTRACT_FORM', 'ROLE_EXTRACT_TAUX', 'ROLE_TRANSFERTS_ALL']
}, ɵ6 = {
    authorities: ['ROLE_Secrétaire', 'ROLE_SecrétaireCfe', 'ROLE_SecrétaireCsec', 'ROLE_ADMINCSE']
}, ɵ7 = {
    authorities: ['ROLE_Dsc']
}, ɵ8 = {
    authorities: ['ROLE_SAISIE_TEMPS_RW', 'ROLE_TRANSFERTS_ALL']
}, ɵ9 = {
    authorities: ['ROLE_SAISIE_TEMPS_RW', 'ROLE_TRANSFERTS_ALL']
}, ɵ10 = {
    authorities: ['ROLE_Secrétaire', 'ROLE_SecrétaireCfe', 'ROLE_SecrétaireCsec', 'ROLE_ADMINCSE']
}, ɵ11 = {
    authorities: ['ROLE_ADMIN']
}, ɵ12 = {
    authorities: ['ROLE_TRANSFERTS_ALL']
}, ɵ13 = {
    authorities: ['ROLE_TRANSFERTS_ALL']
}, ɵ14 = {
    authorities: ['ROLE_ADM_USER_LST', 'ROLE_ADM_USER_RO', 'ROLE_ADM_USER_RW']
}, ɵ15 = {
    authorities: ['ROLE_ADMIN']
};
var routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [UserRouteAccessService],
        data: ɵ0
    },
    {
        path: 'saisieDesHeures/:debitId',
        component: SaisieDesHeuresComponent,
        canActivate: [UserRouteAccessService],
        data: ɵ1
    },
    {
        path: 'saisieDesHeures',
        component: SaisieDesHeuresComponent,
        canActivate: [UserRouteAccessService],
        data: ɵ2
    },
    {
        path: 'listeDesHeures',
        component: ListeDesHeuresComponent,
        canActivate: [UserRouteAccessService],
        data: ɵ3
    },
    {
        path: 'extractionContingent',
        component: ExtractionContingentComponent,
        canActivate: [UserRouteAccessService],
        data: ɵ4
    },
    {
        path: 'relationsSociales',
        component: RelationsSocialesComponent,
        canActivate: [UserRouteAccessService],
        data: ɵ5
    },
    {
        path: 'cse',
        component: CseComponent,
        canActivate: [UserRouteAccessService],
        data: ɵ6
    },
    {
        path: 'organisationSyndicale',
        component: OrganisationSyndicaleComponent,
        canActivate: [UserRouteAccessService],
        data: ɵ7
    },
    {
        path: 'transfertHeures',
        component: TransfertPonctuelCreationComponent,
        canActivate: [UserRouteAccessService],
        data: ɵ8
    },
    {
        path: 'transfertManagement',
        component: TransfertPonctuelManagementComponent,
        canActivate: [UserRouteAccessService],
        data: ɵ9
    },
    {
        path: 'gestion-previsions',
        component: PrevisionsComponent,
        canActivate: [UserRouteAccessService],
        data: ɵ10
    },
    {
        path: 'administration',
        component: AdministrationComponent,
        canActivate: [UserRouteAccessService],
        data: ɵ11
    },
    {
        path: 'relations-sociales/transfertRecurrent',
        component: TransfertRecurrentManagementComponent,
        canActivate: [UserRouteAccessService],
        data: ɵ12
    },
    {
        path: 'relations-sociales/transfertRecurrent/create',
        component: TransfertRecurrentCreationComponent,
        canActivate: [UserRouteAccessService],
        data: ɵ13
    },
    {
        path: 'users',
        component: UserManagementComponent,
        children: userManagementRoute,
        canActivate: [UserRouteAccessService],
        data: ɵ14
    },
    {
        path: 'test-timezone',
        component: TestTimezoneComponent,
        canActivate: [UserRouteAccessService],
        data: ɵ15
    }
].concat(errorRoute);
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15 };
