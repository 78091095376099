import {Component, OnInit, ViewChild} from '@angular/core';
import {MandatService} from '../../services/mandat.service';
import {IMandat} from '../../entities/previsions/mandat.model';
import {User} from '../../entities/user/user.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatDialogConfig, MatSort, MatTableDataSource} from '@angular/material';
import {MandatDetailsComponent} from '../mandat-details/mandat-details.component';
import {AuthenticationService} from '../../services/authentication.service';


@Component({
  selector: 'app-mandat',
  templateUrl: './mandat.component.html',
  styleUrls: ['./mandat.component.scss']
})
export class MandatComponent implements OnInit {


  mandats: IMandat[];
  user: User;
  mandatsDataSource = new MatTableDataSource<IMandat>();

  @ViewChild(MatSort) sort: MatSort;

  constructor(private readonly mandatService: MandatService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly dialog: MatDialog,
              public readonly authenticationService: AuthenticationService,
              public readonly router: Router) {
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({user}) => {
        this.user = user;
        this.loadMandats();
      }
    );
  }

  public loadMandats() {
    this.mandatService.getMandatsByUser(this.user.matricule).subscribe(mandats => {
        this.mandats = mandats;
        this.mandatsDataSource = new MatTableDataSource<IMandat>(this.mandats);
        this.mandatsDataSource.sort = this.sort;
        this.mandatsDataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
          return this.getPropertyByPath(data, sortHeaderId);
        };
      }
    );
  }

  modify(mandat: IMandat) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {mandat};
    const dialogRef = this.dialog.open(MandatDetailsComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(
      val => this.loadMandats());
  }


  create() {
    const mandat: IMandat = {matricule: this.user.matricule};
    this.modify(mandat);
  }


  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  public canModifyFonctionSensible(): boolean {
    return this.authenticationService.hasAnyAuthority(['ROLE_ADM_FN_SENSIBLE']);
  }


  showContingents(mandat: IMandat) {
    this.router.navigate(['../mandat', mandat.id, 'contingents'], {relativeTo: this.activatedRoute});

  }
}
