import { OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DateAdapter } from '@angular/material';
import { AuthenticationService } from '../services/authentication.service';
import { GestionDesHeuresService } from '../services/gestion-des-heures.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '../initializer/AppConfig';
import * as moment from 'moment';
import 'moment-duration-format';
import { String } from 'typescript-string-operations';
import { UserService } from '../services/user.service';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
export var APP_DATE_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
/**
 * Déclaration du component
 */
var SaisieDesHeuresComponent = /** @class */ (function () {
    /**
     * Constructeur, avec injection du service de gestion des heures, le toaster
     * + router & location pour la navigation
     * @param gestionDesHeuresService
     * @param toastr
     * @param router
     * @param location
     */
    function SaisieDesHeuresComponent(gestionDesHeuresService, toastr, router, route, location, dateAdapter, authenticationService, userService) {
        this.gestionDesHeuresService = gestionDesHeuresService;
        this.toastr = toastr;
        this.router = router;
        this.route = route;
        this.location = location;
        this.dateAdapter = dateAdapter;
        this.authenticationService = authenticationService;
        this.userService = userService;
        /**
         * Flag de validation de la saisie des heures
         */
        this.saisieCorrecteHeures = false;
        /**
         * Liste des activités = contingents retournés par le backend
         */
        this.contingents = [];
        /**
         * Titre injecté dans la page
         */
        this.pageTitle = this.msg('saisieHeures.title');
        // commentaire dans saisie
        this.saisieCommentaire = '';
        /**
         * taille maxi des commentaires
         */
        this.tailleSaisieCommentaireMax = 140;
        /**
         * defini si mode saisie creation ou modification
         */
        this.creation = true;
        /**
         * util en mode modification de saisie, pour supprimer debit avant de creer un autre debit
         */
        this.debitASupprimer = false;
        /**
         * date du jour
         */
        this.today = Date.now();
        this.filteredUsers = [];
        this.userInput = new FormControl();
        // types heure collectifs (pour masquer la saisie max
        this.typesHeuresCollectifs = [3, 4];
        this.submitInProgress = false;
    }
    Object.defineProperty(SaisieDesHeuresComponent.prototype, "enabled", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    SaisieDesHeuresComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dateAdapter.setLocale('fr');
        this.dateAdapter.getFirstDayOfWeek = function () { return 1; };
        this.toggleContingent = true;
        this.route.params.subscribe(function (params) {
            if (params.debitId) {
                _this.creation = false;
                _this.modif(params.debitId);
            }
            else {
                _this.creation = true;
                _this.debitModif = null;
            }
        });
        if (this.accessUserList()) {
            if (this.creation) {
                this.userInput
                    .valueChanges
                    .pipe(filter(function (value) { return value.length >= 2; }), debounceTime(300), switchMap(function (value) { return _this.userService.getFilteredAdministrableUsers(value); })).subscribe(function (users) { return _this.filteredUsers = users; });
            }
            this.horizonSaisieFutur = new Date();
        }
        else {
            this.selectedMichelinId = AuthenticationService.getTokenObject().sub;
            this.horizonSaisieFutur = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        }
        this.gestionDesHeuresService.getHorizonSaisie().subscribe(function (LatestDatePaye) {
            _this.horizonSaisie = LatestDatePaye;
        });
    };
    SaisieDesHeuresComponent.prototype.modif = function (debitId) {
        var _this = this;
        this.gestionDesHeuresService.getDebit(debitId).subscribe(function (debit) {
            console.debug(debitId);
            _this.successModifSubscribe(debit);
        }, function (err) {
            console.error(err + ' : pb reseau');
        });
    };
    SaisieDesHeuresComponent.prototype.successModifSubscribe = function (debit) {
        var _this = this;
        var dateActivite = moment(debit.dateDebit, 'YYYY-MM-DD').toDate();
        this.debitModif = debit;
        this.selectedMichelinId = debit.michelinId;
        this.pageTitle = this.msg('saisieHeures.title.modif');
        this.date = debit.dateDebit;
        this.timeBeginInput = debit.heureDebut;
        this.timeEndInput = moment(debit.dateDebit + ' ' + debit.heureDebut).add(debit.duree, 'milliseconds').format('HH:mm:ss');
        this.saisieCommentaire = debit.commentaire;
        var contingentTrouve = false;
        this.userInput.disable();
        this.gestionDesHeuresService.getActivitesByDate(this.selectedMichelinId, dateActivite).subscribe(function (contingentList) {
            // recupere le contingent a modifier
            for (var _i = 0, contingentList_1 = contingentList; _i < contingentList_1.length; _i++) {
                var cl = contingentList_1[_i];
                if (cl.mandatId === debit.mandatCseId && cl.typeHeureId === debit.typeHeureId) {
                    _this.contingents = [];
                    _this.contingents.push(cl);
                    _this.selectedActivity = cl;
                    contingentTrouve = true;
                    break;
                }
            }
            if (contingentTrouve === false) {
                console.error('--------------pb------contingent dans successModifSubscribe');
                _this.toastr.error(_this.msg('lib.Error'));
            }
            // Gestion du cas des heures hors contingent avec un magic number :-/
            if (_this.selectedActivity.solde != null && !_this.typesHeuresCollectifs.includes(_this.selectedActivity.typeHeureId)) {
                _this.rabDebitModif = _this.debitModif.duree;
                console.debug('Rab de modif ' + _this.rabDebitModif);
                console.debug('this.selectedActivity.saisieMax ' + _this.selectedActivity.saisieMax);
                _this.selectedActivity.saisieMax = +_this.selectedActivity.saisieMax + +_this.rabDebitModif;
                console.debug('this.selectedActivity.saisieMax ' + _this.selectedActivity.saisieMax);
                _this.modifMaxSaisieAffiche = moment.duration(_this.selectedActivity.saisieMax, 'millisecond')
                    .format('h[h]mm[m]', { userLocale: 'fr' }) + 'in';
                console.debug(moment.duration(_this.selectedActivity.saisieMax, 'millisecond').format('hh:mm'));
            }
            _this.changeHeure();
            _this.debitASupprimer = true;
            _this.toggleContingent = false;
        }, function (err) {
            _this.errorContingent(err);
        });
    };
    /**
     * Accès aux textes/messages paramétrés
     * @param key
     */
    SaisieDesHeuresComponent.prototype.msg = function (key) {
        return AppConfig.msg(key);
    };
    Object.defineProperty(SaisieDesHeuresComponent.prototype, "tokenObject", {
        /**
         * Récupérer le token d'authent courant
         */
        get: function () {
            //console.debug('token: ' + AuthenticationService.tokenObject());
            return AuthenticationService.tokenObject();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Noter l'heure de fin saisie et de début saisie,, sous forme de duration
     * + Valider que la saisie est correct
     */
    SaisieDesHeuresComponent.prototype.changeHeure = function () {
        this.timeBegin = this.timeBeginInput ? moment.duration(moment.duration(this.timeBeginInput)) : undefined;
        this.timeEnd = this.timeEndInput ? moment.duration(moment.duration(this.timeEndInput)) : undefined;
        if (!this.timeEnd && this.selectedActivity.saisieDefaut) {
            this.timeEnd = this.timeBegin.clone();
            this.timeEnd.add(moment.duration(this.selectedActivity.saisieDefaut, 'millisecond'));
            this.timeEndInput = this.timeEnd.format('HH:mm', { userLocale: 'fr' });
        }
        if (this.timeEnd !== undefined && this.timeBegin !== undefined) {
            this.calculDuree();
        }
    };
    SaisieDesHeuresComponent.prototype.calculDuree = function () {
        if (this.timeEnd !== undefined && this.timeBegin !== undefined) {
            console.debug('debut: ' + this.timeBegin + 'fin: ' + this.timeEnd);
            var difference = this.timeEnd.subtract(this.timeBegin);
            this.duree = difference;
            this.saisieCorrecteHeures = this.verifHeureSaisieCorrecte(difference);
        }
    };
    /**
     * Vérifier que la durée indiquée est valide
     * @param duree
     */
    SaisieDesHeuresComponent.prototype.verifHeureSaisieCorrecte = function (duree) {
        if (!this.selectedActivity || duree == null) {
            return false;
        }
        var solde = moment.duration(this.selectedActivity.solde);
        var saisieMax = this.selectedActivity.saisieMax != null ? moment.duration(this.selectedActivity.saisieMax) : null;
        if (duree.asMilliseconds() > 0) {
            if (saisieMax != null && duree > saisieMax) {
                this.toastr.error(String.Format(this.msg('saisieHeures.err.saisieMax'), moment.duration(this.selectedActivity.saisieMax, 'millisecond').format('h[h]mm[m]', { userLocale: 'fr' }) + 'in'), this.msg('lib.Error'));
                return false;
            }
            else {
                return true;
            }
        }
        else {
            this.toastr.error(this.msg('saisieHeures.err.autre'), this.msg('lib.Error'));
            return false;
        }
    };
    /**
     * au clic 'Valider' supprime debit si mode modification, et appel methode enregistre le debit
     */
    SaisieDesHeuresComponent.prototype.action = function () {
        if (this.verifHeureSaisieCorrecte(this.duree)) {
            this.submitInProgress = true;
            if (!this.creation && this.debitASupprimer) {
                this.updateDebit();
            }
            else if (this.creation) {
                this.enregistrementDebit();
            }
        }
        else {
            this.toastr.error(this.msg('saisieHeures.err.saisieIncorrecte'), this.msg('lib.Error'));
        }
    };
    /**
     * Enregistrer le débit d'heure
     */
    SaisieDesHeuresComponent.prototype.enregistrementDebit = function () {
        var _this = this;
        this.gestionDesHeuresService.addDebit({
            dateDebit: this.date,
            duree: this.duree.asMilliseconds(),
            heureDebut: this.timeBeginInput,
            mandatCseId: this.selectedActivity.mandatId,
            typeHeureId: this.selectedActivity.typeHeureId,
            commentaire: this.saisieCommentaire,
            michelinId: this.selectedMichelinId
        }).subscribe(function (result) {
            _this.successSaisie(result);
        }, function (err) {
            _this.errorSaisie(err);
        });
    };
    SaisieDesHeuresComponent.prototype.updateDebit = function () {
        var _this = this;
        this.gestionDesHeuresService.updateDebit({
            debitId: this.debitModif.debitId,
            dateDebit: this.date,
            duree: this.duree.asMilliseconds(),
            heureDebut: this.timeBeginInput,
            mandatCseId: this.selectedActivity.mandatId,
            typeHeureId: this.selectedActivity.typeHeureId,
            commentaire: this.saisieCommentaire,
            michelinId: this.selectedMichelinId
        }).subscribe(function (result) {
            _this.successSaisie(result);
        }, function (err) {
            _this.errorSaisie(err);
        });
    };
    /**
     * Si le backend a bien enregistré la saisie...
     * @param result
     */
    SaisieDesHeuresComponent.prototype.successSaisie = function (result) {
        this.toastr.success(this.msg('saisieHeures.succ.saisie'), this.msg('lib.Success'));
        this.submitInProgress = false;
        this.router.navigate(['home']);
    };
    /**
     * Si le backend a rejeté la saisie...
     * @param err
     */
    SaisieDesHeuresComponent.prototype.errorSaisie = function (err) {
        this.toastr.error(this.msg('saisieHeures.err.prefix') + err.error.message, this.msg('lib.Error'));
        this.submitInProgress = false;
    };
    /**
     * Raffraichir les activités dispo selon la date en mode creation et
     * verifie que la date saisie est plus recente que la date de la derniere paye en mode modification
     * @param date
     */
    SaisieDesHeuresComponent.prototype.saisieDate = function (date) {
        var _this = this;
        var dateSaisie = date;
        if (this.creation) {
            this.date = date;
            this.contingents = [];
            this.toggleContingent = true;
            this.gestionDesHeuresService.getActivitesByDate(this.selectedMichelinId, date).subscribe(function (contingentList) {
                _this.successContingent(contingentList);
            }, function (err) {
                _this.errorContingent(err);
            });
        }
        else {
            if (moment(moment(dateSaisie).format('YYYY-MM-DD')).diff(moment(moment(this.today).format('YYYY-MM-DD'))) < 0) {
                //console.debug('avant');
                if (moment(moment(dateSaisie).format('YYYY-MM-DD')).diff(this.horizonSaisie) < 0) {
                    // console.debug('avant PB');
                    this.toastr.error(this.msg('saisieHeures.err.LatestDatePayeAfterSelectedDate')
                        + moment(this.horizonSaisie).format('DD-MM-YYYY'), this.msg('lib.Error'));
                }
            }
            this.gestionDesHeuresService.getActivitesByDate(this.selectedMichelinId, date).subscribe(function (contingentList) {
                for (var _i = 0, contingentList_2 = contingentList; _i < contingentList_2.length; _i++) {
                    var contingent = contingentList_2[_i];
                    if (contingent.mandatId === _this.selectedActivity.mandatId
                        && contingent.typeHeureId === _this.selectedActivity.typeHeureId) {
                        _this.selectedActivity = contingent;
                        if (contingent.solde != null) {
                            _this.selectedActivity.saisieMax = +contingent.saisieMax + +_this.rabDebitModif;
                            console.debug('this.selectedActivity.saisieMax : ' + _this.selectedActivity.saisieMax);
                            _this.modifMaxSaisieAffiche = moment.duration(_this.selectedActivity.saisieMax, 'millisecond')
                                .format('h[h]mm[m]', { userLocale: 'fr' }) + 'in';
                        }
                        break;
                    }
                }
                _this.changeHeure();
            }, function (err) {
                _this.errorContingent(err);
            });
        }
    };
    SaisieDesHeuresComponent.prototype.mandateChanged = function () {
        if (this.userInput.value && this.userInput.value.michelinId) {
            this.selectedUser = this.userInput.value;
            this.selectedMichelinId = this.selectedUser.michelinId;
        }
        else {
            this.selectedUser = null;
            this.selectedMichelinId = null;
        }
        this.date = null;
        this.selectedActivity = null;
        this.contingents = [];
    };
    /**
     * Le backend a bien retourné une liste de contingent (éventuellement vide)
     * @param contingentList
     */
    SaisieDesHeuresComponent.prototype.successContingent = function (contingentList) {
        console.debug('contingentList: ' + contingentList);
        if (contingentList.length === 0) {
            this.toastr.error(this.msg('saisieHeures.err.noCredit'), this.msg('lib.Error'));
        }
        else {
            for (var _i = 0, contingentList_3 = contingentList; _i < contingentList_3.length; _i++) {
                var contingent = contingentList_3[_i];
                console.debug('returned contingent.name: ' + contingent.libelleSaisie);
            }
            this.contingents = contingentList;
            for (var _a = 0, _b = this.contingents; _a < _b.length; _a++) {
                var contingent = _b[_a];
                if (contingent.saisieMax != null && !this.typesHeuresCollectifs.includes(contingent.typeHeureId)) {
                    contingent.libelleSaisie = contingent.libelleSaisie
                        + ' (disponible '
                        + moment.duration(contingent.saisieMax, 'millisecond').format('h[h]mm[m]', { userLocale: 'fr' })
                        + 'in)';
                    console.debug('contingent: ' + contingent.libelleSaisie);
                }
            }
            this.toggleContingent = false;
            if (contingentList.length === 1) {
                this.selectedActivity = contingentList[0];
            }
        }
    };
    /**
     * Le backend a retourné une erreur au lieu de la liste des contingents
     * @param err
     */
    SaisieDesHeuresComponent.prototype.errorContingent = function (err) {
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', err.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + err.status + ", body was: " + err.error.message);
            console.error('err: ' + err);
            this.toastr.error(err.error.message, this.msg(err.error.message));
        }
    };
    SaisieDesHeuresComponent.prototype.accessUserList = function () {
        return this.authenticationService.hasAnyAuthority(['ROLE_ADM_USER_LST']);
    };
    SaisieDesHeuresComponent.prototype.displayFn = function (user) {
        return user ? user.prenom + " " + user.nom : '';
    };
    return SaisieDesHeuresComponent;
}());
export { SaisieDesHeuresComponent };
